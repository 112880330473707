import EntryStatus from "enums/EntryStatus";
import * as React from "react";
import { toastr } from "react-redux-toastr";
import axios from "utils/Axios";

export default class EntryAcceptIcon extends React.Component {
  static defaultProps = {
    row: undefined,
    onSuccess: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.onClick = this.onClick.bind(this);
  }

  onClick() {   
    toastr.confirm("Zaakceptować wpisy z wybranego wiersza?", {
      onOk: () => {
        axios
          .post(`TimeEntries/Accept`, this.getAllSendEntriesIds())
          .then((res) => {
            toastr.success("Wpisy zostały zaakceptowane.");
            this.props.onSuccess();
          });
      }
    });
  }

  getAllSendEntriesIds() {
    var data = [];
    for (var key in this.props.row.weekEntries) {
      var entry = this.props.row.weekEntries[key];
      if (entry.id && entry.status === EntryStatus.SEND.value)
        data.push(entry.id);
    }
    return data;
  }

  canAcceptRow() {
    for (var key in this.props.row.weekEntries) {
      var entry = this.props.row.weekEntries[key];
      if (entry.id && entry.canAccept === true && entry.status === EntryStatus.SEND.value)
        return true;
    }
    return false;
  }

  render() {
    if (!this.canAcceptRow(this.props.row))
      return null;

    return <i className="far fa-check-circle pointer" title="Zaakceptuj wpisy" onClick={this.onClick} />;
  }
}

