const dictionaryUP = {
  myLeaves: 'Moje urlopy',
  worklog: 'Czas pracy',
  leaveApplication: 'Złóż wniosek o urlop',
  forLeaves: 'dla urlopów',
  leaveSummary: 'Urlop',
  leave: 'Urlop',
  workingHours: "Wymiar czasu pracy",
  leavesName: "urlopy",
  leaveApplictionForm: "- wniosek o urlop zostanie wysłany do akceptującego,",
  leaveTitle: "Wniosek urlopowy",
  leaveOrLimit: "Urlop w roku"
};
  
const dictionaryOther = {
  myLeaves: 'Moje przerwy',
  worklog: 'Rejestr wpisów',  
  leaveApplication: 'Zgłoś przerwę',
  forLeaves: 'dla przerw',
  leaveSummary: 'Limit',
  leave: 'Wniosek',
  workingHours: "Wymiar",
  leavesName: "przerwy",
  leaveApplictionForm: "- zgłoszenie przerwy zostanie wysłane do akceptującego,",
  leaveTitle: "Zgłoszenie przerwy w świadczeniu usług",
  leaveOrLimit: "Limit w roku"
};

export function getResource(userContractType, key) {
  if (userContractType === 'UP')
    return dictionaryUP[key]; 
  else 
    return dictionaryOther[key];
}
