import { Enum } from "./Enum";

const LeaveEntryType = {
  SICK: Enum("SICK", "Chorobowy"),
  OCCASIONAL: Enum("OCCASIONAL", "Okolicznościowy"),
  HOLIDAY: Enum("HOLIDAY", "Wypoczynkowy"),
  UNPAID: Enum("UNPAID", "Bezpłatny"),
};

export default LeaveEntryType;

