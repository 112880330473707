import * as TableActions from "actions/table";
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import axios from "utils/Axios";
import { calcEmployeeUrl, dateToMoment } from "utils/Utils";


class DeleteCreativeWorkIcon extends React.Component {
  static defaultProps = {
    row: undefined,
    controlling: false
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    toastr.confirm("Usunąć wskazany utwór?", {
      onOk: () => {
        var employeeUrl = calcEmployeeUrl(this.props.row, this.props.controlling);
        axios
          .delete(`CreativeWorks/${employeeUrl}/${this.props.row.id}`)
          .then(res => {
            if (dateToMoment(this.props.row.date).year() === this.props.selectedYear) 
              this.props.doUpdateRow(this.props.row, null);
            toastr.success("Utwór został usunięty.");
          });
      }
    });
  }

  render() {   
    if (!this.props.row.id)
      return null;
    
    return (
      <i className="far fa-trash-alt pointer" title="Usuń" onClick={this.onClick} />
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedYear: state.config.selectedYear
  };
};

const mapDispatchToProps = {
  doUpdateRow: TableActions.updateRow
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteCreativeWorkIcon);
