import * as React from "react";
import FileSaver from "file-saver";

import axios from "utils/Axios";
import Card from "components/common/Card";
import YearSelect from "components/common/select/YearSelect";
import { connect } from "react-redux";

class YearReportCard extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);

    this.state = {
      spinner: false,
    };

    this.onYearChange = this.onYearChange.bind(this);
    this.onGenerate = this.onGenerate.bind(this);
  }

  onYearChange(selectedYear) {
    this.onGenerate(selectedYear);
  }

  onGenerate() {
    this.setState({
      ...this.state,
      spinner: true
    });

    axios
      .post(`Reports/Leaves/Year?year=${this.props.selectedYear}`, null, {
        responseType: "blob",
        timeout: 30000
      })
      .then(res => {
        var blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
        this.setState({
          ...this.state,
          spinner: false
        });
        FileSaver.saveAs(blob, `Raport urlopowy roczny - ${this.props.selectedYear}.xlsx`); 
      })
      .catch(err => {
        this.setState({
          ...this.state,
          spinner: false
        })
      });
  }

  render() {
    return (
      <Card title="Urlopy i przerwy" buttonTitle="Generuj" onClick={this.onGenerate} spinner={this.state.spinner}>
        <p>Urlopy i przerwy zaplanowane i wykorzystane w wybranym roku.</p>
        <div className="w3-row form-inline" >
          <div className="w3-col m2 label">
            <label>Rok:</label>
          </div>
          <div className="w3-col m10 value">
            <YearSelect onChange={this.onYearChange} from={2017}/>
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedYear: state.config.selectedYear,
  };
};

export default connect(mapStateToProps)(YearReportCard);
