export const CURRENT_USER_LOADED = "CURRENT_USER_LOADED";
export const CURRENT_CLOSING_LOADED = "CURRENT_CLOSING_LOADED";
export const HOLIDAYS_LOADED = "HOLIDAYS_LOADED";


export const currentUserLoaded = (user) => {
  return {
    type: CURRENT_USER_LOADED,
    user: user
  };
};

export const currentClosingLoaded = (closing) => {
  return {
    type: CURRENT_CLOSING_LOADED,
    closing: closing
  };
};

export const holidaysLoaded = (holidays) => {
  return {
    type: HOLIDAYS_LOADED,
    holidays: holidays
  };
};
