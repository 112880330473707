import * as React from "react";
import { Checkbox } from "react-icheck";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import * as TableActions from "actions/table";
import ClientSelect from "components/basic/myTimeEntries/ClientSelect";
import { Dialog } from "components/common/Dialog";
import DialogTable from "components/common/DialogTable";
import DialogTableFilter from "components/common/DialogTableFilter";
import DialogMode from "enums/DialogMode";
import axios from "utils/Axios";
import { formatDate } from "utils/Utils";

class AddProjectDialog extends React.Component {
  static defaultProps = {
    mode: DialogMode.HIDDEN,
    controlling: false,
    onClose: () => {}
  };

  columns = [
    {
      Header: "BU",
      accessor: "businessUnit",
      width: 50
    },
    {
      Header: "Centrum kosztów",
      id: "cc",
      accessor: "costCenter.name"
    },
    {
      Header: "Projekt",
      id: "project",
      accessor: (row) => {
        return row.projectId != null ? row.projectType + "." + row.projectName : "";
      }
    },
    {
      Header: "Opis",
      accessor: "costCenter.description"
    },
    {
      Header: "Akceptujący",
      accessor: "managerName"
    }
  ];

  order = [{ id: "cc" }, { id: "project" }];

  constructor(props) {
    super(props);

    this.state = {
      selectedRow: undefined,
      selectedClient: undefined,
      showClientModal: false,
      ownBusinessUnit: true
    };

    this.onOwnBusinessUnitChange = this.onOwnBusinessUnitChange.bind(this);
    this.onSelectRow = this.onSelectRow.bind(this);
    this.onSelectClient = this.onSelectClient.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.addProject = this.addProject.bind(this);
    this.onCloseClientModal = this.onCloseClientModal.bind(this);
    this.onSaveClientModal = this.onSaveClientModal.bind(this);
  }

  componentWillMount() {
    this.props.doDataSetLoaded([]);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.mode === DialogMode.ADD) {
      this.loadData();
    }
  }

  loadData() {
    var employeeUrl = this.calcEmployeeUrl();

    axios.get(`Activities/${employeeUrl}/Remaining?monthDate=${formatDate(this.props.selectedMonth)}&ownBusinessUnit=${this.state.ownBusinessUnit}`)
      .then((res) => {
        this.setState({
          ...this.state,
          selectedRow: undefined,
          selectedClient: undefined,
          showClientModal: false
        });
        this.props.doDataSetLoaded(res.data);
      });
  }

  onOwnBusinessUnitChange(event) {
    console.log(event);
    this.setState({
        ...this.state,
        ownBusinessUnit: event.target.checked
      },
      () => this.loadData()
    );
  }

  onSelectRow(row) {
    this.setState({
      ...this.state,
      selectedRow: row
    });
  }

  onSaveClientModal() {
    if (this.state.selectedClient && this.state.selectedClient.id <= 0) {
       axios
        .post(`Clients`, { name: this.state.selectedClient.name })
        .then(res => {
          this.onSelectClient(res.data);
          this.addProject();
        });
    }
    else {
      this.addProject();
    }
  }

  onCloseClientModal() {
    this.setState({
      ...this.state,
      showClientModal: false,
      selectedClient: undefined
    });
  }

  onSelectClient(client) {
    this.setState({
      ...this.state,
      selectedClient: client
    });
  }

  onAddClick() {
    if (this.state.selectedRow && this.state.selectedRow.costCenter.sale) {
      this.setState({
        ...this.state,
        showClientModal: true
      });
    } else {
      this.addProject();
    }
  }

  addProject() {
    if (!this.state.selectedRow) {
      toastr.warning("Wybierz projekt lub centrum kosztów.");
      return;
    }

    if (this.state.selectedRow.costCenter.sale === true && !this.state.selectedClient) {
      toastr.warning("Dla sprzedaży wymagane jest podanie klienta.");
      return;
    }

    var employeeUrl = this.calcEmployeeUrl();

    var path, data;
    if (this.state.selectedRow.projectId) {
      path = `Employees/${employeeUrl}/FavouriteProjects`;
      data = {
        projectId: this.state.selectedRow.projectId
      };
    }
    else {
      path = `Employees/${employeeUrl}/FavouriteResponsibilities`;
      data = {
        responsibilityId: this.state.selectedRow.responsibilityId,
        clientId: (this.state.selectedClient ? this.state.selectedClient.id : null)
      };
    }

    axios
      .post(path, data)
      .then((res) => {
        var mte = {
          activity: {
            businessUnit: this.state.selectedRow.businessUnit,
            costCenter: {
              name: this.state.selectedRow.costCenter.name,
              typeKey: this.state.selectedRow.costCenter.typeKey,
              leave: this.state.selectedRow.costCenter.leave,
              sale: this.state.selectedRow.costCenter.sale
            },
            activityId: this.state.selectedRow.activityId,
            projectId: this.state.selectedRow.projectId,
            projectTypeKey: this.state.selectedRow.projectTypeKey,
            projectName: this.state.selectedRow.projectName,
            responsibilityId: this.state.selectedRow.responsibilityId,
            managerName: this.state.selectedRow.managerName
          },
          client: this.state.selectedClient,
          favouriteActivityId: res.data.id,
          employeeId: this.props.controlling === true ? this.props.selectedEmployee.id : null,
          weekEntries: {}
        };
    
        this.props.doUpdateRow(null, mte);
        this.props.onClose();
      })
      .catch(err => {console.log(err)});
      // błąd requestu może powstać gdy dodajemy drugi raz sprzedaż z tym samym klientem
  }

  calcEmployeeUrl() {
    return this.props.controlling === true ? this.props.selectedEmployee.id : "Current";
  }

  render() {
    return (
      <Dialog
        mode={this.props.mode}
        title="Projekty i centra kosztów"
        saveText="Dodaj"
        modalClass="modal-wide"
        onSave={this.onAddClick}
        onClose={this.props.onClose}
      >
        <div className="w3-margin-bottom page-filter">
          <div className="check">
            <Checkbox checkboxClass="icheckbox_flat-blue" label="Tylko z mojego BU" checked={this.state.ownBusinessUnit} onChange={this.onOwnBusinessUnitChange} />
          </div>
          <DialogTableFilter />
        </div>     
        <DialogTable columns={this.columns} order={this.order} dataSet={this.state.dataSet} onSelectRow={this.onSelectRow} />
        
        <Dialog
          mode={this.state.showClientModal ? DialogMode.ADD : DialogMode.HIDDEN}
          title="Wybierz klienta"
          saveText="Wybierz"
          modalClass="modal-small mt100"
          onSave={this.onSaveClientModal}
          onClose={this.onCloseClientModal}
        >
          <ClientSelect 
            onChange={this.onSelectClient} 
            visible={this.state.showClientModal} 
          />
        </Dialog>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedMonth: state.config.selectedMonth,
    selectedEmployee: state.config.selectedEmployee ? state.config.selectedEmployee.value : undefined
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dialogDataSetLoaded,
  doUpdateRow: TableActions.updateRow
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProjectDialog);
