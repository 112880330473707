import * as React from "react";

export default class Card extends React.Component {
  static defaultProps = {
    title: "",
    spinner: false,
    onSaveClick: () => { },
    onTestClick: () => { }
  }

  render() {
    return (
      <div className="w3-card-2 w3-section w3-border">
        <header className="w3-container w3-green w3-display-container">
          <h6>{this.props.title}</h6>
          <p class="small-right">{this.props.time}</p>
        </header>
        <div className="w3-container w3-padding-16" style={{ backgroundColor: "#f6f6f6" }}>
          {this.props.children}
          <div className="w3-row w3-margin-top form-inline">
            <div className="w3-col m2 w3-hide-small">
              <label>&nbsp;</label>
            </div>
            <div className="w3-col m10">
              <div className="w3-bar">
                {this.props.spinner === true
                  ? <button className="w3-button w3-round w3-red" disabled >
                      <i className="fas fa-spinner w3-spin" /> Proszę czekać...
                    </button>
                  : <button className="w3-button w3-round w3-red" title="Wyślij przykładowy e-mail na swój adres" onClick={this.props.onTestClick}>Testuj</button>
                }
                &nbsp;
                {this.props.spinner === true
                  ? <button className="w3-button w3-round w3-green" disabled >
                      <i className="fas fa-spinner w3-spin" /> Proszę czekać...
                    </button>
                  : <button className="w3-button w3-round w3-green" title="Zapisz szablon" onClick={this.props.onSaveClick}>Zapisz</button> 
                }   
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
