import EntryStatus from "enums/EntryStatus";
import * as React from "react";
import { connect } from "react-redux";

class FooterStats extends React.Component {
  static defaultProps = {
    week: undefined,
    controlling: false,
    disabled: false,
    onSend: () => {},
    onUndo: () => {}
  };

  canSendOrUndo() {
    var canSend = false, canUndo = false;

    for (var row in this.props.dataSet) {
      var entries = this.props.dataSet[row].weekEntries;
      if (entries) {    
        var entry = entries[this.props.week.startDate];
        if (entry && entry.hours > 0 && (entry.status === EntryStatus.ENTERED.value || entry.status === EntryStatus.REJECTED.value))
          canSend = true;
        if (entry && entry.status === EntryStatus.SEND.value)
          canUndo = true;
      }
    };

    return {
      canSend: canSend,
      canUndo: canUndo
    };
  }

  render() {
    var {canSend, canUndo} = this.canSendOrUndo();
    return (
        <div>
          <p className="w3-medium">
            {this.props.week.declaredHours} / {this.props.week.requiredHours}
          </p>
          {this.props.controlling !== true &&
            <div className="w3-bar">
              <button className="w3-button w3-round w3-blue image" onClick={this.props.onSend} title="Wyślij tydzień do akceptacji" disabled={this.props.disabled || !canSend}>
                <i className="fas fa-upload" />
              </button>
              &nbsp;
            <button className="w3-button w3-round w3-red image" onClick={this.props.onUndo} title="Wycofaj wpisy z tygodnia" disabled={this.props.disabled || !canUndo}>
                <i className="fas fa-undo-alt" />
              </button>
            </div>
          }
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dataSet: state.table.originalDataSet
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(FooterStats);
