import * as React from "react";
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr'
import moment from "moment";

import * as TableActions from "actions/table";
import { Dialog, Row } from "components/common/Dialog";
import DialogMode from "enums/DialogMode";
import { LeaveEntryStatus } from "enums/EntryStatus";
import { enum2ComboBox } from "enums/Enum";
import LeaveEntryType from "enums/LeaveEntryType";
import axios from "utils/Axios";
import { calcEmployeeUrl, dateToMoment, formatDate, formatTime, HOURS_PATTERN, HOURS_REGEX } from "utils/Utils";
import { getResource } from "utils/RedactedResources";
import DownloadIcon from "../icon/DownloadIcon";
import ContractType from "enums/ContractType";

class LeaveEntryDialog extends React.Component {
  static defaultProps = {
    controlling: false,
    acceptance: false,
    selectedEmployee: undefined,
    holidays: []
  };

  constructor(props) {
    super(props);

    this.state = {
      row: undefined,
      hoursEnabled: false,
      leaveReason: undefined,
      reasons: []
    };

    this.onSaveClick = this.onSaveClick.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onAccept = this.onAccept.bind(this);
  }

  componentWillMount() {
    axios
    .get(`LeaveReasons/Reasons`)
    .then((res) => {
      this.setState({
        ...this.state,
        reasons: res.data
      })
    })
  }

  componentWillReceiveProps(newProps) {
    if (this.props.row !== newProps.row) {
      this.setState({
        ...this.state,
        row: newProps.row,
        hoursEnabled: (newProps.row && newProps.startDate && newProps.row.startDate === newProps.row.endDate),
        leaveReason: undefined
      });
    }
  }

  onValueChange(name, event) {
    var value = event.target.value;
    this.updateRowValue(name, value);
  }

  onDateChange(name, date) {
    var value = formatDate(date);
    this.updateRowValue(name, value);
  }

  onSelectChange(name, option) {
    let value = option ? option.value : undefined; 
    if (name === "reason") {
      let selectedReason = this.state.reasons.find(r => r.type === value)
      this.setState({
        ...this.state,
        leaveReason: selectedReason
      })
    }
    if (name === "type" && value !== LeaveEntryType.OCCASIONAL.value) {
      this.setState({
        ...this.state,
        leaveReason: undefined
      })
      this.updateRowValue("reason", undefined);
    }
    this.updateRowValue(name, value);
  }

  updateRowValue(name, value) {
    this.setState((prevState) => {
      var row = { ...prevState.row };
      var hoursEnabled = prevState.hoursEnabled;
      row[name] = value;
      if (name === "startDate" && prevState.row.endDate === undefined)
        row.endDate = value;

      if(row.startDate !== undefined && row.endDate !== undefined)
        row.days = this.leaveDurationInDays(row.startDate, row.endDate);

      if (name === "startDate" || name === "endDate")
        row.hours = undefined;
      
      hoursEnabled = (row.days === 1);
      if(!hoursEnabled)
        row.hours = undefined;

      var newState = {
        ...prevState,
        row: row,
        hoursEnabled: hoursEnabled
      };
      return newState;
    });
  }

  leaveDurationInDays(startDate, endDate){ 
    let endMoment = moment.utc(endDate, "YYYY-MM-DD");
    let startMoment = moment.utc(startDate, "YYYY-MM-DD");

    let freeDays = 0;
     
    for (let dayCheckDateStart = startMoment.clone(); dayCheckDateStart <= endMoment; dayCheckDateStart.add(1, 'days')) {
      if (dayCheckDateStart.day() === 0 || dayCheckDateStart.day() === 6 || this.isHoliday(dayCheckDateStart))
        freeDays++;
    }
    return moment.duration(endMoment.diff(startMoment)).asDays() + 1 - freeDays;
  }

  isHoliday(date) {
    if (this.props.holidays.length > 0 && this.props.holidays[0].holiday) {
      return this.props.holidays[0].holiday.some(h => h.format("YYYY-MM-DD") === date.format("YYYY-MM-DD"));
    }
    return false;
  }

  onSaveClick() {
    if (!this.state.row.startDate) {
      toastr.warning("Wypełnij datę początkową.");
      return;
    }

    if (!this.state.row.endDate) {
      toastr.warning("Wypełnij datę końcową.");
      return;
    }

    if (this.state.row.days <= 0) {
      toastr.warning("Niepoprawny zakres dat.");
      return;
    }

    if (!this.state.row.type) {
      toastr.warning("Wybierz typ.");
      return;
    }

    if(this.state.row.type === LeaveEntryType.OCCASIONAL.value && !this.state.row.reason){
      toastr.warning("Wybierz powód.");
      return;
    }

    if (this.state.row.hours && this.state.row.hours.length > 0 && !HOURS_REGEX.test(this.state.row.hours)) {
      toastr.warning("Niepoprawny format godzin.");
      return;
    }

    if (this.props.controlling === true && !this.state.row.comment) {
      toastr.warning("Wypełnij pole z komentarzem.");
      return;
    }

    if(this.state.row.reason){
      if (this.state.leaveReason && this.state.leaveReason.days && this.state.row.days > this.state.leaveReason.days) {
        toastr.warning("Dla tego urlopu okolicznościowego przysługuje liczba dni: " + this.state.leaveReason.days)
        return;
      }
    }

    let employeeUrl = calcEmployeeUrl(this.props.row, this.props.controlling);
    let data = this.state.row;

    switch (this.props.mode) {
      case DialogMode.ADD:
        axios
          .post(`LeaveEntries/${employeeUrl}`, data)
          .then((res) => {
            toastr.success("Wniosek został wysłany.");
            if (dateToMoment(data.startDate).year() === this.props.selectedYear) {
              this.props.doUpdateRow(null, res.data);
            }
            else {
              this.props.doClose();
            }
          });
        break;
      
      case DialogMode.EDIT:
        axios
          .put(`LeaveEntries/${employeeUrl}/${this.props.row.id}`, data)
          .then((res) => {
            toastr.success("Wniosek został zapisany.");
            if(dateToMoment(data.startDate).year() === this.props.selectedYear){
              this.props.doUpdateRow(this.props.row, res.data);
            }
            else {
              this.props.doClose();
            }
          });
        break;
      
      default:
        break;
    }
  }

  onReject() {
    if (!this.state.row.comment) {
      toastr.warning("Wypełnij pole z komentarzem.");
      return;
    }

    axios
      .post(`LeaveEntries/Reject?comment=${this.state.row.comment}`, [this.state.row.id])
      .then((res) => {
        toastr.success("Odrzucono wniosek.");
        this.props.doUpdateRow(this.props.row, null);
      });
  }

  onAccept() {
    this.accept();
  }

  accept() {
    axios
      .post(`LeaveEntries/Accept`, [this.state.row.id])
      .then((res) => {
        toastr.success("Zaakceptowano wniosek.");
        this.props.doUpdateRow(this.props.row, null);
      });
  }

  isDayLabor() {
    if (this.props.controlling && this.props.selectedEmployee){
      return this.props.selectedEmployee.value.employmentModel === 'DAY_LABOR';
    }
    else if (this.props.acceptance) {
      //check not needed for acceptance
      return false;
    }
    else
      return this.props.currentUser.employmentModel === 'DAY_LABOR';
  }

  isUP() {
    if (this.props.controlling) {
      return this.props.selectedEmployee.value.contractType === ContractType.UP.value;
    }
    else if (this.props.acceptance) {
      //check not needed for acceptance
      return false;
    }
    else
      return this.props.currentUser.contractType === ContractType.UP.value;
  }

  getReasonsDict() {
    var dict = [];
    for (var reason of this.state.reasons) {
      dict.push({
        value: reason.type,
        label: reason.reason
      })
    }
    return dict;
  }


  render() {
    let mode = this.props.mode;
    let row  = this.state.row;
    let contractType = null;
    if (!this.props.controlling)
      contractType = this.props.currentUser.contractType;

    if (row === undefined) 
      return null;

    return (
      <Dialog
        title={getResource(contractType, "leaveTitle")}
        mode={mode}
        saveText={mode === DialogMode.ADD ? "Wyślij" : null}
        onSave={this.onSaveClick}
        onClose={this.props.doClose}
        buttons={mode === DialogMode.ACCEPT && row.status === LeaveEntryStatus.SEND.value
          ? <div className="w3-bar w3-center">
            <button className="w3-button w3-round w3-red" onClick={this.onReject}>Odrzuć</button>
            &nbsp;
            <button className="w3-button w3-round w3-blue" onClick={this.onAccept}>Zaakceptuj</button>
          </div>
          : undefined}
      >
        {mode === DialogMode.ADD && this.props.controlling !== true &&
          <div className="w3-panel w3-pale-blue small-top">
            <p>
              Po wybraniu dat <b>początek</b>, <b>koniec</b> oraz <b>typu</b> i kliknięciu <b>Dodaj</b>:<br />
              {getResource(this.props.currentUser.contractType, "leaveApplictionForm")}<br />
              - wydarzenie zostanie dodane do kalendarza "Urlopy".
            </p>
             <p>
              Pole <b>godzin</b> wypełniamy tylko {getResource(this.props.currentUser.contractType, "forLeaves")} krótszych niż jeden dzień.
            </p>
          </div>
        }
        <Row 
          label="Początek" 
          value={row.startDate} 
          onChange={this.onDateChange.bind(this, "startDate")} 
          enabled={mode === DialogMode.ADD || mode === DialogMode.EDIT} 
          calendar 
        />
        <Row label="Koniec" 
          value={row.endDate} 
          onChange={this.onDateChange.bind(this, "endDate")} 
          enabled={mode === DialogMode.ADD || mode === DialogMode.EDIT} 
          calendar 
        />
        <Row label="Godzin"
          value={row.hours}
          onChange={this.onValueChange.bind(this, "hours")}
          enabled={(mode === DialogMode.ADD || mode === DialogMode.EDIT) && this.state.hoursEnabled}
          pattern={HOURS_PATTERN}
        />
        <Row 
          label="Dni" 
          value={row.days ? row.days : 0}
         />
         
        {this.isDayLabor() ? 
          <Row
            visible={false}
            label="Typ"
            value={row.typeKey}
            setState={row.type = LeaveEntryType.UNPAID.value}
            /> 
            : 
            <Row
              label="Typ"
              value={row.type}
              options={enum2ComboBox(LeaveEntryType)}
              onChange={this.onSelectChange.bind(this, "type")}
              enabled={mode === DialogMode.ADD || mode === DialogMode.EDIT}
            />
        }
        <Row
          label="Powód"
          value={row.reason}
          options={this.getReasonsDict()}
          onChange={this.onSelectChange.bind(this, "reason")}
          enabled={mode === DialogMode.ADD}
          visible={mode === DialogMode.ADD && row.type === LeaveEntryType.OCCASIONAL.value}
        />
        {this.state.leaveReason && this.state.leaveReason.days ? 
          <div>
            <center>
              Maksymalna liczba dni: {this.state.leaveReason.days}
            </center>
          </div> 
          : ""
        }

        <Row 
          label="Opis" 
          value={row.description} 
          onChange={this.onValueChange.bind(this, "description")} 
          enabled={(mode === DialogMode.ADD || mode === DialogMode.EDIT) && this.props.controlling !== true} 
          textarea
        />
        <Row 
          label="Status"
          value={row.status}
          options={enum2ComboBox(LeaveEntryStatus)}
          onChange={this.onSelectChange.bind(this, "status")}
          visible={mode !== DialogMode.ADD || this.props.controlling}
          enabled={mode === DialogMode.EDIT || this.props.controlling}
        />
        <Row 
          label="Wysłano" 
          value={formatTime(row.sendTime)} 
          visible={mode !== DialogMode.ADD} 
          raw
        />
        <Row 
          label="Mój komentarz" 
          value={row.comment} 
          onChange={this.onValueChange.bind(this, "comment")} 
          visible={(mode === DialogMode.ACCEPT && row.status === LeaveEntryStatus.SEND.value) || this.props.controlling} 
          enabled={(mode === DialogMode.ACCEPT && row.status === LeaveEntryStatus.SEND.value) || this.props.controlling}  
          textarea
        />
        <Row 
          label="Zaakceptowano" 
          value={formatTime(row.acceptedTime)}
          visible={mode !== DialogMode.ADD && row.status === LeaveEntryStatus.ACCEPTED.value}  
          raw
        />
        <Row 
          label="Odrzucono" 
          value={formatTime(row.acceptedTime)}
          visible={mode !== DialogMode.ADD && row.status === LeaveEntryStatus.REJECTED.value}  
          raw
        />  
        {row.type === LeaveEntryType.UNPAID.value && this.isUP() ? 
          <div>
            <center>Pobierz i uzupełnij wniosek <DownloadIcon/></center>
          </div>
          : ""
        } 
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mode: state.table.dialogMode,
    row: state.table.selectedRow,
    selectedYear: state.config.selectedYear,
    currentUser: state.session.currentUser,
    selectedEmployee: state.config.selectedEmployee,
    holidays: state.session.holidays
  };
};

const mapDispatchToProps = {
  doUpdateRow: TableActions.updateRow,
  doClose: TableActions.closeDialog
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveEntryDialog);
