import * as TableActions from "actions/table";
import LeaveEntryDialog from "components/common/dialog/LeaveEntryDialog";
import YesNoIcon from "components/common/icon/YesNoIcon";
import StatusLabel from "components/common/StatusLabel";
import Table from "components/common/Table";
import DialogMode from "enums/DialogMode";
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import axios from "utils/Axios";
import { Checkbox } from "react-icheck";

class SicksAndOtherLeavesPage extends React.Component {
  static viewKey = 'SicksAndOtherLeavesPage';

  columns = [
    {
      Header: <span className="w3-left">Początek</span>,
      accessor: "startDate",
      width: 120
    },
    {
      Header: <span className="w3-left">Koniec</span>,
      accessor: "endDate",
      width: 120
    },
    {
      Header: <span className="w3-left">Dni</span>,
      accessor: "days",
      width: 50
    },
    {
      Header: <span className="w3-left">Zatrudniony</span>,
      accessor: "employeeName",
    },
    {
      Header: <span className="w3-left">Forma zatr.</span>,
      accessor: "contractType",
      width: 100
    },
    {
      Header: <span className="w3-left">Typ</span>,
      accessor: "typeKey"
    },
    {
      Header: <span className="w3-left">Status</span>,
      accessor: "statusKey",
      Cell: (props) => <StatusLabel status={props.original.status} label={props.original.statusKey} />
    },
    {
      Header: <span className="w3-left">Opis</span>,
      accessor: "description"
    },
    {
      Header: "Wymagane L-4",
      id: "l4Required",
      className: "w3-center",
      accessor: (row) => <YesNoIcon value={row.l4Required} />,
      width: 140
    },
    {
      className: "flex",
      id: "options",
      accessor: row => this.renderCheck(row),  
      width:40
    }
  ]

  order = [{ id: "startDate" }];

  constructor(props) {
    super(props);

    this.state = { 
      rowsToAccept: undefined,
      showDialog: true,
      rowsToDelete: [],
      idsToAccept: []
    };

    this.onShowDialog = this.onShowDialog.bind(this);
    this.onAcceptAll = this.onAcceptAll.bind(this);
    this.onConfirmChosen = this.onConfirmChosen.bind(this);
    this.onCheckedChange = this.onCheckedChange.bind(this);
  }


  renderCheck(row) {
    return <Checkbox checkboxClass="icheckbox_flat-blue" label="&nbsp;"
      checked={row.selected === true}
      onChange={this.onCheckedChange}
      data-id={row.id} />;
  }

  onCheckedChange(event, checked) {
    var id = event.target.dataset.id;
    this.props.doSelectRow(id, checked, "id");
    if (checked) {
        this.state.rowsToDelete.push(id)  
    }
    else {
      var index = this.state.rowsToDelete.indexOf(id)
      this.state.rowsToDelete.splice(index, 1)
    }
  }

  componentWillMount() {
    this.props.doSetCurrentTableView(SicksAndOtherLeavesPage.viewKey);
    this.props.doDataSetLoaded([], SicksAndOtherLeavesPage.viewKey);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    axios
      .get(`LeaveEntries/LeavesToManualProcess`)
      .then((res) => {
        this.props.doDataSetLoaded(res.data, SicksAndOtherLeavesPage.viewKey);
        this.setState({
          ...this.state,
          rowsToAccept: res.data,
          idsToAccept: this.getIdsToAccept(res.data)
        })
      });  
  }

  onShowDialog(row) {
    if (this.state.showDialog)
      this.props.doOpenDialog(row, DialogMode.ACCEPT);
  }

  onAcceptAll() {
    toastr.confirm("Zaakceptować wszystkie wnioski?", {
      onOk: () => {
        var data = this.getAllSendEntriesIds();
        if (data.l4Required === true) {
          toastr.confirm("Wśród zaznaczonych wniosków znajdują się osoby zatrudnione na UP. Czy potwierdzasz dostarczenie wymaganych dokumentów (np: L-4, zaświadczenie potwierdzające prawo do urlopu okolicznościowego itp.)?", {
            onOk: () => {
              this.acceptAll(data.ids);
             }
          });
        }
        else {
          this.acceptAll(data.ids);
        }
      }
    });
  }

  getIdsToAccept(rows) {
    var data = []
    for(var i in rows) {
      data.push(rows[i].id)
    }
    return data;
  }

  onConfirmChosen() {
    toastr.confirm("Zatwierdzić wybrane wnioski?", {
      onOk: () => {
       this.confirmChosen(this.state.rowsToDelete)
      }
    });
  }

  confirmChosen(ids) {
    axios
    .post(`LeaveEntries/Confirm`, ids)
    .then((res) => {
      toastr.success("Zatwierdzono wybrane wnioski.");
      this.loadData();
      this.setState({
        ...this.state,
        rowsToDelete: []
      })
    });
  }

  acceptAll(ids) {
    axios
      .post(`LeaveEntries/Accept`, ids)
      .then((res) => {
        toastr.success("Zaakceptowano wszystkie wnioski.");
        this.loadData();
      });
  }

  getAllSendEntriesIds() {
    var ids = [];
    var l4Required = false;
    this.props.dataSet.forEach(row => {
      ids.push(row.id);
      if (row.l4Required === true)
        l4Required = true;
    });
    return { ids: ids, l4Required: l4Required };
  }
  
  render() {
    return (
      <div>
        <h1>Pozostałe nieobecności</h1>
        <Table columns={this.columns} order={this.order} onShowDialog={this.onShowDialog} /> 
        <div className="w3-bar w3-center">
          <button className="w3-button w3-round w3-blue" onClick={this.onConfirmChosen} disabled={this.state.rowsToDelete.length === 0}>
            Zatwierdź wybrane
          </button>
        </div>
        <LeaveEntryDialog />
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    dataSet: state.table.filteredDataSet
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doUpdateRow: TableActions.updateRow,
  doOpenDialog: TableActions.openDialog,
  doSelectRow: TableActions.selectRow,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(SicksAndOtherLeavesPage);
