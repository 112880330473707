import Card from "components/common/Card";
import FileSaver from "file-saver";
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { formatDate } from "utils/Utils";

import axios from "utils/Axios";
import MonthSelect from "components/reports/MonthSelect";
import EmployeeSelect from "components/common/select/EmployeeSelect";
import * as ConfigActions from "actions/config";
class EngagementReportCard extends React.Component {
  static defaultProps = {

  };

  constructor(props) {
    super(props);

    this.state = {
      month: undefined,
      spinner: false
    };

    this.onMonthChange = this.onMonthChange.bind(this);
    this.onGenerate = this.onGenerate.bind(this);
  }

  onMonthChange(date) {
    this.setState({
      ...this.state,
      month: date
    });   
  }

  componentWillMount() {
    this.props.doEmployeeSelected();
  }

  onGenerate() {
    if (!this.props.selectedEmployee) {
      toastr.warning("Wybierz zatrudnionego z listy.");
          return;
    }
  
    this.setState({
      ...this.state,
      spinner: true
    });

    axios
      .post(`Reports/Engagement/DayLabor?month=${formatDate(this.state.month)}&id=${this.props.selectedEmployee.value.id}`, null, {
        responseType: "blob",
        timeout: 30000
      })
      .then(res => { 
        var blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
        this.setState({
          ...this.state,
          spinner: false
        });
        FileSaver.saveAs(blob, `Raport zaangażowania ${formatDate(this.state.month)}.xlsx`);
      })
      .catch(err => {        
        this.setState({
          ...this.state,
          spinner: false
        })
      });
  }

  render() {
    return (
      <Card title="Zaangażowanie zatrudnionych na akord" buttonTitle="Generuj" onClick={this.onGenerate} spinner={this.state.spinner} > 
        
        <div className="w3-row form-inline">
          <div className="w3-col m2 label">
            <label>Miesiąc:</label>
          </div>
          <div className="w3-col m10 value">
            <MonthSelect onChange={this.onMonthChange} />
          </div>
        </div>
        <div className="w3-row form-inline">
          <div className="w3-col m2 label">
            <label>Zatrudniony:</label>
          </div>
          <div className="w3-col m10 value">
            <EmployeeSelect disabled = {this.props.selectedEmployee}/>
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedEmployee: state.config.selectedEmployee
  };
};
const mapDispatchToProps = {
  doEmployeeSelected: ConfigActions.employeeSelected,
};
export default connect(mapStateToProps, mapDispatchToProps)(EngagementReportCard);
