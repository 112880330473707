import * as React from "react";
import { connect } from "react-redux";
import Select from "react-select-v1";
import moment from "moment-timezone";
import * as ConfigActions from "actions/config";

class MonthSelect extends React.Component {
  static defaultProps = {
    fromYear: 2011,
    monthDate: undefined,
    onSelect: () => {} 
  };

  constructor(props) {
    super(props);

    var today = moment();
    var years = this.generateYears();
    var months = this.generateMonths();

    this.state = {
      years: years,
      months: months,
      selectedYear: years[(this.props.monthDate !== undefined ? this.props.monthDate.year() : today.year()) - this.props.fromYear],
      selectedMonth: months[this.props.monthDate !== undefined ? this.props.monthDate.month() : today.month()]
    };

    this.onYearChange = this.onYearChange.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
  }

  componentDidMount() {
    this.props.doMonthSelected(this.getSelectedDate());
  }

  onYearChange(option) {
    if (option) {
      this.setState({
        ...this.state,
        selectedYear: option
      },
        () => {
          this.props.doMonthSelected(this.getSelectedDate());
          this.props.onSelect();
        }
      );
    }
  }

  onMonthChange(option) {
    if (option) {
      this.setState({
        ...this.state,
        selectedMonth: option
      },
        () => {
          this.props.doMonthSelected(this.getSelectedDate());
          this.props.onSelect();
        }
      );
    }
  }

  generateYears() {
    var nextYear = moment().year() + 1;
    var array = [];
    for (var i = this.props.fromYear; i <= nextYear; i++) {
      array.push({
        label: i,
        value: i
      });
    }
    return array;
  }

  generateMonths() {
    return ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"].map((el, index) => {
      return {
        label: el,
        value: index
      };
    });
  }

  getSelectedDate() {
    return moment([this.state.selectedYear.value, this.state.selectedMonth.value, 1]);
  }

  render() {
    return (
      <div>
        <Select
          className="select-inline w100"
          value={this.state.selectedYear}
          onChange={this.onYearChange}
          options={this.state.years}
          clearable={false}
        />
        <Select
          className="select-inline w200"
          value={this.state.selectedMonth}
          onChange={this.onMonthChange}
          options={this.state.months}
          clearable={false} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  doMonthSelected: ConfigActions.monthSelected
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthSelect);