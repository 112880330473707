import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';

import session from './session';
import config from './config';
import table from './table';


export default combineReducers({
  config: config,
  session: session,
  table: table,
  toastr: toastr
});