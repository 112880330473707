import * as React from "react";
import { connect } from "react-redux";

import * as TableActions from "actions/table";
import { Dialog, Row } from "components/common/Dialog";
import DialogMode from "enums/DialogMode";
import axios from "utils/Axios";
import { formatDate } from "utils/Utils";

class HolidayDialog extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);

    this.state = {
      row: undefined,
    };

    this.onSave = this.onSave.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.row !== newProps.row) {
      this.setState({
        ...this.state,
        row: newProps.row
      });
    }
  }

  onValueChange(name, event) {
    var value = event.target.value;
    this.updateRowValue(name, value);
  }

  onDateChange(name, date) {
    var value = formatDate(date);
    this.updateRowValue(name, value);
  }

  updateRowValue(name, value) {
    this.setState((prevState) => {
      var row = { ...prevState.row };
      row[name] = value;

      var newState = {
        ...prevState,
        row: row,
      };
      return newState;
    });
  }

  onSave() {
    var row = this.state.row;
    if (!row.id) {
      axios
        .post(`Holidays`, row)
        .then((res) => {
          this.props.doUpdateRow(null, res.data);
        });
    }
    else {
      axios
        .put(`Holidays/${row.id}`, row)
        .then((res) => {
          this.props.doUpdateRow(this.props.row, res.data);
        });
    }
  }

  render() {
    var { mode } = this.props;
    var row = this.state.row;

    if (row === undefined) return null;

    return (
      <Dialog
        title="Święto" 
        mode={mode} 
        onClose={this.props.doCloseDialog} 
        onSave={this.onSave}
      >
        <Row
          label="Id"
          value={row.id}
          raw
        />
        <Row
          label="Data"
          value={row.date}
          calendar
          enabled={mode === DialogMode.ADD || mode === DialogMode.EDIT}
          onChange={this.onDateChange.bind(this, "date")}
        />
        <Row
          label="Nazwa"
          value={row.name}
          enabled={mode === DialogMode.ADD || mode === DialogMode.EDIT}
          onChange={this.onValueChange.bind(this, "name")}
        />
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mode: state.table.dialogMode,
    row: state.table.selectedRow
  };
};

const mapDispatchToProps = {
  doUpdateRow: TableActions.updateRow,
  doCloseDialog: TableActions.closeDialog
};

export default connect(mapStateToProps, mapDispatchToProps)(HolidayDialog);