import * as TableActions from "actions/table";
import CreativeWorkDialog from "components/common/dialog/CreativeWorkDialog";
import DeleteCreativeWorkIcon from "components/common/icon/DeleteCreativeWork";
import UndoCreativeWorkIcon from "components/common/icon/UndoCreativeWorkIcon";
import CreativeWorkInfo from "components/common/panel/CreativeWorkInfo";
import PageFilter from "components/common/panel/PageFilter";
import YearSelect from "components/common/select/YearSelect";
import StatusLabel from "components/common/StatusLabel";
import Table from "components/common/Table";
import DialogMode from "enums/DialogMode";
import EntryStatus from "enums/EntryStatus";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import axios from "utils/Axios";
import { dateToMoment, formatDate } from "utils/Utils";

class MyCreativeWorksPage extends React.Component { 
  static viewKey = 'MyCreativeWorksPage';

  columns = [
    {
      show: false,
      id: "date",
      accessor: "date"
    },
    {
      Header: <span className="w3-left">Miesiąc</span>,
      id: "month",
      accessor: "date",
      Cell: (props) => dateToMoment(props.value).format("MMMM"),
    },
    {
      Header: <span className="w3-left">Tytuł</span>,
      accessor: "title",
      minWidth: 500
    },
    {
      Header: <span className="w3-left">Kwota</span>,
      accessor: "amount",
      width: 200
    },
    {
      Header: <span className="w3-left">Status</span>,
      accessor: "status",
      Cell: (props) => <StatusLabel status={props.original.status} label={props.original.statusKey} />,
      width: 150
    },
    {
      Header: "",
      id: "options",
      className: "w3-center",
      sortable: false,
      Cell: (props) => <div>
        {props.original.status === EntryStatus.ENTERED.value && <DeleteCreativeWorkIcon row={props.original} />}
        {props.original.status === EntryStatus.SEND.value && <UndoCreativeWorkIcon row={props.original} />}
        </div>,
      width: 40
    },
  ];

  order = [{ id: "date" }, {id: "title"}];

  constructor(props) {
    super(props);

    this.state = { 
    };

    this.loadData();

    this.onAddClick = this.onAddClick.bind(this);
    this.onSendClick = this.onSendClick.bind(this);
    this.onShowDialog = this.onShowDialog.bind(this);
  }

  componentWillMount() {
    this.props.doSetCurrentTableView(MyCreativeWorksPage.viewKey);
    this.props.doDataSetLoaded([], MyCreativeWorksPage.viewKey);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.selectedYear !== newProps.selectedYear) {
      this.loadData(newProps.selectedYear);
    }
  }

  loadData(year = this.props.selectedYear) {
    if (!year)
      return;
    
    axios
      .get(`CreativeWorks/Current/Year?year=${year}`)
      .then((res) => {
        this.props.doDataSetLoaded(res.data, MyCreativeWorksPage.viewKey)
      });
  }

  onShowDialog(row) {
    if (dateToMoment(row.date).isBefore(dateToMoment(this.props.currentClosing.date)) || row.status === EntryStatus.SEND.value || row.status === EntryStatus.ACCEPTED.value) {
      this.props.doOpenDialog(row, DialogMode.PREVIEW);
    }
    else {
      this.props.doOpenDialog(row, DialogMode.EDIT);
    }
  }

  onAddClick() {     
    this.props.doOpenDialog({
      date: formatDate(moment().date(1)),
      status: EntryStatus.ENTERED.value
    }, DialogMode.ADD);
  }

  onSendClick() {
    toastr.confirm("Wysłać wprowadzone utwory do akceptacji?", {
      onOk: () => {
        axios
          .post(`CreativeWorks/Current/Submit?year=${this.props.selectedYear}`)
          .then((res) => {
            this.props.doDataSetLoaded(res.data, MyCreativeWorksPage.viewKey)
          });
      }
    });
  }

  render() {
    return (
      <div>
        <h1 className="small-bottom">Moje utwory</h1>
        <CreativeWorkInfo />
        <PageFilter tableFilter>
          <div className="w3-row form-inline">
            <div className="w3-col m2 label"><label>Rok:</label></div>
            <div className="w3-col m10 value"><YearSelect from={2017}/></div>
          </div>
        </PageFilter>
        <Table columns={this.columns} order={this.order} defaultPageSize={20} onShowDialog={this.onShowDialog} />
        <div className="w3-section w3-bar w3-center">
          <button className="w3-button w3-round w3-green" onClick={this.onAddClick} >Dodaj</button>
          &nbsp;
          <button className="w3-button w3-round w3-blue" onClick={this.onSendClick} >Wyślij do akceptacji</button>
        </div>
        <CreativeWorkDialog />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentClosing: state.session.currentClosing,
    currentUser: state.session.currentUser,
    selectedYear: state.config.selectedYear,
    dataSet: state.table.filteredDataSet
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doUpdateRow: TableActions.updateRow,
  doOpenDialog: TableActions.openDialog,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCreativeWorksPage);