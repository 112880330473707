import * as React from "react";
import { toastr } from "react-redux-toastr";

import axios from "utils/Axios";

export default class LeaveAcceptIcon extends React.Component {
  static defaultProps = {
    row: undefined,
    controlling: false,
    onSuccess: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.onClick = this.onClick.bind(this);
  }

  onClick() {   
    toastr.confirm("Zaakceptować wniosek?", {
      onOk: () => {
        this.accept();
      }
    });
  }

  accept() {
    axios
      .post(`LeaveEntries/Accept`, [this.props.row.id])
      .then(res => {
        toastr.success("Zaakceptowano wniosek.");
        this.props.onSuccess();
      });
  }

  render() {
    return <i className="fas fa-check-circle pointer" title="Zaakceptuj wniosek" onClick={this.onClick} />;
  }
}
