import * as React from "react";
import { formatDate } from "utils/Utils";

import axios from "utils/Axios";
import { toastr } from 'react-redux-toastr'
import { connect } from "react-redux";
import FileSaver from "file-saver";

class PrintIcon extends React.Component {
  static defaultProps = {
    row: undefined,
    onSuccess: () => {}
  };

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    axios
    .post(`Reports/Engagement/Employee?start=${formatDate(this.props.currentClosing.date)}&id=${this.props.row.employeeId}`, null, { 
      responseType: "blob",
      timeout: 30000
    })
      .then(res => { 
        var blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
        toastr.success("Pobrano fakturę");
        FileSaver.saveAs(blob, `Załącznik do faktury ${this.props.row.employeeName} ${this.props.currentClosing.date}.xlsx`); 
      });
  }

  render() {
    return <i className="fas fa-print pointer" title="Pobierz załącznik do faktury" onClick={this.onClick} />;
  }
}

const mapStateToProps = (state) => {
  return {
    currentClosing: state.session.currentClosing
  };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintIcon);
