import * as ConfigActions from "actions/config";
import * as TableActions from "actions/table";
import CreativeWorkDialog from "components/common/dialog/CreativeWorkDialog";
import DeleteCreativeWorkIcon from "components/common/icon/DeleteCreativeWork";
import CreativeWorkInfo from "components/common/panel/CreativeWorkInfo";
import PageFilter from "components/common/panel/PageFilter";
import EmployeeSelect from "components/common/select/EmployeeSelect";
import YearSelect from "components/common/select/YearSelect";
import StatusLabel from "components/common/StatusLabel";
import Table from "components/common/Table";
import DialogMode from "enums/DialogMode";
import EntryStatus from "enums/EntryStatus";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import axios from "utils/Axios";
import { dateToMoment, formatDate } from "utils/Utils";

class CreativeWorksPage extends React.Component { 
  static viewKey = 'CreativeWorksPage';

  columns = [
    {
      show: false,
      id: "date",
      accessor: "date"
    },
    {
      Header: <span className="w3-left">Miesiąc</span>,
      id: "month",
      accessor: "date",
      Cell: (props) => dateToMoment(props.value).format("MMMM"),
    },
    {
      Header: <span className="w3-left">Tytuł</span>,
      accessor: "title",
      minWidth: 500
    },
    {
      Header: <span className="w3-left">Kwota</span>,
      accessor: "amount",
      width: 200
    },
    {
      Header: <span className="w3-left">Status</span>,
      accessor: "status",
      Cell: (props) => <StatusLabel status={props.original.status} label={props.original.statusKey} />,
      width: 150
    },
    {
      Header: "",
      id: "options",
      className: "w3-center",
      sortable: false,
      Cell: (props) => <div>
        {(props.original.status === EntryStatus.ENTERED.value || props.original.status === EntryStatus.SEND.value)
          && <DeleteCreativeWorkIcon row={props.original} controlling />}
        </div>,
      width: 40
    },
  ];

  order = [{ id: "date" }];

  constructor(props) {
    super(props);

    this.state = { 
    };

    this.onAddClick = this.onAddClick.bind(this);
    this.onShowDialog = this.onShowDialog.bind(this);
  }

  componentWillMount() {
    this.props.doSetCurrentTableView(CreativeWorksPage.viewKey);
    this.props.doDataSetLoaded([], CreativeWorksPage.viewKey);
    this.props.doEmployeeSelected();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.selectedYear !== newProps.selectedYear || this.props.selectedEmployee !== newProps.selectedEmployee) {
      this.loadData(newProps.selectedYear, newProps.selectedEmployee);
    }
  }

  loadData(year, employee) {
    if (!year || !employee) {
      this.props.doDataSetLoaded([], CreativeWorksPage.viewKey);
      return;
    }
    
    axios
      .get(`CreativeWorks/${employee.id}/Year?year=${year}`)
      .then((res) => {
        this.props.doDataSetLoaded(res.data, CreativeWorksPage.viewKey)
      });
  }

  onShowDialog(row) {
    if (dateToMoment(row.date).isBefore(dateToMoment(this.props.currentClosing.date))) {
      this.props.doOpenDialog(row, DialogMode.PREVIEW);
    }
    else {
      this.props.doOpenDialog(row, DialogMode.EDIT);
    }
  }

  onAddClick() {     
    this.props.doOpenDialog({
      date: formatDate(moment().date(1)),
      status: EntryStatus.ENTERED.value,
      employeeId: this.props.selectedEmployee.id
    }, DialogMode.ADD);
  }

  render() {
    return (
      <div>
        <h1 className="small-bottom">Utwory zatrudnionych</h1>
        <PageFilter tableFilter>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Rok:</label></div>
            <div className="w3-col m9 value"><YearSelect from={2017}/></div>
          </div>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Zatrudniony:</label></div>
            <div className="w3-col m9 value"><EmployeeSelect creativeWork /></div>
          </div>
        </PageFilter>
        <CreativeWorkInfo controlling />
        <Table columns={this.columns} order={this.order} defaultPageSize={20} onShowDialog={this.onShowDialog} />
        <div className="w3-section w3-bar w3-center">
          <button className="w3-button w3-round w3-green" disabled={!this.props.selectedEmployee} onClick={this.onAddClick} >Dodaj</button>
        </div>
        <CreativeWorkDialog controlling />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentClosing: state.session.currentClosing,
    currentUser: state.session.currentUser,
    selectedYear: state.config.selectedYear,
    selectedEmployee: state.config.selectedEmployee ? state.config.selectedEmployee.value : undefined,
    dataSet: state.table.filteredDataSet
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doEmployeeSelected: ConfigActions.employeeSelected,
  doUpdateRow: TableActions.updateRow,
  doOpenDialog: TableActions.openDialog,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(CreativeWorksPage);