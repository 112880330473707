import ReadyToCloseState from "enums/ReadyToCloseState";
import * as React from "react";
import YesNoIcon from "./YesNoIcon";

export default class DayLaborIcon extends React.Component {
  static defaultProps = {
    value: undefined
  };

  render() {
    if (this.props.value === undefined)
      return null;
    if (this.props.value === ReadyToCloseState.DAY_LABOR_UNCONFIRMED)
      return <i className={"fa fa-user-times"} title="Zatrudniony na akord nie zatwierdził godzin"/>
    return <YesNoIcon value={this.props.value}/>
  }
}
