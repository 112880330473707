import * as React from "react";
import { connect } from "react-redux";

import ActivityFilterSelect from "./ActivityFilterSelect";
import * as TableActions from "actions/table";
import PageFilter from "components/common/panel/PageFilter";
import Table from "components/common/Table";
import ProjectDialog from "components/dictionaries/projects/ProjectDialog";
import ActivityStatus from "enums/ActivityStatus";
import DialogMode from "enums/DialogMode";
import axios from "utils/Axios";

class ProjectsPage extends React.Component {
  static viewKey = 'ProjectsPage';

  columns = [
    {
      Header: <span className="w3-left">ID</span>,
      accessor: "activityId",
      width: 100
    },
    {
      Header: <span className="w3-left">BU</span>,
      accessor: "businessUnit",
      width: 100
    },
    {
      Header: <span className="w3-left">Centrum kosztów</span>,
      id: "cc",
      accessor: "costCenter.name"
    },
    {
      Header: <span className="w3-left">Projekt</span>,
      id: "project",
      accessor: (row) => {
        return row.projectId != null ? row.projectTypeKey + "." + row.projectName : null;
      }
    },
    {
      Header: <span className="w3-left">Początek</span>,
      accessor: "startDate",
      width: 150
    },
    {
      Header: <span className="w3-left">Koniec</span>,
      accessor: "endDate",
      width: 150
    },
    {
      Header: <span className="w3-left">Akceptujący</span>,
      accessor: "managerName"
    }
  ];

  order = [{ id: "cc" }, { id: "project" }];

  constructor(props) {
    super(props);

    this.state = {
      filter: ActivityStatus.OPEN.value
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onShowDialog = this.onShowDialog.bind(this);
  }

  componentWillMount() {
    this.props.doSetCurrentTableView(ProjectsPage.viewKey);
    this.props.doDataSetLoaded([], ProjectsPage.viewKey);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData(filter = this.state.filter) {
    axios
      .get(`Activities?status=${filter}`)
      .then((res) => this.props.doDataSetLoaded(res.data, ProjectsPage.viewKey));
  }

  onFilterChange(value) {
    this.setState({
      ...this.state,
      filter: value
    });

    this.loadData(value);
  }

  onShowDialog(row) {
    this.props.doOpenDialog(row, DialogMode.PREVIEW);
  }

  render() {
    return (
      <div>
        <h1 className="small-bottom">Projekty i centra kosztów</h1>
        <PageFilter tableFilter>
          <div className="w3-row form-inline">
            <div className="w3-col m2 label"><label>Status:</label></div>
            <div className="w3-col m10 value"><ActivityFilterSelect value={this.state.filter} onChange={this.onFilterChange} /></div>
          </div>
        </PageFilter>  
        <Table columns={this.columns} order={this.order} onShowDialog={this.onShowDialog} defaultPageSize={20} />
        <ProjectDialog />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doOpenDialog: TableActions.openDialog,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPage);