import * as Action from "../actions/session";
import { dateToMoment } from "utils/Utils";

const initialState = {
  currentUser: undefined,         // zalogowany zatrudniony 
  currentClosing: undefined,      // bieżący closing 
  holidays: []                    // święta w tym roku oraz + 1
};

export default (state = initialState, action) => {
  switch (action.type) {
      
    case Action.CURRENT_CLOSING_LOADED: 
      return {
        ...state,
        currentClosing: action.closing
      };
    
    case Action.CURRENT_USER_LOADED: 
      return {
        ...state,
        currentUser: action.user
      };
    
    case Action.HOLIDAYS_LOADED: 
      return {
        ...state,
        holidays: [{
          holiday: action.holidays.map(h => dateToMoment(h.date))
        }]
      };

    default:
      return state;
  }
};
