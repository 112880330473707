import EntryStatus from "enums/EntryStatus";
import * as React from "react";

export default class EntryCell extends React.Component {
  static defaultProps = {
    row: undefined,
    week: undefined,
    onClick: (row, entry) => { }
  };

  constructor(props) {
    super(props);

    this.state = {
      entry: this.calcEntry(props.row, props.week)
    };

    this.onClick = this.onClick.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.row !== newProps.row) {
      this.setState({
        ...this.state,
        entry: this.calcEntry(newProps.row, newProps.week)
      });
    }
  }

  calcEntry(row, week) {
    var entry = row.weekEntries[week.startDate];
    if (!entry) {
      entry = {
        status: EntryStatus.ENTERED.value,
        hours: ""
      };
    }
    return entry;
  }

  onClick() {
    if (this.canShow(this.state.entry)) {
      this.props.onClick(this.props.row, this.state.entry);
    }
  }

  canShow(entry) {
    return entry.id && entry.canAccept === true;
  }

  render() {  
    if (!this.props.row) 
      return null;

    var className = "w3-input w3-border w3-round entry-" + this.state.entry.status.toLowerCase() + (this.canShow(this.state.entry) ? " pointer" : "");
    return (
      <div>
        <input type="text" className={className} value={this.state.entry.hours} readOnly onClick={this.onClick} title="Podgląd" />
        { this.canShow(this.state.entry) && this.state.entry.status === EntryStatus.SEND.value && <i className="fas fa-pencil-alt" title="Zaakceptuj lub odrzuć" onClick={this.onClick} />}
      </div>
    );
  }
}
