import * as React from "react";
import { toastr } from "react-redux-toastr";
import axios from "utils/Axios";

import Card from "../../common/Card";


export default class PassChangeCard extends React.Component {
  static defaultProps = {
   
  }

  constructor(props) {
    super(props);

    this.state = {
      oldPassword: "",
      newPassword: "",
      newPassword2: ""
    };

    this.onClick = this.onClick.bind(this);
  }

  onChange(name, event) {   
    var value = event.target.value;
    this.setState((prevState) => {
       var state = { ...prevState };
       state[name] = value;
       return state;
    });
  }

  onClick() {
    if (!this.state.newPassword) {
      toastr.warning("Podaj nowe hasło.");
      return;
    }

    if (!this.state.newPassword2) {
      toastr.warning("Powtórz nowe hasło.");
      return;
    }

    if (this.state.newPassword !== this.state.newPassword2) {
      toastr.warning("Nowe hasła różnią się.");
      return;
    }

    axios
      .post(`Accounts/ChangePassword`, this.state)
      .then(res => { 
        toastr.success("Hasło zostało zmienione.");
        this.setState({
          oldPassword: "",
          newPassword: "",
          newPassword2: ""
        });
      });
  }

  render() {
    return (
      <Card title="Zmiana hasła" buttonTitle="Zmień hasło" onClick={this.onClick}>
        <p className="small-top">Zmiana hasła do logowania w TTracku (nie ma wpływu na uwierzytelnianie przez Google).
        W przypadku ustawiania hasła po raz pierwszy, pole <i>Poprzednie hasło</i> należy zostawić puste.</p>
        <div className="w3-row form-inline">
          <div className="w3-col m2 label">
            <label>Poprzednie hasło:</label>
          </div>
          <div className="w3-col m10 value">
            <input type="password" className="w3-input w3-border w3-round w300" value={this.state.oldPassword} onChange={this.onChange.bind(this, "oldPassword")} />
          </div>
        </div>
        <div className="w3-row form-inline">
          <div className="w3-col m2 label">
            <label>Nowe hasło:</label>
          </div>
          <div className="w3-col m10 value">
            <input type="password" className="w3-input w3-border w3-round w300" value={this.state.newPassword} onChange={this.onChange.bind(this, "newPassword")} />
        </div>
        </div>
        <div className="w3-row form-inline">
          <div className="w3-col m2 label">
            <label>Powtórz hasło:</label>
          </div>
          <div className="w3-col m10 value">
            <input type="password" className="w3-input w3-border w3-round w300" value={this.state.newPassword2} onChange={this.onChange.bind(this, "newPassword2")} />
          </div>
        </div>
      </Card>
    );
  }
}
