import * as React from "react";

import { Link } from "react-router-dom";
import PageInfo from "components/common/panel/PageInfo";
import Path from "utils/Path";
import axios from "utils/Axios";
import { connect } from "react-redux";
import { formatDate } from "utils/Utils";

class AcceptanceInfo extends React.Component {
  static defaultProps = {

  };

  constructor(props){
    super(props);

    this.state = {
      mustAcceptTimeEntries: false,
      mustAcceptLeaveEntries: false,
    };
  }

  componentDidMount() {
    this.loadData(this.props.employee);
  }

  loadData(employee) {
    if (!employee || !employee.roleManager) {
      return;
    }
        
    axios.get(`Activities/ToAccept?monthDate=${formatDate(this.props.date)}`)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          this.setState({
            ...this.state,
            mustAcceptTimeEntries: true
          });
        }
      });
    
    axios.get(`LeaveEntries/HasLeavesToAccept`)
      .then((res) => {
        if (res.data) {
          this.setState({
            ...this.state,
            mustAcceptLeaveEntries: true
          });
        }
      });
  }

  render() {
    if (!this.state.mustAcceptTimeEntries && !this.state.mustAcceptLeaveEntries)
      return null;
    
    return (
      <PageInfo warning>
        {this.state.mustAcceptTimeEntries ?
          <p>
            Posiadasz wpisy do zaakceptowania: &nbsp;&nbsp;
          <Link to={Path.acceptance + Path.byEmployee} className="w3-button w3-round w3-blue small-button">
              Akceptacja po zatrudnionych
          </Link>
          </p>
          : null}
        {this.state.mustAcceptLeaveEntries ?
          <p>
            Posiadasz urlopy i przerwy do zaakceptowania: &nbsp;
          <Link to={Path.acceptance + Path.leaves} className="w3-button w3-round w3-blue small-button">
              Akceptacja urlopów i przerw
          </Link>
          </p>
          : null}
      </PageInfo> 
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    employee: state.session.currentUser,
    date: state.session.currentClosing.date
  };
};

export default connect(mapStateToProps)(AcceptanceInfo);