import * as React from "react";
import { connect } from "react-redux";

import * as TableActions from "actions/table";
import LeaveEntryDialog from "components/common/dialog/LeaveEntryDialog";
import DeleteLeaveIcon from "components/common/icon/LeaveDeleteIcon";
import LeaveInfo from "components/common/panel/LeaveInfo";
import PageFilter from "components/common/panel/PageFilter";
import YearSelect from "components/common/select/YearSelect";
import StatusLabel from "components/common/StatusLabel";
import Table from "components/common/Table";
import DialogMode from "enums/DialogMode";
import axios from "utils/Axios";
import { getResource } from "utils/RedactedResources";

class MyLeavesPage extends React.Component { 
  static viewKey = 'MyLeavesPage';

  columns = [
    {
      Header: <span className="w3-left">Początek</span>,
      accessor: "startDate",
      width: 120
    },
    {
      Header: <span className="w3-left">Koniec</span>,
      accessor: "endDate",
      width: 120
    },
    {
      Header: <span className="w3-left">Dni</span>,
      accessor: "days",
      width: 50
    },
    {
      Header: <span className="w3-left">Typ</span>,
      accessor: "typeKey"
    },
    {
      Header: <span className="w3-left">Status</span>,
      accessor: "statusKey",
      Cell: (props) => <StatusLabel status={props.original.status} label={props.original.statusKey} />
    },
    {
      Header: <span className="w3-left">Opis</span>,
      id: "description",
      accessor: "description"
    },
    {
      Header: "",
      id: "options",
      className: "w3-center",
      sortable: false,
      resizable: false,
      accessor: (row) => <DeleteLeaveIcon row={row} />,
      width: 40
    }
  ];

  order = [{ id: "startDate" }];

  constructor(props) {
    super(props);

    this.state = { };

    this.onShowDialog = this.onShowDialog.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
  }

  componentWillMount() {
    this.props.doSetCurrentTableView(MyLeavesPage.viewKey);
    this.props.doDataSetLoaded([], MyLeavesPage.viewKey);
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.selectedYear !== newProps.selectedYear) {
      this.loadData(newProps.selectedYear);
    }
  }

  loadData(year = this.props.selectedYear) {
    if (!year)
      return;
    
    axios
      .get(`LeaveEntries/Current?year=${year}`)
      .then((res) => {
        this.props.doDataSetLoaded(res.data, MyLeavesPage.viewKey)
      });
  }

  onShowDialog(row) {
    this.props.doOpenDialog(row, DialogMode.PREVIEW);
  }

  onAddClick() {
    this.props.doOpenDialog({}, DialogMode.ADD);
  }

  render() {
    return (
      <div>
        <h1 className="small-bottom">{getResource(this.props.currentUser.contractType, "myLeaves")}</h1>
        <LeaveInfo />
        <PageFilter>
          <div className="w3-row form-inline">
            <div className="w3-col m2 label"><label>Rok:</label></div>
            <div className="w3-col m10 value"><YearSelect from={2016}/></div>
          </div>
        </PageFilter>
        <Table columns={this.columns} order={this.order} onShowDialog={this.onShowDialog} />
        <div className="w3-section w3-bar w3-center">
          <button className="w3-button w3-round w3-green" onClick={this.onAddClick}>{getResource(this.props.currentUser.contractType, "leaveApplication")}</button>
        </div>
        <LeaveEntryDialog />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedYear: state.config.selectedYear,
    currentUser: state.session.currentUser

  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doOpenDialog: TableActions.openDialog,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(MyLeavesPage);