import * as TableActions from "actions/table";
import { Dialog, Row } from "components/common/Dialog";
import DialogMode from "enums/DialogMode";
import EntryStatus from "enums/EntryStatus";
import { enum2ComboBox } from "enums/Enum";
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import axios from "utils/Axios";
import { calcEmployeeUrl, ENTRY_PATTERN, ENTRY_REGEX } from "utils/Utils";
import moment from "moment";


export class WeekEntryDialog extends React.Component {
  static defaultProps = {
    controlling: false,
    onHoursUpdated: (row, entry, newEntry) => { }
  };

  constructor(props) {
    super(props);

    this.state = {
      entry: undefined,
      originalEntry: undefined
    };

    this.onSave = this.onSave.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onAccept = this.onAccept.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.row !== newProps.row || this.props.entry !== newProps.entry) {
      
      this.setState({
        ...this.state,
        entry: newProps.entry,
        originalEntry: newProps.entry
      });
    }
  }


  onValueChange(name, event) {
    var value = event.target.value;
    this.updateValue(name, value);
  }

  onSelectChange(name, option) {
    var value = option !== null ? option.value : undefined; 
    this.updateValue(name, value);
  }

  updateValue(name, value) {
    if (name === "hours")
      value = value.toString().replace(",", ".");

    this.setState((prevState) => {
      var entry = { ...prevState.entry };
      entry[name] = value;

      var newState = {
        ...prevState,
        entry: entry
      };
      return newState;
    });
  }

  onSave() {
    var row = this.props.row;
    var entry = this.state.entry;

    var strVal = entry.hours.toString().replace(",", ".");
    if (strVal.length > 0 && !ENTRY_REGEX.test(strVal)) {
      toastr.warning("Niepoprawny format godzin.");
      return;
    }

    if (!this.state.entry.comment)
    {
     entry.comment = "Zmodyfikowano " + moment().format('L, LTS');
    }

    var employeeUrl = calcEmployeeUrl(this.props.row, this.props.controlling);

    if (!entry.id) {
      axios
        .post(`TimeEntries/${employeeUrl}`, entry)
        .then((res) => {
          toastr.success("Wpis został zapisany.");
          row.weekEntries[entry.startDate] = res.data;
          this.props.onHoursUpdated(row, this.state.originalEntry, res.data);
          this.props.doUpdateRow(row, row);
        });
    }
    else {
      axios
        .put(`TimeEntries/${employeeUrl}/${entry.id}`, entry)
        .then((res) => {
          toastr.success("Wpis został zapisany.");
          row.weekEntries[entry.startDate] = res.data;
          this.props.onHoursUpdated(row, this.state.originalEntry, res.data);
          this.props.doUpdateRow(row, row);
        });
    }
  }

  onAccept() {
    var row = this.props.row;
    var entry = this.state.entry;

    axios
      .post(`TimeEntries/Accept`, [entry.id])
      .then((res) => {
        toastr.success("Wpis został zaakceptowany.");
        row.weekEntries[entry.startDate].status = EntryStatus.ACCEPTED.value;
        row.weekEntries[entry.startDate].statusKey = EntryStatus.ACCEPTED.label;
        this.props.doUpdateRow(row, row);
      });
  }

  onReject() {

    if (!this.state.entry.comment) {
      toastr.warning("Wypełnij pole z komentarzem.");
      return;
    }
    var row = this.props.row;
    var entry = this.state.entry;

    axios
      .post(`TimeEntries/Reject?comment=${entry.comment}`, [entry.id])
      .then((res) => {
        toastr.success("Wpis został odrzucony.");
        row.weekEntries[entry.startDate].status = EntryStatus.REJECTED.value;
        row.weekEntries[entry.startDate].statusKey = EntryStatus.REJECTED.label;
        this.props.doUpdateRow(row, row);
      });
  }

  render() {
    var { mode } = this.props;
    var entry  = this.state.entry;


    if (entry === undefined) return null;

    return (
      <Dialog
        title="Opis" 
        onClose={this.props.doClose} 
        onSave={this.onSave}
        mode={mode} 
        buttons={mode === DialogMode.ACCEPT
          ? <div className="w3-bar w3-center">
            <button className="w3-button w3-round w3-red" onClick={this.onReject}>Odrzuć</button>
            &nbsp;
            <button className="w3-button w3-round w3-blue" onClick={this.onAccept}>Zaakceptuj</button>
          </div>
          : undefined}
      >
        <Row 
          label="Godzin" 
          value={entry.hours} 
          enabled={mode === DialogMode.ADD || mode === DialogMode.EDIT} 
          onChange={this.onValueChange.bind(this, "hours")}
          pattern={ENTRY_PATTERN}
        />
        <Row
          label="Status"
          value={entry.status}
          options={enum2ComboBox(EntryStatus)}
          visible={true}
          enabled={mode === DialogMode.EDIT && this.props.controlling === true} 
          onChange={this.onSelectChange.bind(this, "status")}
        />
        <Row 
          label="Opis" 
          value={entry.description} 
          enabled={(mode === DialogMode.ADD || mode === DialogMode.EDIT) && this.props.controlling !== true} 
          onChange={this.onValueChange.bind(this, "description")}
          textarea
        />
        <Row 
          label="Mój komentarz" 
          value={entry.comment} 
          visible={mode === DialogMode.ACCEPT || (mode === DialogMode.EDIT && this.props.controlling === true)}
          enabled={mode === DialogMode.ACCEPT || (mode === DialogMode.EDIT && this.props.controlling === true)} 
          onChange={this.onValueChange.bind(this, "comment")}
          textarea
        />
     </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mode: state.table.dialogMode,
    row: state.table.selectedRow,
    entry: state.table.selection.entry
  };
};

const mapDispatchToProps = {
  doUpdateRow: TableActions.updateRow,
  doClose: TableActions.closeDialog
};

export default connect(mapStateToProps, mapDispatchToProps)(WeekEntryDialog);