import moment from "moment";
import * as React from "react";
import Select from "react-select-v1";

import axios from "utils/Axios";
import { dateToMoment, formatDate } from "utils/Utils";

export default class WeekSelect extends React.Component {
  static defaultProps = {
    onChange: (date) => {}
  }

  static FROM = 2011;

  constructor(props) {
    super(props);

    var today = moment();
    var years = this.generateYears();
    var months = this.generateMonths();

    this.state = {
      years: years,
      months: months,
      weeks: [],
      selectedYear: years[today.year() - WeekSelect.FROM],
      selectedMonth: months[today.month()],
      selectedWeek: undefined
    };

    this.onYearChange = this.onYearChange.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.onWeekChange = this.onWeekChange.bind(this);
  }

  componentDidMount() {
    this.loadWeeks();
  }

  loadWeeks() {
    axios
      .get(`TimeEntries/Weeks?monthDate=${formatDate(this.getSelectedMonthDate())}`)
      .then(res => {
        var today = formatDate(moment());
        var currentWeek;

        var weekOptions = res.data.map(el => {
          var option = {
            label: dateToMoment(el.startDate).date() + " - " + dateToMoment(el.endDate).date(),
            value: {
              start: dateToMoment(el.startDate).date(),
              end: dateToMoment(el.endDate).date()
            }
          };

          if (el.startDate <= today && today <= el.endDate)
            currentWeek = option;
          
          return option;
        });

        if (!currentWeek)
          currentWeek = weekOptions[0];

        this.setState({
          ...this.state,
          weeks: weekOptions,
          selectedWeek: currentWeek
        },
        () => this.props.onChange(this.getSelectedDate()));
      });
  }

  onYearChange(option) {
    if (option) {
      this.setState({
        ...this.state,
        selectedYear: option,
        selectedMonth: undefined,
        selectedWeek: undefined,
        weeks: undefined
      });
    }
  }

  onMonthChange(option) {
    if (option) {
      this.setState(
        {
          ...this.state,
          selectedMonth: option,
          selectedWeek: undefined,
          weeks: undefined
        },
        () => this.loadWeeks()
      );
    }
  }

  onWeekChange(option) {
    if (option) {
      this.setState(
        {
          ...this.state,
          selectedWeek: option
        },
        () => this.props.onChange(this.getSelectedDate())
      );
    }
  }

  generateYears() {
    var nextYear = moment().year() + 1;
    var array = [];
    for (var i = WeekSelect.FROM; i <= nextYear; i++) {
      array.push({
        label: i,
        value: i
      });
    }
    return array;
  }

  generateMonths() {
    return ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"].map((el, index) => {
      return {
        label: el,
        value: index
      };
    });
  }

  getSelectedDate() {
    return {
      start: moment([this.state.selectedYear.value, this.state.selectedMonth.value, this.state.selectedWeek.value.start]),
      end: moment([this.state.selectedYear.value, this.state.selectedMonth.value, this.state.selectedWeek.value.end]),
    };
  }

  getSelectedMonthDate() {
    return moment([this.state.selectedYear.value, this.state.selectedMonth.value, 1]);
  }

  render() {
    return (
      <div>
        <Select
          className="select-inline w100"
          value={this.state.selectedYear}
          onChange={this.onYearChange}
          options={this.state.years}
          clearable={false}
          placeholder="Wybierz"
          noResultsText="Brak wyników"
        />
        <Select
          className="select-inline w200"
          value={this.state.selectedMonth}
          onChange={this.onMonthChange}
          options={this.state.months}
          clearable={false}
          placeholder="Wybierz"
          noResultsText="Brak wyników"
        />
        <Select
          className="select-inline w100"
          value={this.state.selectedWeek}
          onChange={this.onWeekChange}
          options={this.state.weeks}
          clearable={false}
          placeholder="Wybierz"
          noResultsText="Brak wyników"
        />
      </div>
    );
  }
}

