import * as TableActions from "actions/table";
import Table from "components/common/Table";
import WeekSelect from "components/reports/WeekSelect";
import * as React from "react";
import { connect } from "react-redux";
import axios from "utils/Axios";
import { formatDate } from "utils/Utils";


class MyStatisticsPage extends React.Component {
  static viewKey = 'MyStatisticsPage';

  columns = [
    {
      Header: <span className="w3-left">BU</span>,
      accessor: "businessUnit",
      width: 60
    },
    {
      Header: <span className="w3-left">Centrum kosztów</span>,
      accessor: "costCenterName"
    },
    {
      Header: <span className="w3-left">Projekt</span>,
      accessor: "projectName"
    },
    {
      Header: <span className="w3-left">Klient</span>,
      accessor: "clientName"
    },
    {
      Header: <span className="w3-left">Godzin</span>,
      accessor: "hours"
    },
    {
      Header: <span className="w3-left">Dni</span>,
      id: "days",
      accessor: row => row.hours / 8
    }
  ]

  order = [{ id: "costCenterName" }, { id: "projectName" }];

  constructor(props) {
    super(props);

    this.state = {
      from: undefined,
      to: undefined,
    };

    this.onFromChange = this.onFromChange.bind(this);
    this.onToChange = this.onToChange.bind(this);
  }

  componentWillMount() {
    this.props.doSetCurrentTableView(MyStatisticsPage.viewKey);
    this.props.doDataSetLoaded([], MyStatisticsPage.viewKey);
  }

  loadData() {
    if (this.state.from !== undefined && this.state.to !== undefined) {
      axios
        .get(`TimeEntries/Current/Statistics?start=${formatDate(this.state.from)}&end=${formatDate(this.state.to)}`)
        .then((res) => {
          this.props.doDataSetLoaded(res.data, MyStatisticsPage.viewKey);
        });
    }
  }

  onFromChange(date) {
    this.setState({
      ...this.state,
      from: date.start
    }, () => this.loadData());   
  }

  onToChange(date) {
    this.setState({
      ...this.state,
      to: date.end
    }, () => this.loadData());   
  }

  render() {
    return (
      <div>
        <h1 className="small-bottom">Moje statystyki</h1>
        <div className="w3-section page-filter">
          <div className="form w3-pale-green w3-leftbar w3-border-green" style={{ width: "700px" }}>
            <div className="w3-row form-inline">
              <div className="w3-col m3 label">
                <label>Tydzień poczatkowy:</label>
              </div>
              <div className="w3-col m9 value">
                <WeekSelect onChange={this.onFromChange} />
              </div>
            </div>
            <div className="w3-row form-inline">
              <div className="w3-col m3 label">
                <label>Tydzień końcowy:</label>
              </div>
              <div className="w3-col m9 value">
                <WeekSelect onChange={this.onToChange} />
              </div>
            </div>
          </div>
        </div>
        <Table columns={this.columns} order={this.order} defaultPageSize={20} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dataSet: state.table.originalDataSet,
    currentClosing: state.session.currentClosing,
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(MyStatisticsPage);