import * as React from "react";
import FileSaver from "file-saver";

import axios from "utils/Axios";
import Card from "components/common/Card";
import MonthSelect from "components/reports/MonthSelect";
import { formatDate } from "utils/Utils";

export default class WorkReportCard extends React.Component {
  static defaultProps = {

  };

  constructor(props) {
    super(props);

    this.state = {
      month: undefined,
      spinner: false
    };

    this.onMonthChange = this.onMonthChange.bind(this);
    this.onGenerate = this.onGenerate.bind(this);
  }

  onMonthChange(date) {
    this.setState({
      ...this.state,
      month: date
    });   
  }

  onGenerate() {
    this.setState({
      ...this.state,
      spinner: true
    });

    axios
      .post(`Reports/Leaves/Month?date=${formatDate(this.state.month)}`, null, {
        responseType: "blob",
        timeout: 30000
      })
      .then(res => { 
        var blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
        this.setState({
          ...this.state,
          spinner: false
        });
        FileSaver.saveAs(blob, `Raport urlopowy za ${formatDate(this.state.month)}.xlsx`);
      })
      .catch(err => {
        this.setState({
          ...this.state,
          spinner: false
        })
      });
  }

  render() {
    return (
      <Card title="Urlopy, przerwy i praca twórcza" buttonTitle="Generuj" onClick={this.onGenerate} spinner={this.state.spinner}>
        <p>Miesięczny raport urlopów i pracy twórczej - zatrudnieni na umowę o pracę.</p>
        <div className="w3-row form-inline">
          <div className="w3-col m2 label">
            <label>Miesiąc:</label>
          </div>
          <div className="w3-col m10 value">
            <MonthSelect onChange={this.onMonthChange} />
          </div>
        </div>
      </Card>
    );
  }
}
