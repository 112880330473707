export const YEAR_SELECTED = "YEAR_SELECTED";
export const MONTH_SELECTED = "MONTH_SELECTED";
export const EMPLOYEE_SELECTED = "EMPLOYEE_SELECTED";
export const ACTIVITY_SELECTED = "ACTIVITY_SELECTED";
export const STATUS_SELECTED = "STATUS_SELECTED";

export const yearSelected = (year) => {
  return {
    type: YEAR_SELECTED,
    selectedYear: year
  };
};

export const monthSelected = (month) => {
  return {
    type: MONTH_SELECTED,
    selectedMonth: month
  };
};

export const employeeSelected = (employee) => {
  return {
    type: EMPLOYEE_SELECTED,
    selectedEmployee: employee
  };
};

export const activitySelected = (activity) => {
  return {
    type: ACTIVITY_SELECTED,
    selectedActivity: activity
  };
};

export const statusSelected = (status) => {
  return {
    type: STATUS_SELECTED,
    statusSelected: status
  };
};
