import * as React from "react";
import { connect } from "react-redux";
import Select from "react-select-v1";
import axios from "utils/Axios";

import * as ConfigActions from "actions/config";
import { formatDate } from "utils/Utils";

class EmployeeSelect extends React.Component {
  static defaultProps = {
    forceReload: false
  };

  constructor(props) {
    super(props);

    this.state = {
      options: [],
      value: undefined
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.selectedMonth !== newProps.selectedMonth || this.props.forceReload !== newProps.forceReload)
      this.loadData(newProps.selectedMonth);
  }

  loadData(month) {
    axios.get(`TimeEntries/EmployeesToAccept?monthDate=${formatDate(month)}`)
      .then((res) => {
        var options = res.data.map((el) => {
          return {
            label: el.employeeName,
            value: el
          };
        });

        var value = options.length > 0
          ? options[0] && options[0].value.status === 0 ? options[0] : undefined  // wybieramy pierwszego tylko gdy jest coś do zaakcpetowania
          : undefined;

        this.setState({
          ...this.state,
          options: options,
          value: value
        });

        if(value)
          this.props.doEmployeeSelected(value.value);
    });
  }

  onChange(option) {
    this.setState({
      ...this.state,
      value: option
    });
    this.props.doEmployeeSelected(option.value);
  }

  renderOption(option) {
    return <div>{option.value.status === 0 ? <i className="fas fa-circle w3-small w3-text-blue" style={{marginRight: "5px"}}/> : ""} {option.label} </div>
  }

  render() {
    if (!this.state.options)
      return;

    return <Select
          value={this.state.value}
          searchable={true}
          clearable={false}
          options={this.state.options}
          optionRenderer={this.renderOption}
          onChange={this.onChange}
          className="select-inline w308"
          placeholder="Wybierz"
          noResultsText="Brak wyników"
        />;
  }
}

const mapStateToProps = (state) => {
  return {
    selectedMonth: state.config.selectedMonth
  };
};

const mapDispatchToProps = {
  doEmployeeSelected: ConfigActions.employeeSelected
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSelect);