import * as React from "react";
import { connect } from "react-redux";

import * as TableActions from "actions/table";
import YesNoIcon from "components/common/icon/YesNoIcon";
import ClosingInfo from "components/common/panel/ClosingInfo";
import PageFilter from "components/common/panel/PageFilter";
import Table from "components/common/Table";
import StatsPeriodSelect from "components/controlling/StatsPeriodSelect";
import axios from "utils/Axios";
import ClosingDetails from "components/controlling/closing/DetailsManager";

class ManagerStatsPage extends React.Component { 
  static viewKey = 'ManagerStatsPage';
  
  static defaultProps = {
    fullMonth: true
  };

  columns = [
    {
      Header: <span className="w3-left">Akceptujący</span>,
      id: "manager",
      accessor: "managerName",
    },
    {
      Header: <span className="w3-left">Projekt</span>,
      id: "project",
      accessor: "projectName",
    },
    {
      Header: <span className="w3-left">Centrum kosztów</span>,
      id: "ck",
      accessor: "costCenterName",
    },
    {
      Header: <span className="w3-left">Wprowadzone</span>,
      accessor: "enteredHours",
      width: 120
    },
    {
      Header: <span className="w3-left">Wysłane</span>,
      accessor: "sendHours",
      width: 120
    },
    {
      Header: <span className="w3-left">Zaakceptowane</span>,
      accessor: "acceptedHours",
      width: 120
    },
    {
      Header: <span className="w3-left">Odrzucone</span>,
      accessor: "rejectedHours",
      width: 120
    },
    {
      Header: <span className="w3-left">Razem</span>,
      accessor: "sumHours",
      width: 120
    },
    {
      Header: "Gotowy",
      id: "ready",
      className: "w3-center",
      accessor: "ready",
      Cell: (props) => <YesNoIcon value={props.value} />,
      width: 100
    }
  ];

  order = [{ id: "manager" }, { id: "project" }, { id: "ck" }];

  constructor(props) {
    super(props);

    this.state = {
      fullMonth: props.fullMonth
    }

    this.onPeriodChange = this.onPeriodChange.bind(this);
    this.getSubComponent = this.getSubComponent.bind(this);

  }

  componentWillMount() {
    this.props.doSetCurrentTableView(ManagerStatsPage.viewKey);
    this.props.doDataSetLoaded([], ManagerStatsPage.viewKey);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData(fullMonth = this.state.fullMonth) {
    axios
      .get(`Closings/Managers?fullMonth=${fullMonth}`)
      .then((res) => {
        this.props.doDataSetLoaded(res.data, ManagerStatsPage.viewKey)
      });
  }

  onPeriodChange(fullMonth) {
    this.setState({
      fullMonth: fullMonth
    });

    this.loadData(fullMonth);
  }

  getTrProps(state, rowInfo, column) {
    if (rowInfo && rowInfo.original.ready === true) {
      return {
        className: "green"
      };
    }
    else
      return {};
  }
  getSubComponent(row) {
    if (row.original.responsibilityId)
      return <ClosingDetails responsibilityId={row.original.responsibilityId} fullMonth={this.state.fullMonth} />
  }

  render() {
    return (
      <div>
        <h1 className="small-bottom">Statystyki akceptujących</h1>
        <ClosingInfo />
        <PageFilter tableFilter>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Przedział czasu:</label></div>
            <div className="w3-col m9 value"><StatsPeriodSelect fullMonth={this.state.fullMonth} onChange={this.onPeriodChange} /></div>
          </div>
        </PageFilter> 
        <Table columns={this.columns} order={this.order} getTrProps={this.getTrProps} defaultPageSize={20} SubComponent={this.getSubComponent}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagerStatsPage);