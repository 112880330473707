import * as React from "react";

export default class YesNoIcon extends React.Component {
  static defaultProps = {
    value: undefined
  };

  render() {    
    if (this.props.value === undefined)
      return null;
    
    return <i className={"fa " + (this.props.value? "fa-check w3-text-green" : "fa-times w3-text-red")} />
  }
}
