import PageInfo from "components/common/panel/PageInfo";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import axios from "utils/Axios";
import { getResource } from "utils/RedactedResources";

  class LeaveInfo extends React.Component {
  static defaultProps = {
    controlling: false
  };

  constructor(props){
    super(props);

    this.state = {
      info: undefined,
      leaveSum: undefined,
      selectedYear: undefined
    };
  }

  componentDidMount() {
    this.loadData(this.props.employee);

  }

  // odświeżamy dane po zmianie dataSeta, żeby oświeżyć statystyki po dodaniu / usunięciu wniosku
  componentWillReceiveProps(newProps) {
    if (this.props.dataSet !== newProps.dataSet) {
      this.loadData(newProps.employee);
      this.updateLeaveSum(newProps.dataSet);
    }
  }

  loadData(employee) {
    if (!employee) {
      this.setState({
        ...this.state,
        info: undefined
      })
      return;
    }
    
    var employeeId = (this.props.controlling ? employee.id : "Current");
    
    axios
      .get(`LeaveEntries/${employeeId}/Info`)
      .then((res) => { 
        this.setState({
          ...this.state,
          info: res.data
        });
      });
  }

  isDayLabor() {
    let employmentModel = this.props.controlling ? 
      this.props.selectedEmployee.value.employmentModel : this.props.currentUser.employmentModel
    return employmentModel === "DAY_LABOR"
  }

  updateLeaveSum(leaveArray) {
    let sum = leaveArray.map(leave => leave.days).reduce((prev, curr) => prev + curr, 0);
    this.setState({
      ...this.state,
      leaveSum: sum
    })
  }

  render() {
    if (!this.state.info)
      return null;
    var contractType = "";
    var info = this.state.info;
    if (this.props.controlling)
      contractType = null;
    else contractType = this.props.currentUser.contractType;
    return (
      
      <PageInfo>
        {this.isDayLabor() ?
        <p>
        Wykorzystano w roku {this.props.selectedYear}: {this.state.leaveSum}
        </p>
        :  
        <p>
        {getResource(contractType, "leaveOrLimit")} {moment().year()}: <br />
          Poprzedni rok ({info.previousYearBalance}) + Limit ({info.currentYearLimit}) - Wykorzystano ({info.usedInCurrentYear}) = {info.currentYearRemained}
        </p>
      }
      </PageInfo> 
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    employee: (props.controlling
      ? (state.config.selectedEmployee ? state.config.selectedEmployee.value : undefined)
      : state.session.currentUser),
    dataSet: state.table.originalDataSet,
    currentUser: state.session.currentUser,
    selectedYear: state.config.selectedYear,
    selectedEmployee: state.config.selectedEmployee
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveInfo);