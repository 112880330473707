import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google"

export default function GoogleLoginButton(props) {
  
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <GoogleLogin 
        theme = "filled_blue"
        size = 'large'
        shape = 'rectangle'
        width = '400px'
        onSuccess={credentialResponse => {
          props.onSuccess(credentialResponse);
        }}
        useOneTap
      />
    </GoogleOAuthProvider>
  );
}