import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import * as TableActions from "actions/table";
import axios from "utils/Axios";
import { calcEmployeeUrl } from "utils/Utils";
import EntryStatus from "enums/EntryStatus";

class LeaveDeleteIcon extends React.Component {
  static defaultProps = {
    row: undefined,
    controlling: false
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    var employeeUrl = calcEmployeeUrl(this.props.row, this.props.controlling);
    
    toastr.confirm("Usunąć wniosek?", {
      onOk: () => {
        axios
          .delete(`LeaveEntries/${employeeUrl}/${this.props.row.id}`)
          .then(res => {
            toastr.success("Wniosek został usuniety.");
            this.props.doUpdateRow(this.props.row, null);
          });
      }
    });
  }

  canDelete() {
    return (this.props.controlling === true || this.props.row.status === EntryStatus.SEND.value || this.props.row.status === EntryStatus.REJECTED.value);
  }

  render() {
    if (!this.canDelete())
      return null;

    return <i className="far fa-trash-alt pointer" title="Usuń wniosek" onClick={this.onClick} />;
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  doUpdateRow: TableActions.updateRow
};

export default connect(mapStateToProps, mapDispatchToProps)(LeaveDeleteIcon);
