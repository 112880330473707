import * as React from "react";
import { connect } from "react-redux";
import Select from "react-select-v1";

class StatsPeriodSelect extends React.Component {
  static defaultProps = {
    fullMonth: true,
    onChange: () => {}
  };

  static OPTIONS = [
    {
      label: "cały miesiąc",
      value: true
    },
    {
      label: "do końca ost. tygodnia",
      value: false
    }
  ];

  constructor(props) {
    super(props);

    this.state = {
      fullMonth: props.fullMonth
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(option) {
    if (option) {
      this.setState({
        ...this.state,
        fullMonth: option.value
      });
  
      this.props.onChange(option.value);
    }
  }

  render() {
    return <Select className="select-inline w200" clearable={false} value={this.state.fullMonth} onChange={this.onChange} options={StatsPeriodSelect.OPTIONS} />;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StatsPeriodSelect);
