import * as React from "react";

export default class EmptyPagination extends React.Component {

  render() {
    return (
      <div className="w3-row">
        <div className="w3-half w3-margin-vert m-center">
          &nbsp;
        </div>
        <div className="w3-half w3-right-align w3-margin-vert m-center">
          &nbsp;
        </div>
      </div>
    );
  }
  
}
