import PageInfo from "components/common/panel/PageInfo";
import * as React from "react";
import { connect } from "react-redux";
import { dateToMoment } from "utils/Utils";

class ClosedInfo extends React.Component {
  static defaultProps = {

  };

  constructor(props){
    super(props);

    this.state = {};
  }

  render() {
    if (!this.props.currentClosing || !this.props.selectedMonth)
      return null;

    if (this.props.selectedMonth.isBefore(dateToMoment(this.props.currentClosing.date))) {
      return (
        <PageInfo warning>
          <p>Miesiąc jest zamknięty.</p>
        </PageInfo>
      );
    }
    else
      return null;
  }
}

const mapStateToProps = (state) => {
  return {
    currentClosing: state.session.currentClosing,
    selectedMonth: state.config.selectedMonth
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ClosedInfo);