import * as React from "react";

export default class FooterStats extends React.Component {
  static defaultProps = {
    week: undefined,
    onSend: () => {},
    onUndo: () => {}
  };

  render() {
    return (
        <div>
          <p className="w3-medium">
            {this.props.week.declaredHours} / {this.props.week.requiredHours}
          </p>
        </div>
    );
  }
}
