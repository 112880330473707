import EntryStatus from "enums/EntryStatus";
import * as React from "react";


export default class StatusLegend extends React.Component {
  static defaultProps = {
  };

  render() {
    var inputs = [];
    for (const key in EntryStatus) {
      const element = EntryStatus[key];
      inputs.push(<input type="text" className={"w3-input w3-border w3-round entry-legend entry-" + element.value.toLowerCase()}
        defaultValue={element.label.toLowerCase()} key={element.value} readOnly />);
    }

    return (
      <div className="table-filter">
        <label className="inline">Statusy wpisów:</label>
        {inputs}
      </div>
    );
  }
}