import "App.css";
import "icheck/skins/flat/blue.css";
import "moment/locale/pl";
import "react-select-v1/dist/react-select.css";
import "react-table/react-table.css";

import * as React from "react";

import { Route, Switch } from "react-router-dom";

import AcceptanceByActivityPage from "components/acceptance/byActivity/AcceptanceByActivityPage";
import AcceptanceByEmployeePage from "components/acceptance/byEmployee/AcceptanceByEmployeePage";
import Authorize from "components/Authorize";
import ClosingPage from "components/controlling/closing/ClosingPage";
import CreativeWorkAcceptancePage from "components/acceptance/creativeWorks/CreativeWorkAcceptancePage";
import CreativeWorksPage from "components/controlling/creativeWorks/CreativeWorksPage";
import EmailsPage from "components/controlling/emails/EmailsPage";
import EmployeeLeavesPage from "components/controlling/leaves/EmployeeLeavesPage";
import EmployeeTimeEntriesPage from "components/controlling/timeEntries/EmployeeTimeEntriesPage";
import EmployeesPage from "components/dictionaries/employees/EmployeesPage";
import Footer from "components/common/Footer";
import HolidaysPage from "components/dictionaries/holidays/HolidaysPage";
import LeavesReportPage from "components/reports/leaves/LeavesReportPage";
import LoginPage from "components/myAccount/LoginPage";
import LogoutPage from "components/myAccount/LogoutPage";
import MainPage from "components/MainPage";
import ManagerStatsPage from "components/controlling/managerStats/ManagerStatsPage";
import MyCreativeWorksPage from "components/basic/creativeWork/MyCreativeWorksPage";
import MyLeavesPage from "components/basic/myLeaves/MyLeavesPage";
import MyStatisticsPage from "components/basic/myStatistics/MyStatisticsPage";
import MyTimeEntriesPage from "components/basic/myTimeEntries/MyTimeEntriesPage";
import Navbar from "components/common/NavBar";
import Path from "utils/Path";
import ProjectsPage from "components/dictionaries/projects/ProjectsPage";
import ReduxToastr from "react-redux-toastr";
import SalesReportPage from "components/reports/sales/SalesReportPage";
import SettingsPage from "components/myAccount/settings/SettingsPage";
import SicksAndOtherLeavesPage from "components/controlling/sicks/SicksAndOtherLeavesPage";
import LeavesAcceptancePage from "components/acceptance/leaves/LeavesAcceptancePage";
import moment from "moment-timezone";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    moment.locale("pl");
    moment.tz.setDefault("Europe/Warsaw")

  }

  render() {
    const options = {
      okText: "Tak",
      cancelText: "Nie"
    };

    return ( 
      <div>
        <Switch>
          <Route path={Path.myAccount + Path.login} component={LoginPage} />
          <Route path={Path.myAccount + Path.logOut} component={LogoutPage} />
          <Authorize>
            <Navbar /> 
            <div className="w3-content w3-container app-container">
              <Route path={Path.main} exact component={MainPage} />
              <Route path={Path.basic + Path.timeEntries} component={MyTimeEntriesPage} />
              <Route path={Path.basic + Path.creativeWorks} component={MyCreativeWorksPage} />
              <Route path={Path.basic + Path.leaves} component={MyLeavesPage} />
              <Route path={Path.basic + Path.statistics} component={MyStatisticsPage} />
              <Route path={Path.acceptance + Path.byEmployee} component={AcceptanceByEmployeePage} />
              <Route path={Path.acceptance + Path.byActivity} component={AcceptanceByActivityPage} />
              <Route path={Path.acceptance + Path.vacations} component={LeavesAcceptancePage} />
              <Route path={Path.controlling + Path.sicks} component={SicksAndOtherLeavesPage} />
              <Route path={Path.acceptance + Path.creativeWorks} component={CreativeWorkAcceptancePage} />
              <Route path={Path.controlling + Path.timeEntries} component={EmployeeTimeEntriesPage} />
              <Route path={Path.controlling + Path.leaves} component={EmployeeLeavesPage} />
              <Route path={Path.controlling + Path.creativeWorks} component={CreativeWorksPage} />
              <Route path={Path.controlling + Path.managerStats} component={ManagerStatsPage} />
              <Route path={Path.controlling + Path.closing} component={ClosingPage} />
              <Route path={Path.controlling + Path.emails} component={EmailsPage} />
              <Route path={Path.dictionaries + Path.employees} component={EmployeesPage} />
              <Route path={Path.dictionaries + Path.projects} component={ProjectsPage} />
              <Route path={Path.dictionaries + Path.holidays} component={HolidaysPage} />
              <Route path={Path.reports + Path.sales} component={SalesReportPage} />
              <Route path={Path.reports + Path.leaves} component={LeavesReportPage} />
              <Route path={Path.myAccount + Path.settings} component={SettingsPage} /> 
            </div>
           <Footer/>
          </Authorize>  
        </Switch>
         <ReduxToastr timeOut={4000} preventDuplicates position="top-center" transitionIn="fadeIn" transitionOut="fadeOut"
          progressBar closeOnToastrClick confirmOptions={options} />
      </div>
    );
  }
}

