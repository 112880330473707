import * as React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";

import Pagination from "./Pagination";

class DialogTable extends React.Component {
  static defaultProps = {
    columns: [],
    order: [],
    onSelectRow: (row) => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedRow: undefined
    };
  }

  sort(a, b, desc) {
    a = (a === null || a === undefined ? '' : a);
    b = (b === null || b === undefined ? '' : b);

    a = (typeof a === "string" ? a.toLowerCase() : a);
    b = (typeof b === "string" ? b.toLowerCase() : b);

    if(typeof a === "string" && typeof b === "string")
      return a.localeCompare(b);
    
    if (a > b)
      return 1;
    else if (a < b)
      return -1;
    else
      return 0;
  }

  render() {
    return (
      <ReactTable
        data={this.props.dataSet}
        columns={this.props.columns}
        defaultSorted={this.props.order}
        defaultSortMethod={this.sort}
        defaultPageSize={10}
        minRows={1}
        previousText="<<"
        nextText=">>"
        loadingText="Wczytywanie..."
        noDataText="Brak wyników"
        pageText="Strona"
        ofText="z"
        rowsText="wierszy"
        pageJumpText="skocz"
        rowsSelectorText="wierszy na stronę"
        className="-striped -highlight w3-small"
        showPageSizeOptions={false}
        getTrProps={(state, rowInfo, column) => {
          if (rowInfo) {
            return {
              onClick: (e) => {
                this.props.onSelectRow(rowInfo.original);
                this.setState({ selectedRow: rowInfo.original });
              },
              style: { cursor: "pointer" },
              className: rowInfo.original === this.state.selectedRow ? "w3-dark-grey" : undefined
            };
          }
          else
            return {};
        }}
        PaginationComponent={Pagination}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataSet: state.table.dialogFilteredDataSet
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DialogTable);
