import * as React from "react";
import { connect } from "react-redux";
import Select from "react-select-v1";

import axios from "utils/Axios";
import { sort } from "utils/Utils";

class ClientSelect extends React.Component {
  static defaultProps = {
    visible: false,      
    onChange: (value) => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      dataSet: [],
      value: undefined
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.visible === true) {
      this.setState({
        ...this.state,
        value: undefined
      });
      
      if (this.state.dataSet.length === 0) {
        this.loadData();
      }
    }
  }

  loadData() {
    axios.get(`Clients`)
      .then((res) => {
        var options = res.data.map((el) => {
          return {
            label: el.name,
            value: el
          };
        });
        options.sort((a, b) => sort(a.label, b.label));
        this.setState({
          dataSet: options
        });
      });
  }

  onChange(option) {
    var isNewOption = option && (typeof option.value === 'string' || option.value instanceof String);
    if (isNewOption) {
      option = {
        label: option.label,
        value: {
            code: option.label,
            name: option.label,
            id: 0
        }
      };
    }
    this.setState({
      ...this.state,
      value: option
    });
    var changedOption = option ? option.value : undefined;
    this.props.onChange(changedOption);
  }

  promptTextCreator(label) {
    return `Dodaj nowego klienta "${label}"`;
  }

  render() {
    return (
      <div className="w308">
        <div className="padbl6">
          Wsparcie sprzedaży realizowane jest na rzecz określonego klienta
        </div>
        <div>
          <Select.Creatable
            disabled={false}
            value={this.state.value}
            searchable={true}
            clearable={false}
            options={this.state.dataSet}
            onChange={this.onChange}
            className="select-inline w308"
            placeholder="Wyszukaj lub dodaj nowego klienta"
            noResultsText="Brak wyników"
            promptTextCreator={this.promptTextCreator}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClientSelect);