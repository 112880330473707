import * as React from 'react';
import ReactTable from 'react-table';

export default class DetailsTable extends React.Component {
  static defaultProps = {
    columns: [],
    order: [],
    dataSet: []
  }

  render() {
    return <ReactTable
      data={this.props.dataSet}
      columns={this.props.columns}
      defaultSorted={this.props.order}
      defaultPageSize={10}
      minRows={1}
      noDataText="Brak wyników"
      showPagination={false}
      showPageSizeOptions={false}
    />;
  }
}
