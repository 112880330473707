import * as TableActions from "actions/table";
import { Dialog, Row } from "components/common/Dialog";
import DialogTable from "components/common/DialogTable";
import ContractType from "enums/ContractType";
import DialogMode from "enums/DialogMode";
import EntryStatus from "enums/EntryStatus";
import { enum2ComboBox } from "enums/Enum";
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr'
import axios from "utils/Axios";
import { AMOUNT_CHARS, AMOUNT_REGEX, calcEmployeeUrl, dateToMoment, formatDate, formatTime } from "utils/Utils";

class CreativeWorkDialog extends React.Component {
  static defaultProps = {
    controlling: false,
    onAccept: () => {}
  };

  columns = [
    {
      Header: "Tytuł",
      accessor: "title"
    }
  ];

  order = [{ id: "title" }];

  constructor(props) {
    super(props);

    this.state = {
      row: undefined,
      templatesDisplay: false
    };

    this.onSaveClick = this.onSaveClick.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onAccept = this.onAccept.bind(this);
    this.onShowTemplates = this.onShowTemplates.bind(this);
    this.onSelectRow = this.onSelectRow.bind(this);
  }

  componentWillMount() {
    this.props.doDataSetLoaded([]);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.row !== newProps.row) {
      this.setState({
        ...this.state,
        row: newProps.row
      });
    }
  }

  loadData() {
    axios
      .get(`CreativeWorks/Templates`)
      .then((res) => {
        this.props.doDataSetLoaded(res.data);
      });
  }

  onValueChange(name, event) {
    var value = event.target.value;

    if (name === "amount") {
      value = value.replace(",", ".");
      if (!AMOUNT_CHARS.test(value)) {
        toastr.warning("Dozwolone są tylko cyfry.");
        return;
      }
    }
    
    this.updateRowValue(name, value);
  }

  onCheckChange(name, event, checked) {
    this.updateRowValue(name, checked);
  }

  onSelectChange(name, option) {
    var value = option !== null ? option.value : undefined; 
    this.updateRowValue(name, value);
  }

  onYearChange(name, option) {
    var value = option !== null ? option.value : 2019;
    var date = dateToMoment(this.state.row[name]).year(value);
    this.updateRowValue(name, date);
  }

  onMonthChange(name, option) {
    var value = option !== null ? option.value : 1;
    var date = dateToMoment(this.state.row[name]).month(value);
    this.updateRowValue(name, formatDate(date));
  }

  updateRowValue(name, value) {
    this.setState((prevState) => {
      var row = { ...prevState.row };
      row[name] = value;

      var newState = {
        ...prevState,
        row: row,
      };
      return newState;
    });
  }

  onSaveClick() {
    if (!this.state.row.title) {
      toastr.warning("Wypełnij tytuł.");
      return;
    }

    if (!this.state.row.amount && this.props.employee.contractType === ContractType.UP.value) {
      toastr.warning("Zatrudnieni z podwyższonymi kosztami uzyskania przychodów muszą wyceniać swoje utwory.");
      return;
    }

    if (this.props.controlling === true && !this.state.row.comment) {
      toastr.warning("Wypełnij pole z komentarzem.");
      return;
    }

    if (this.state.row.amount && this.state.row.amount.length > 0 && !AMOUNT_REGEX.test(this.state.row.amount)) {
      toastr.warning("Niepoprawny format kwoty.");
      return;
    }

    var employeeUrl = calcEmployeeUrl(this.props.row, this.props.controlling);
    var data = this.state.row;

    switch (this.props.mode) {
      case DialogMode.ADD:
        axios
          .post(`CreativeWorks/${employeeUrl}`, data)
          .then((res) => {
            toastr.success("Utwór został dodany.");
            if (dateToMoment(data.date).year() === this.props.selectedYear) {
              this.props.doUpdateRow(null, res.data);
            }
            else {
              this.props.doClose();
            }
          });
        break;
      
      case DialogMode.EDIT:
        axios
          .put(`CreativeWorks/${employeeUrl}/${this.props.row.id}`, data)
          .then((res) => {
            toastr.success("Utwór został zapisany.");
            if(dateToMoment(data.date).year() === this.props.selectedYear){
              this.props.doUpdateRow(this.props.row, res.data);
            }
            else {
              this.props.doClose();
            }
          });
        break;
      
      default:
        break;
    }
  }

  onReject() {
    if (!this.state.row.comment) {
      toastr.warning("Wypełnij pole z komentarzem.");
      return;
    }

    axios
      .post(`CreativeWorks/Reject?comment=${this.state.row.comment}`, [this.state.row.id])
      .then((res) => {
        toastr.success("Odrzucono utwór.");
        this.props.doUpdateRow(this.props.row, null);
      });
  }

  onAccept() {
    axios
    .post(`CreativeWorks/Accept`, [this.state.row.id])
    .then((res) => {
      toastr.success("Zaakceptowano utwór.");
      this.props.doClose();
      this.props.onAccept();
    });
  }

  onShowTemplates() {
    if (this.props.dataSet.length === 0) {
      this.loadData();
    }
    this.setState({
      ...this.state,
      templatesDisplay: !this.state.templatesDisplay
    });
  }

  onSelectRow(row) {
    this.updateRowValue("title", row.title);
  }

  render() {
    var { mode } = this.props;
    var row  = this.state.row;

    if (row === undefined) return null;

    var arrow = this.state.templatesDisplay ? "up" : "down";
    var height = this.state.templatesDisplay ? "415px" : "0px";

    return (
      <Dialog 
        title="Utwór"
        mode={mode}
        onSave={this.onSaveClick}
        onClose={this.props.doClose}
        buttons={mode === DialogMode.ACCEPT
          ? <div className="w3-bar w3-center">
            <button className="w3-button w3-round w3-red" onClick={this.onReject}>Odrzuć</button>
            &nbsp;
            <button className="w3-button w3-round w3-blue" onClick={this.onAccept}>Zaakceptuj</button>
          </div>
          : undefined}
      >
        {(mode === DialogMode.ADD || mode === DialogMode.EDIT) &&
          <div className="show-templates">
            <p onClick={this.onShowTemplates} ><i className={"fas fa-angle-" + arrow} /> Szablony tytułów <i className={"fas fa-angle-" + arrow} /></p>
          </div>
        }
        <div style={{ height: height, overflow: "hidden" , transition: "height 0.3s ease-in-out"}}>
          <DialogTable columns={this.columns} order={this.order} dataSet={this.state.dataSet} onSelectRow={this.onSelectRow} />
        </div>
        <Row 
          label="Rok" 
          value={dateToMoment(row.date).year()}  
          onChange={this.onYearChange.bind(this, "date")}
          enabled={this.props.controlling === true}
          year
        />
        <Row label="Miesiąc" 
          value={dateToMoment(row.date).month()} 
          onChange={this.onMonthChange.bind(this, "date")}
          enabled={this.props.controlling === true}
          month
        />
        <Row label="Tytuł"
          value={row.title}
          onChange={this.onValueChange.bind(this, "title")}
          enabled={mode === DialogMode.ADD || mode === DialogMode.EDIT}
          textarea
        />
        <Row 
          label="Opis"
          value={row.description} 
          onChange={this.onValueChange.bind(this, "description")}
          enabled={(mode === DialogMode.ADD || mode === DialogMode.EDIT) && !this.props.controlling}
          textarea
        />
        <Row 
          label="Kwota"
          value={row.amount} 
          onChange={this.onValueChange.bind(this, "amount")}
          enabled={mode === DialogMode.ADD || mode === DialogMode.EDIT}
        />
        <Row 
          label="Status"
          value={row.status}
          options={enum2ComboBox(EntryStatus)}
          onChange={this.onSelectChange.bind(this, "status")}
          enabled={this.props.controlling === true}
        />
        <Row 
          label="Mój komentarz" 
          value={row.comment} 
          onChange={this.onValueChange.bind(this, "comment")} 
          visible={mode === DialogMode.ACCEPT || this.props.controlling} 
          enabled={mode === DialogMode.ACCEPT || this.props.controlling}  
          textarea
        />
        <Row 
          label="Wysłano" 
          value={formatTime(row.sendTime)}
          visible={mode !== DialogMode.ADD}  
          raw
        />
        <Row 
          label="Zaakceptowano" 
          value={formatTime(row.acceptedTime)}
          visible={mode !== DialogMode.ADD && row.status === EntryStatus.ACCEPTED.value}  
          raw
        />
        <Row 
          label="Odrzucono" 
          value={formatTime(row.acceptedTime)}
          visible={mode !== DialogMode.ADD && row.status === EntryStatus.REJECTED.value}  
          raw
        />
      </Dialog>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    mode: state.table.dialogMode,
    row: state.table.selectedRow,
    selectedYear: state.config.selectedYear,
    dataSet: state.table.dialogFilteredDataSet,
     employee: (props.controlling === true 
      ? (state.config.selectedEmployee ? state.config.selectedEmployee.value : undefined)
      : state.session.currentUser),
  };
};

const mapDispatchToProps = {
  doUpdateRow: TableActions.updateRow,
  doClose: TableActions.closeDialog,
  doDataSetLoaded: TableActions.dialogDataSetLoaded
};

export default connect(mapStateToProps, mapDispatchToProps)(CreativeWorkDialog);
