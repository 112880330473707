import * as ConfigActions from "actions/config";
import * as React from "react";
import { connect } from "react-redux";
import Select from "react-select-v1";
import axios from "utils/Axios";

class EmployeeSelect extends React.Component {
  static defaultProps = {
    forceReload: false
  };

  constructor(props) {
    super(props);

    this.state = {
      options: [],
      value: undefined
    };

    this.loadData();

    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.forceReload !== newProps.forceReload)
      this.loadData();
  }

  loadData() {
    axios.get(`CreativeWorks/EmployeesToAccept`)
      .then((res) => {
        var options = res.data.map((el) => {
          return {
            label: el.employeeName,
            value: el
          };
        });

        var value = (options.length > 0 ? options[0] : undefined);

        this.setState({
          ...this.state,
          options: options,
          value: value
        });

        if(value)
          this.props.doEmployeeSelected(value.value);
    });
  }

  onChange(option) {
    if (option) {
      this.setState({
        ...this.state,
        value: option
      });
      this.props.doEmployeeSelected(option.value);
    }
  }

  renderOption(option) {
    return <div>{option.value.status === 0 ? <i className="fas fa-circle w3-small w3-text-blue" style={{marginRight: "5px"}}/> : ""} {option.label} </div>
  }

  render() {
    if (!this.state.options)
      return;

    return <Select
          value={this.state.value}
          searchable={true}
          clearable={false}
          options={this.state.options}
          optionRenderer={this.renderOption}
          onChange={this.onChange}
          className="select-inline w308"
          placeholder="Wybierz"
          noResultsText="Brak wyników"
        />;
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = {
  doEmployeeSelected: ConfigActions.employeeSelected
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSelect);