import * as React from "react";
import Select from "react-select-v1";

import { Contract } from "enums/ContractType"; 
import { enum2ComboBox } from "enums/Enum";

export default class EmployeeFilter extends React.Component {
  static defaultProps = {
    value: Contract.ACTIVE.value,
    onChange: (value) => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(option) {
    if (option) {
      this.setState({
        ...this.state,
        value: option
      });

      this.props.onChange(option.value);
    }
  }

  render() {
    return <Select
      className="select-inline w200"
      clearable={false}
      value={this.state.value}
      onChange={this.onChange}
      options={enum2ComboBox(Contract)}
      placeholder="Wybierz..." />;
  }
}
