import * as React from "react";
import { connect } from "react-redux";

import PassChangeCard from "./PassChangeCard";
import LoginAsCard from "./LoginAsCard";

class SettingsPage extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <h1>Ustawienia konta</h1>
        <PassChangeCard />
        {this.props.currentUser.roleAdmin === true && <LoginAsCard />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.currentUser 
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
