import * as TableActions from "actions/table";
import CreativeWorkAcceptIcon from "components/acceptance/creativeWorks/CreativeWorkAcceptIcon";
import EmployeeSelect from "components/acceptance/creativeWorks/EmployeeSelect";
import CreativeWorkDialog from "components/common/dialog/CreativeWorkDialog";
import PageFilter from "components/common/panel/PageFilter";
import StatusLabel from "components/common/StatusLabel";
import Table from "components/common/Table";
import DialogMode from "enums/DialogMode";
import EntryStatus from "enums/EntryStatus";
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import axios from "utils/Axios";
import { dateToMoment } from "utils/Utils";
import * as ConfigActions from "actions/config";

class CreativeWorkAcceptancePage extends React.Component {
  static viewKey = 'CreativeWorkAcceptancePage';

  columns = [
    {
      show: false,
      id: "date",
      accessor: "date"
    },
    {
      Header: <span className="w3-left">Zatrudniony</span>,
      accessor: "employeeName",
      id: "employeeName"
    },
    {
      show: false,
      id: "date",
      accessor: "date"
    },
    {
      Header: <span className="w3-left">Miesiąc</span>,
      id: "month",
      accessor: "date",
      Cell: (props) => dateToMoment(props.value).format("MMMM"),
    },
    {
      Header: <span className="w3-left">Tytuł</span>,
      accessor: "title",
      minWidth: 500
    },
    {
      Header: <span className="w3-left">Kwota</span>,
      accessor: "amount",
      width: 200
    },
    {
      Header: <span className="w3-left">Status</span>,
      accessor: "status",
      Cell: (props) => <StatusLabel status={props.original.status} label={props.original.statusKey} />,
      width: 150
    },
    {
      Header: "",
      id: "options",
      className: "w3-center",
      sortable: false,
      resizable: false,
      Cell: (props) => props.original.status === EntryStatus.SEND.value && <CreativeWorkAcceptIcon row={props.original} onSuccess={this.reloadData} />,
      width: 40
    }
  ]

  order = [{ id: "date" }, {id: "title"}];

  constructor(props) {
    super(props);

    this.state = { 
      forceReload: false
    };

    this.onAcceptAll = this.onAcceptAll.bind(this);
    this.onShowDialog = this.onShowDialog.bind(this);
    this.reloadData = this.reloadData.bind(this);
  }

  componentWillMount() {
    this.props.doSetCurrentTableView(CreativeWorkAcceptancePage.viewKey);
    this.props.doDataSetLoaded([], CreativeWorkAcceptancePage.viewKey);
    this.props.doEmployeeSelected()
  }

  componentWillReceiveProps(newProps) {
    if (this.props.selectedEmployee !== newProps.selectedEmployee)
      this.loadData(newProps.selectedEmployee);
  }

  loadData(employee) {
    if (!employee) {
      this.props.doDataSetLoaded([], CreativeWorkAcceptancePage.viewKey);
      return;
    }

    axios
      .get(`CreativeWorks/ToAccept?employeeId=${employee.employeeId}`)
      .then((res) => {
        this.props.doDataSetLoaded(res.data, CreativeWorkAcceptancePage.viewKey);
      });
  }

  reloadData() {
    this.loadData(this.props.selectedEmployee);
  }

  onAcceptAll() {
    toastr.confirm("Zaakceptować wszystkie utwory?", {
      onOk: () => {
        axios
          .post(`CreativeWorks/Accept`, this.getAllEntriesIds())
          .then((res) => {
            toastr.success("Zaakceptowano wszystkie utwory.");
            this.setState({
              ...this.state,
              forceReload: !this.state.forceReload
            });
          });
      }
    });
  }

  getAllEntriesIds() {
    var data = [];
    this.props.dataSet.forEach(row => {
      data.push(row.id);
    });
    return data;
  }

  onShowDialog(row) {
    if (row.status === EntryStatus.SEND.value)
      this.props.doOpenDialog(row, DialogMode.ACCEPT);
    else
      this.props.doOpenDialog(row, DialogMode.PREVIEW);
  }

  render() {
    return (
      <div>
        <h1 className="small-bottom">Akceptacja utworów</h1> 
        <PageFilter>
        <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Zatrudniony:</label></div>
            <div className="w3-col m9 value"><EmployeeSelect forceReload={this.state.forceReload} /></div>
          </div>
        </PageFilter> 
        <Table columns={this.columns} order={this.order} onShowDialog={this.onShowDialog} defaultPageSize={20} />
        <div className="w3-bar w3-center">
          <button className="w3-button w3-round w3-blue" onClick={this.onAcceptAll}>Zaakceptuj wszystko</button>
        </div>
        <CreativeWorkDialog onAccept={this.reloadData} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dataSet: state.table.filteredDataSet,
    selectedEmployee: state.config.selectedEmployee,
    currentClosing: state.session.currentClosing,
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doOpenDialog: TableActions.openDialog,
  doEmployeeSelected: ConfigActions.employeeSelected,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(CreativeWorkAcceptancePage);
