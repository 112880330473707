import * as React from "react";
import { Checkbox } from "react-icheck";
import { connect } from "react-redux";

import * as TableActions from "actions/table";
import { Dialog, Row } from "components/common/Dialog";
import { getResource } from "utils/RedactedResources";


class EmployeeDialog extends React.Component {
  static defaultProps = {
  };

  render() {
    var { mode } = this.props;
    var row = this.props.row;

    if (row === undefined) return null;

    return (
      <Dialog
        title="Zatrudniony" 
        mode={mode} 
        onClose={this.props.doClose} 
      >
        <Row
          label="Id"
          value={row.id}
          raw
        />
        <Row
          label="Imię"
          value={row.firstName}
        />
        <Row
          label="Nazwisko"
          value={row.lastName}
        />
        <Row
          label="Logo"
          value={row.logo}
        />
        <Row
          label="E-mail"
          value={row.email}
        />
        <Row
          label="Business unit"
          value={row.businessUnit}
        />
         <Row
          label="Stanowisko"
          value={row.position}
        />
        <Row
          label={getResource(this.props.row.contractType, "workingHours")}
          value={row.employmentTime.toFixed(2)}
        />
        <Row
          label="Forma zatrudnienia"
          value={row.contractTypeKey}
        />

         <Row
          label="Model zatrudnienia"
          value={row.employmentModelKey}
        />

        <Row
          label="Akceptujący" value={row.bossName}
        />
        <this.RowSettings row={row} />
        <this.RowRoles row={row} />
      </Dialog>
    );
  }

  RowSettings(props) {
    return (
      <div className="w3-row form-inline">
        <div className="w3-col m4 label">
          <label>Ustawienia:</label>
        </div>
        <div className="w3-col m8 value">
          <Checkbox checkboxClass="icheckbox_flat-blue" label="Aktywny" checked={props.row.active} onChange={() => { }}/>
        </div>
      </div>
    );
  }

  RowRoles(props) {
    return (
      <div>
        <div className="w3-row form-inline">
          <div className="w3-col m4 label">
            <label>Role:</label>
          </div>
          <div className="w3-col m8 value">
            <div className="w3-col m4">
              <Checkbox checkboxClass="icheckbox_flat-blue" label={getResource(props.row.contractType, "worklog")} checked={props.row.roleTimeEntry} onChange={() => { }}/>
            </div>
            <div className="w3-col m4">
              <Checkbox checkboxClass="icheckbox_flat-blue" label="Akceptujący" checked={props.row.roleManager} onChange={() => { }}/>
            </div>
          </div>
        </div>
        <div className="w3-row form-inline">
          <div className="w3-col m4 label"><p></p></div>
          <div className="w3-col m8 value">
            <div className="w3-col m4">
              <Checkbox checkboxClass="icheckbox_flat-blue" label="Kontroling" checked={props.row.roleControlling} onChange={() => { }}/>
            </div>
            <div className="w3-col m4">
              <Checkbox checkboxClass="icheckbox_flat-blue" label="Raporty" checked={props.row.roleReports} onChange={() => { }}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mode: state.table.dialogMode,
    row: state.table.selectedRow,
    currentUser: state.session.currentUser

  };
};

const mapDispatchToProps = {
  doClose: TableActions.closeDialog
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeDialog);