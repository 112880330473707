import Card from "../../common/Card";
import EmployeeSelect from "./EmployeeSelect";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import axios from "utils/Axios";
import { ACCESS_TOKEN_KEY, TOKEN_INFO_KEY } from "utils/Utils";


class LoginAsCard extends React.Component {
  static defaultProps = {
   
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedUser: undefined
    };

    this.onEmployeeChange = this.onEmployeeChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  onEmployeeChange(user) {
    this.setState({
      ...this.state,
      selectedUser: user
    });    
  }

  onClick() {
    if (!this.state.selectedUser) {
      toastr.warning("Wybierz zatrudnionego z listy.");
      return;
    }

    axios
      .post(`Accounts/LoginAs?userId=${this.state.selectedUser.id}`)
      .then(res => { 
        this.handleGeneratedToken(res.data);
      });
  }

  handleGeneratedToken(data) {
    data.expire_time = moment().add(data.expire_time, 's').unix();
    localStorage.setItem(ACCESS_TOKEN_KEY, data.access_token);
    localStorage.setItem(TOKEN_INFO_KEY, JSON.stringify(data));
    this.redirectToMainPage();
  }
  
  redirectToMainPage() {
    window.location.href = "/";
  }

  render() {
    return (
      <Card title="Zmiana tożsamości" buttonTitle="Przeloguj" onClick={this.onClick}>
        <div className="w3-row form-inline">
          <div className="w3-col m2 label">
            <label>Wybierz zatrudnionego:</label>
          </div>
          <div className="w3-col m10 value">
            <EmployeeSelect onChange={this.onEmployeeChange}/>
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  
  };
};

const mapDispatchToProps = {

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginAsCard));
