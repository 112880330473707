import { Enum } from "./Enum";

const EntryStatus = {
  ENTERED: Enum("ENTERED", "Niewysłany"),
  SEND: Enum("SEND", "Wysłany"),
  ACCEPTED: Enum("ACCEPTED", "Zaakcept."),
  REJECTED: Enum("REJECTED", "Odrzucony")
};

export const LeaveEntryStatus = {
  SEND: Enum("SEND", "Wysłany"),
  ACCEPTED: Enum("ACCEPTED", "Zaakcept."),
  REJECTED: Enum("REJECTED", "Odrzucony")
};

export default EntryStatus;
