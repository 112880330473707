import * as React from "react";


export default class StatusLabel extends React.Component {
  static defaultProps = {
    status: undefined,
    label: undefined
  }

  render() {
    var className = "w3-tag status-" + this.props.status.toLowerCase();
    return (
      <span className={className}>{this.props.label}</span>
    );
  }
}
