import StatusLegend from "components/common/StatusLegend";
import TableFilter from "components/common/TableFilter";
import * as React from "react";

export default class PageFilter extends React.Component {
  static defaultProps = {
    tableFilter: false,
    statusLegend: false,
    skipColumns: ""
  }

  render() {
    if (!this.props.children && !this.props.tableFilter)
      return null;

    return (
      <div className="w3-section page-filter">
        <div className={"form" + (this.props.children ? " w3-pale-green w3-leftbar w3-border-green" : "")}>
          {this.props.children}
        </div>
        {this.props.tableFilter && <TableFilter skipColumns={this.props.skipColumns} />}
        {this.props.statusLegend && <StatusLegend />}
      </div>
    );
  }
}
