import * as React from "react";

import { Dialog, Row } from "components/common/Dialog";

import DialogMode from "enums/DialogMode";
import axios from "utils/Axios";
import { toastr } from 'react-redux-toastr'

export default class SendMailDialog extends React.Component {
  static defaultProps = {
    mode: DialogMode.HIDDEN,
    onClose: () => { },
    employeeIds: []
  };

  constructor(props) {
    super(props);

    this.state = {
      emailTemplate: null,
      spinner: false
    };

    this.onTestClick = this.onTestClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.mode !== newProps.mode && newProps.mode == DialogMode.PREVIEW) {
      this.loadData();
    }
  }

  loadData() {
    axios
    .get(`Emails/CUSTOM_REMINDER_TO_EMPLOYEE`)
    .then(res => { 
      this.setState({
        ...this.state,
        emailTemplate: res.data
      });
    });
  }

  onChange(name, event) {   
    var value = event.target.value;
    this.setState((prevState) => {
       var state = { ...prevState };
       state.emailTemplate[name] = value;
       return state;
    });
  }

  onSaveClick() {
    if (!this.state.emailTemplate.subject) {
      toastr.warning("Wpisz temat maila!");
      return;
    }

    if (!this.state.emailTemplate.body) {
      toastr.warning("Wpisz treść maila!");
      return;
    }

    let data = {
      emailTemplate: this.state.emailTemplate,
      employeeIds: this.props.employeeIds
    };

    this.setState({
      ...this.state,
      spinner: true
    });

    axios
      .post(`Emails/${this.state.emailTemplate.id}/SaveAndSend`, data)
      .then(res => { 
        toastr.success("Uruchomiono wysyłkę maili.");
        this.setState({
          ...this.state,
          emailTemplate: res.data,
          spinner: false
        });
      });
  }

  onTestClick() {
    this.setState({
      ...this.state,
      spinner: true
    });
    axios
      .post(`Emails/Test`, this.state.emailTemplate)
      .then(res => { 
        toastr.success("Przykładowy mail został wysłany na Twoją skrzynkę pocztową.");
        this.setState({
          ...this.state,
          spinner: false
        });
      });
  }

  render() {
    if (!this.state.emailTemplate) return null;
    
    return (
      <Dialog 
        title="Wysyłka e-maili"
        mode={this.props.mode}
        saveText={"Wyślij"}
        onSave={this.onSaveClick}
        onClose={this.props.onClose}
        buttons={<div className="w3-bar w3-center">
            {this.state.spinner === true
                ? <button className="w3-button w3-round w3-red" disabled >
                    <i className="fas fa-spinner w3-spin" /> Proszę czekać...
                  </button>
                :  <button className="w3-button w3-round w3-red" title="Wyślij przykładowy e-mail na swój adres" onClick={this.onTestClick}>Testuj</button>
            }
            &nbsp;
            {this.state.spinner === true
                ? <button className="w3-button w3-round w3-green" disabled >
                    <i className="fas fa-spinner w3-spin" /> Proszę czekać...
                  </button>
                : <button className="w3-button w3-round w3-green" onClick={this.onSaveClick}>Zapisz i wyślij</button>
            }
          </div>}
      >
         <div className="w3-panel w3-pale-blue small-top">
          <p>W temacie oraz treści maili można używać poniższych znaczników:
            <ul style={{marginTop: "0px"}}>
              <li><span className="code">{"{imie}"}</span> - imię odbiorcy wiadomości</li>
              <li><span className="code">{"{nazwisko}"}</span> - nazwisko odbiorcy wiadomości</li>
              <li><span className="code">{"{ttrack}"}</span> - link do TTracka ze słowem "tutaj"</li>
            </ul>
          </p>
        </div>
        <Row label="Liczba odbiorców"
          value={this.props.employeeIds.length}
          enabled={false}
        />
        <Row label="Temat"
          value={this.state.emailTemplate.subject}
          onChange={this.onChange.bind(this, "subject")}
          enabled={true}
        />
        <Row 
          label="Treść" 
          value={this.state.emailTemplate.body} 
          onChange={this.onChange.bind(this, "body")} 
          enabled={true} 
          textarea
        />
      </Dialog>
    );
  }
}
