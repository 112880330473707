import * as React from "react";
import { connect } from "react-redux";

import * as TableActions from "actions/table";
import YesNoIcon from "components/common/icon/YesNoIcon";
import PageFilter from "components/common/panel/PageFilter";
import Table from "components/common/Table";
import EmployeeDialog from "components/dictionaries/employees/EmployeeDialog";
import DialogMode from "enums/DialogMode";
import axios from "utils/Axios";
import EmployeeFilter from "./EmployeeFilter";
import { Contract } from "enums/ContractType";

class EmployeesPage extends React.Component {
  static viewKey = 'EmployeesPage';

  columns = [
    {
      Header: <span className="w3-left">ID</span>,
      accessor: "id",
      width: 100
    },
    {
      Header: <span className="w3-left">BU</span>,
      accessor: "businessUnit",
      width: 100
    },
    {
      Header: <span className="w3-left">Nazwisko</span>,
      accessor: "lastName"
    },
    {
      Header: <span className="w3-left">Imię</span>,
      accessor: "firstName"
    },
    {
      Header: <span className="w3-left">Umowa</span>,
      accessor: "contractTypeKey"
    },
    {
      Header: <span className="w3-left">Stanowisko</span>,
      accessor: "position"
    },
    {
      Header: "Rejestr wpisów",
      id: "roleTimeEntry",
      className: "w3-center",
      accessor: "roleTimeEntry",
      Cell: (props) => <YesNoIcon value={props.value} />,
      width: 120
    },
    {
      Header: "Akceptujący",
      id: "roleManager",
      className: "w3-center",
      accessor: "roleManager",
      Cell: (props) => <YesNoIcon value={props.value} />,
      width: 100
    },
    {
      Header: "Kontroling",
      id: "roleControlling",
      className: "w3-center",
      accessor: "roleControlling",
      Cell: (props) => <YesNoIcon value={props.value} />,
      width: 100
    },    
    {
      Header: "Raporty",
      id: "roleReports",
      className: "w3-center",
      accessor: "roleReports",
      Cell: (props) => <YesNoIcon value={props.value} />,
      width: 100
    }
  ];
  order = [{ id: "lastName" }, { id: "firstName" }];

  constructor(props) {
    super(props);

    this.state = {
      filter: Contract.ACTIVE.value
    };


    this.onFilterChange = this.onFilterChange.bind(this);
    this.onShowDialog = this.onShowDialog.bind(this);  }

  componentWillMount() {
    this.props.doSetCurrentTableView(EmployeesPage.viewKey);
    this.props.doDataSetLoaded([], EmployeesPage.viewKey);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData(filter = this.state.filter) {
    axios
      .get(`Employees/${filter}`)
      .then((res) => this.props.doDataSetLoaded(res.data, EmployeesPage.viewKey));
  }

  onFilterChange(value) {
    this.setState({
      ...this.state,
      filter: value
    });
    this.loadData(value);
  }

  onShowDialog(row) {
    this.props.doOpenDialog(row, DialogMode.PREVIEW);
  }

  render() {
    return (
      <div>
        <h1 className="small-bottom">Zatrudnieni</h1>
        <PageFilter tableFilter skipColumns="bossName">
        <div className="w3-row form-inline">
          <div className="w3-col m3 label"><label>Wybierz:</label></div>
          <div><EmployeeFilter value={this.state.filter} onChange={this.onFilterChange} /></div> 
        </div>
        </PageFilter>
        <Table columns={this.columns} order={this.order} onShowDialog={this.onShowDialog} defaultPageSize={20} />
        <EmployeeDialog />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doOpenDialog: TableActions.openDialog,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesPage);
