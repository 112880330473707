import * as React from "react";

import { Link } from "react-router-dom";
import PageInfo from "components/common/panel/PageInfo";
import Path from "utils/Path";
import axios from "utils/Axios";
import { connect } from "react-redux";
import { formatPeriod } from "utils/Utils";

class StatisticsInfo extends React.Component {
  static defaultProps = {

  };

  constructor(props){
    super(props);

    this.state = {
      statistics: undefined
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.currentUser !== newProps.currentUser || this.props.dataSet !== newProps.dataSet)
      this.loadData();
  }

  loadData() {
    axios
      .get(`Closings/Current/Statistics`)
      .then(res => {
        this.setState({
          ...this.state,
          statistics: res.data
        })
      });
  }

  render() {
    if (!this.props.currentUser || !this.state.statistics)
      return null;

    var stat = this.state.statistics;
    
    return (
      <PageInfo>
        <p>Godzin w okresie od {formatPeriod(stat.start, stat.end)}:
        Wysłanych i zaakceptowanych ({stat.sendHours + stat.acceptedHours}) / Wymaganych ({stat.requiredHours})</p>   
        <p className="small">Powyższe statystyki są cotygodniowo przekazywane do Managera Działu oraz Zarządu (w drugim dniu roboczym każdego tygodnia - najczęściej wtorek o 11:00).</p>
        {stat.creativeWorks === 0 &&
          <p>W tym miesiącu nie zadeklarowałeś żadnego utworu - zrób to. &nbsp;&nbsp;
            <Link to={Path.basic + Path.creativeWorks} className="w3-button w3-round w3-green small-button">
               Moje utwory
            </Link>
          </p>
        }    
      </PageInfo> 
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataSet: state.table.originalDataSet,
    currentUser: state.session.currentUser
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsInfo);