import PageInfo from "components/common/panel/PageInfo";
import * as React from "react";
import { connect } from "react-redux";
import { capitalizeFirstLetter, dateToMoment } from "utils/Utils";

class ClosingInfo extends React.Component {
  static defaultProps = {

  };

  constructor(props){
    super(props);

    this.state = {};
  }

  render() {
    if (!this.props.currentClosing)
      return null;

    var date = dateToMoment(this.props.currentClosing.date);
    return (
      <PageInfo>
        <p>
          {capitalizeFirstLetter(date.format("MMMM"))} {date.format("YYYY")} <br />
          Wymagana liczba godzin: {this.props.currentClosing.requiredHours}h
        </p>
      </PageInfo> 
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentClosing: state.session.currentClosing
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(ClosingInfo);