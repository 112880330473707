import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import axios from "utils/Axios";


class FillIcon extends React.Component {
  static defaultProps = {
    row: undefined,
    onSuccess: () => {}
  };

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    toastr.confirm("Dopełnić godziny domyślnym centrum kosztów?", {
      onOk: () => {
        axios
          .post(`Closings/${this.props.row.employeeId}/FillMonth?monthDate=${this.props.currentClosing.date}`)
          .then((res) => {
            this.props.onSuccess();
          });
      }
    });
  }

  render() {
    if (this.props.row.canFill !== true ) 
      return null;

    return <i className="fas fa-fill-drip pointer" title="Dopełnij godziny" onClick={this.onClick} />;
  }
}

const mapStateToProps = (state) => {
  return {
    currentClosing: state.session.currentClosing
  };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(FillIcon);
