import * as ConfigActions from "actions/config";
import * as TableActions from "actions/table";
import EmployeeSelect from "components/acceptance/byEmployee/EmployeeSelect";
import EntryAcceptIcon from "components/acceptance/EntryAcceptIcon";
import EntryCell from "components/acceptance/EntryCell";
import FooterStats from "components/acceptance/FooterStats";
import WeekEntryDialog from "components/acceptance/WeekEntryDialog";
import PageFilter from "components/common/panel/PageFilter";
import MonthSelect from "components/common/select/MonthSelect";
import Table from "components/common/Table";
import DialogMode from "enums/DialogMode";
import EntryStatus from "enums/EntryStatus";
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import axios from "utils/Axios";
import { dateToMoment, formatDate } from "utils/Utils";
import { Checkbox } from "react-icheck";

class AcceptanceByEmployeePage extends React.Component {
  static viewKey = 'AcceptanceByEmployeePage';

  columns = [
    {
      Header: "",
      id: "check",
      className: "flex",
      accessor: row => this.renderCheck(row),       
      width: 30
    },
    {
      Header: <span className="w3-left">BU</span>,
      accessor: "activity.businessUnit",
      width: 70
    },
    {
      Header: <span className="w3-left">Centrum kosztów</span>,
      id: "cc",
      accessor: "activity.costCenter.name"
    },
    {
      Header: <span className="w3-left">Projekt</span>,
      id: "project",
      accessor: (row) => {
        if (!row.activity) return null;
        return row.activity.projectId != null ? row.activity.projectTypeKey + "." + row.activity.projectName : "";
      }
    },
    {
      Header: <span className="w3-left">Klient</span>,
      id: "client",
      accessor: (row) => {
        return (row.client ? row.client.name : row.activity.clientName);
      } 
    },
    {
      Header: <span className="w3-left">Akceptujący</span>,
      accessor: "activity.managerName"
    },

    // [tygodnie]

    {
      Header: <span className="w3-center">Suma</span>,
      id: "sum",
      className: "w3-center",
      sortable: false,
      resizable: false,
      width: 70,
      accessor: (row) => {
        var sum = 0;
        for (var weKey in row.weekEntries) {
          var te = row.weekEntries[weKey];
          sum += te.hours;
        }
        return sum > 0 ? <b>{sum}</b> : null;
      },
      Footer: () => {
        var declared = 0, required = 0;
        this.state.weeks.forEach(w => required += w.requiredHours);
        this.state.weeks.forEach(w => declared += w.declaredHours);
        return <div><p className="w3-medium" style={{ margin: "8px -5px 8px -5px", fontWeight: "normal" }}>{declared} / {required}</p></div>
      }
    },
    {
      Header: "",
      id: "options",
      className: "w3-center",
      sortable: false,
      width: 40,
      accessor: row => <EntryAcceptIcon row={row} onSuccess={this.reloadData} /> 
    },
  ]

  order = [{ id: "cc" }, { id: "project" }];

  constructor(props) {
    super(props);

    this.state = {
      columns: this.columns,
      weeks: [],
      addProjectDialogMode: DialogMode.HIDDEN,
      forceReload: false,
      chosenIds: [],
    };

    this.onMonthSelect = this.onMonthSelect.bind(this);
    this.onEntryClick = this.onEntryClick.bind(this);
    this.onAcceptAll = this.onAcceptAll.bind(this);
    this.onAcceptChosen = this.onAcceptChosen.bind(this);
    this.loadData = this.loadData.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.canAcceptAll = this.canAcceptAll.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  componentWillMount() {
    this.props.doSetCurrentTableView(AcceptanceByEmployeePage.viewKey);
    this.props.doDataSetLoaded([], AcceptanceByEmployeePage.viewKey);
    this.props.doEmployeeSelected();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.selectedMonth !== newProps.selectedMonth || this.props.selectedEmployee !== newProps.selectedEmployee) {
      this.loadData(newProps.selectedMonth, newProps.selectedEmployee);
    }
  }

  loadData(month, employee) {
    if (!month || !employee) {
      this.props.doDataSetLoaded([], AcceptanceByEmployeePage.viewKey);
      if (this.state.columns.length > 6) {
        var columns = this.columns;
        this.setState({
          ...this.state,
          columns: columns
        });
      }
      return;
    }
    
    axios
      .get(`TimeEntries/${employee.employeeId}/Weeks?monthDate=${formatDate(this.props.selectedMonth)}`)
      .then((res) => {
        var weeks = res.data;
        axios
          .get(`TimeEntries/ToAcceptByEmployee?employeeId=${employee.employeeId}&monthDate=${formatDate(month)}`)
          .then((res) => {
            this.props.doDataSetLoaded(res.data, AcceptanceByEmployeePage.viewKey);
            this.createWeekColumns(weeks);
          });
      });
  }

  reloadData() {
    this.loadData(this.props.selectedMonth, this.props.selectedEmployee);
  }

  createWeekColumns(weeks) {
    var columns = this.state.columns.slice(0, 5);
    weeks.forEach((week, idx) => {
      columns.push({
        Header: dateToMoment(week.startDate).date() + " - " + dateToMoment(week.endDate).date(),
        Footer: () => <FooterStats week={week} />,
        width: 100,
        resizable: false,
        sortable: false,
        className: "w3-center entry",
        id: week.startDate,
        accessor: (row) => <EntryCell row={row} week={week} onClick={this.onEntryClick} />
      });
    });

    columns.push(this.state.columns[this.state.columns.length-2]);
    columns.push(this.state.columns[this.state.columns.length-1]);

    this.setState({
      ...this.state,
      columns: columns,
      weeks: weeks
    });
  }

  onMonthSelect() {
    this.props.doDataSetLoaded([], AcceptanceByEmployeePage.viewKey);
    
    var newWeeks = this.state.weeks.slice();
    newWeeks.forEach(value => { 
      value.declaredHours = 0;
    });
    this.setState({
      ...this.state,
      weeks: newWeeks
    });
  }

  onEntryClick(row, entry) {
    this.props.doOpenDialog(row, this.canAccept(entry) ? DialogMode.ACCEPT : DialogMode.PREVIEW, { entry: entry });
  }

  onAcceptAll() {
    toastr.confirm("Zaakceptować wszystkie wpisy?", {
      onOk: () => {
        axios
          .post(`TimeEntries/Accept`, this.getAllSendEntriesIds())
          .then((res) => {
            toastr.success("Wpisy zostały zaakceptowane.");
            this.setState({
              ...this.state,
              forceReload: !this.state.forceReload
            });
          });
          this.reloadData();
      }
    });
  }

  onAcceptChosen() {
    toastr.confirm("Zaakceptować wybrane wpisy?", {
      onOk: () => {
        axios
          .post(`TimeEntries/Accept`, this.state.chosenIds)
          .then((res) => {
            if (this.state.chosenIds.length == 0) {
              toastr.warning("Nie wybrano wpisów")
            } 
            else {
            toastr.success("Wybrane wpisy zostały zaakceptowane.");
            this.setState({
              ...this.state,
              forceReload: !this.state.forceReload
            });
            this.reloadData();
            this.setState({
              ...this.state,
              chosenIds: []
            });
          }});
      }
    });
  }

  renderCheck(row) {
    for (var key in row.weekEntries) {
      var ids = []
      var entry = row.weekEntries[key];
      for (var id in row.weekEntries) {
        ids.push(row.weekEntries[id].id)
      }
      if (this.canAccept(entry)) {        
        return (<Checkbox checkboxClass="icheckbox_flat-blue" id={ids} name="checkbox" onChange={(ids) => this.handleChange(ids)}/>) 
      }
  }
}

handleChange(event) {
  var chosenIds = this.state.chosenIds;
  var isChecked = event.target;
  if (isChecked) 
        chosenIds.push(event.target.id)
  else {
    var indexesToDelete = event.target.id.split(",")
    chosenIds = chosenIds.filter(c => indexesToDelete.indexOf(c) < 0)
  } 
  this.setState({
    ...this.state,
    chosenIds: this.toUnique(chosenIds)
  })
} 

toUnique(chosenIds) {
  const uniqueChosenIds = [...new Set(chosenIds)]
  var data = []
  for (var id in uniqueChosenIds) {
    if (uniqueChosenIds[id]) {
      var indexes = uniqueChosenIds[id].split(",")
      for (var i in indexes) {
          data.push(indexes[i])
      }  
    }
  }
  return data;
}

  getAllSendEntriesIds() {
    var data = [];
    this.props.dataSet.forEach(row => {
      for (var key in row.weekEntries) {
        var entry = row.weekEntries[key];
        if (this.canAccept(entry))
          data.push(entry.id);
      }
    });
    return data;
  }

  getSumOfEntriesToAccept() {
    var sum = 0;
    this.props.dataSet.forEach(row => {
      for (var key in row.weekEntries) {
        var entry = row.weekEntries[key];
        if (this.canAccept(entry) && entry.status === EntryStatus.SEND.value)
          sum += entry.hours;
      }
    });
    return sum;
  }

  canAccept(entry) {
    return entry.id && entry.status === EntryStatus.SEND.value && entry.canAccept === true;
  }

  getTrProps(state, rowInfo, column) {
    if (rowInfo && rowInfo.original.canAccept !== true) {
      return {
        className: "text-grey"
      };
    }
    else
      return {};
  }

  canAcceptAll() {
    for (var row in this.props.dataSet) {
      var entries = this.props.dataSet[row].weekEntries;
      if (entries) {    
        for (var date in entries) {
          var entry = entries[date];
          if (entry && entry.hours > 0 && entry.status === EntryStatus.SEND.value && entry.canAccept === true)
            return true;
        }
      }
    };

    return false;
  }

  canAcceptChosen() {
    for (var row in this.props.dataSet) {
      var entries = this.props.dataSet[row].weekEntries;
      if (entries) {    
        for (var date in entries) {
          var entry = entries[date];
          if (entry && entry.hours > 0 && entry.status === EntryStatus.SEND.value && entry.canAccept === true)
            return true;
        }
      }
    };

    return false;
  }

  render() {
    var monthDate = dateToMoment(this.props.currentClosing.date);

    return (
      <div>
        <h1 className="small-bottom">Akceptacja po zatrudnionych</h1>
        <PageFilter statusLegend >
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Miesiąc:</label></div>
            <div className="w3-col m9 value"><MonthSelect monthDate={monthDate} onSelect={this.onMonthSelect}/></div>
          </div>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Zatrudniony:</label></div>
            <div className="w3-col m9 value"><EmployeeSelect forceReload={this.state.forceReload} /></div>
          </div>
        </PageFilter>  
        <Table columns={this.state.columns} order={this.order} getTrProps={this.getTrProps}/>
        { this.getSumOfEntriesToAccept() > 0 && <div className="moveUpMargin">Suma wpisów do akceptacji: {this.getSumOfEntriesToAccept()}</div>}
        <div className="w3-bar w3-center">
          <button className="w3-button w3-round w3-blue" onClick={this.onAcceptAll} disabled={!this.canAcceptAll()}>Zaakceptuj wszystko</button>
          &nbsp;&nbsp;&nbsp;
          <button className="w3-button w3-round w3-blue" onClick={this.onAcceptChosen} disabled={!this.canAcceptAll()}>Zaakceptuj wybrane</button>
        </div>
        <WeekEntryDialog />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dataSet: state.table.filteredDataSet,
    selectedMonth: state.config.selectedMonth,
    selectedEmployee: state.config.selectedEmployee,
    currentClosing: state.session.currentClosing,
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doEmployeeSelected: ConfigActions.employeeSelected,
  doUpdateRow: TableActions.updateRow,
  doOpenDialog: TableActions.openDialog,
  doSelectRow: TableActions.selectRow,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptanceByEmployeePage);

