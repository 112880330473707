import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import * as TableActions from "actions/table";
import axios from "utils/Axios";


class DeleteIcon extends React.Component {
  static defaultProps = {
    row: undefined
  };

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    toastr.confirm("Usunąć zaznaczone święto?", {
      onOk: () => {
        axios
          .delete(`Holidays/${this.props.row.id}`)
          .then((res) => {
            this.props.doUpdateRow(this.props.row, null);
          });
      }
    });
  }

  render() {
    return <i className="far fa-trash-alt pointer" onClick={this.onClick} title="Usuń" />
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  doUpdateRow: TableActions.updateRow
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteIcon);
