import * as React from 'react';
import axios from "utils/Axios";

export default class Footer extends React.Component {

  loadData() {
    axios
      .get('/Status/LoadVersion')
      .then((res) => {
        this.setState({
          ...this.state,
          version: res.data.version,
        });
      });
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    let currYear = new Date().getFullYear();
    if (this.state){
      var version = this.state.version;
    }
    
    return (
      <footer className="footer w3-padding">
        <p>TTrack {version}</p>
        <p>Copyright &copy; 2019 - {currYear} BMS sp. z o.o.</p>
      </footer>
    );
  } 
}