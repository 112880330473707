import * as TableActions from "actions/table";
import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import axios from "utils/Axios";


class UndoCreativeWorkIcon extends React.Component {
  static defaultProps = {
    row: undefined,
    disabled: false,
    onSuccess: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    toastr.confirm("Wycofać wskazany utwór?", {
      onOk: () => {
        axios
          .post(`CreativeWorks/Current/Undo?id=${this.props.row.id}`)
          .then((res) => {
            toastr.success("Utwór został wycofany.");
            this.props.doUpdateRow(this.props.row, res.data);
          });
      }
    });
  }

  render() {   
    if (!this.props.row.id || this.props.disabled === true)
      return null;
    
    return (
      <i className="fas fa-undo-alt pointer" title="Wycofaj" onClick={this.onClick} />
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  doUpdateRow: TableActions.updateRow
};

export default connect(mapStateToProps, mapDispatchToProps)(UndoCreativeWorkIcon);
