import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import * as TableActions from "actions/table";
import ActivitySelect from "components/acceptance/byActivity/ActivitySelect";
import EntryAcceptIcon from "components/acceptance/EntryAcceptIcon";
import EntryCell from "components/acceptance/EntryCell";
import WeekEntryDialog from "components/acceptance/WeekEntryDialog";
import PageFilter from "components/common/panel/PageFilter";
import MonthSelect from "components/common/select/MonthSelect";
import Table from "components/common/Table";
import DialogMode from "enums/DialogMode";
import EntryStatus from "enums/EntryStatus";
import axios from "utils/Axios";
import { dateToMoment, formatDate } from "utils/Utils";

class AcceptanceByActivityPage extends React.Component {
  static viewKey = 'AcceptanceByActivityPage';

  columns = [
    {
      Header: <span className="w3-left">BU</span>,
      accessor: "employee.businessUnit",
      width: 70
    },
    {
      Header: <span className="w3-left">Imię</span>,
      id: "firstName",
      accessor: "employee.firstName"
    },
    {
      Header: <span className="w3-left">Nazwisko</span>,
      id: "lastName",
      accessor: "employee.lastName"
    },
    {
      Header: <span className="w3-left">Klient</span>,
      id: "client",
      accessor: "client.name"
    },

    // [tygodnie]
    
    {
      Header: <span className="w3-center">Suma</span>,
      id: "sum",
      className: "w3-center",
      sortable: false,
      resizable: false,
      width: 70,
      accessor: (row) => {
        var sum = 0;
        for (var weKey in row.weekEntries) {
          var te = row.weekEntries[weKey];
          sum += te.hours;
        }
        return sum > 0 ? <b>{sum}</b> : null;
      }
    },
    {
      Header: "",
      id: "options",
      className: "w3-center",
      sortable: false,
      width: 40,
      accessor: row => <EntryAcceptIcon row={row} onSuccess={this.reloadData} /> 
    }
  ]

  order = [{ id: "lastName" }, { id: "firstName" }];

  constructor(props) {
    super(props);

    this.state = {
      columns: this.columns,
      weeks: [],
      addProjectDialogMode: DialogMode.HIDDEN,
      forceReload: false
    };

    this.onMonthSelect = this.onMonthSelect.bind(this);
    this.onEntryClick = this.onEntryClick.bind(this);
    this.onAcceptAll = this.onAcceptAll.bind(this);
    this.loadData = this.loadData.bind(this);
    this.reloadData = this.reloadData.bind(this);
  }

  componentWillMount() {
    this.props.doSetCurrentTableView(AcceptanceByActivityPage.viewKey);
    this.props.doDataSetLoaded([], AcceptanceByActivityPage.viewKey);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.selectedMonth !== newProps.selectedMonth || this.props.selectedActivity !== newProps.selectedActivity) {
      this.loadData(newProps.selectedMonth, newProps.selectedActivity);
    }
  }

  loadData(month, activity) {
    if (!month || !activity) {
      this.props.doDataSetLoaded([], AcceptanceByActivityPage.viewKey);
      return;
    }
    
    axios
      .get(`TimeEntries/Weeks?monthDate=${formatDate(this.props.selectedMonth)}`)
      .then((res) => {
        var weeks = res.data;
        axios
          .post(`TimeEntries/ToAcceptByActivity?activityId=${activity.activityId}&monthDate=${formatDate(month)}`)
          .then((res) => {
            this.props.doDataSetLoaded(res.data, AcceptanceByActivityPage.viewKey);
            this.createWeekColumns(weeks);
          });
      });
  }

  reloadData() {
    this.loadData(this.props.selectedMonth, this.props.selectedActivity);
  }

  createWeekColumns(weeks) {
    var columns = this.state.columns.slice(0, 4);
    weeks.forEach((week, idx) => {
      columns.push({
        Header: dateToMoment(week.startDate).date() + " - " + dateToMoment(week.endDate).date(),
        width: 100,
        resizable: false,
        sortable: false,
        className: "w3-center entry",
        id: week.startDate,
        accessor: (row) => <EntryCell row={row} week={week} onClick={this.onEntryClick} />
      });
    });

    columns.push(this.state.columns[this.state.columns.length-2]);
    columns.push(this.state.columns[this.state.columns.length-1]);

    this.setState({
      ...this.state,
      columns: columns,
      weeks: weeks
    });
  }

  onMonthSelect() {
    this.props.doDataSetLoaded([], AcceptanceByActivityPage.viewKey);
  }

  onEntryClick(row, entry) {
    this.props.doOpenDialog(row, DialogMode.ACCEPT, { entry: entry });
  }

  onAcceptAll() {
    toastr.confirm("Zaakceptować wszystkie wpisy?", {
      onOk: () => {
        axios
          .post(`TimeEntries/Accept`, this.getAllEntriesIds())
          .then((res) => {
            toastr.success("Zaakceptowano wszystkie wpisy.");
            this.setState({
              ...this.state,
              forceReload: !this.state.forceReload
            });
          });
          this.reloadData();
      }
    });
  }

  getAllEntriesIds() {
    var data = [];
    this.props.dataSet.forEach(row => {
      for (var key in row.weekEntries) {
        var entry = row.weekEntries[key];
        data.push(entry.id);
      }
    });
    return data;
  }

  getSumOfEntriesToAccept() {
    var sum = 0;
    this.props.dataSet.forEach(row => {
      for (var key in row.weekEntries) {
        var entry = row.weekEntries[key];
        if (entry.status === EntryStatus.SEND.value)
          sum += entry.hours;
      }
    });
    return sum;
  }

  render() {
    var monthDate = dateToMoment(this.props.currentClosing.date);

    return (
      <div>
        <h1 className="small-bottom">Akceptacja po projektach / ck</h1>
        <PageFilter statusLegend>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Miesiąc:</label></div>
            <div className="w3-col m9 value"><MonthSelect monthDate={monthDate} onSelect={this.onMonthSelect}/></div>
          </div>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Projekt:</label></div>
            <div className="w3-col m9 value"><ActivitySelect forceReload={this.state.forceReload} /></div>
          </div>
        </PageFilter>  
        <Table columns={this.state.columns} order={this.order} />
        { this.getSumOfEntriesToAccept() > 0 && <div className="moveUpMargin">Suma wpisów do akceptacji: {this.getSumOfEntriesToAccept()}</div>}
        <div className="w3-bar w3-center">
            <button className="w3-button w3-round w3-blue" onClick={this.onAcceptAll} disabled={this.getSumOfEntriesToAccept() <= 0}>Zaakceptuj wszystko</button>
        </div>
        <WeekEntryDialog />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dataSet: state.table.filteredDataSet,
    selectedMonth: state.config.selectedMonth,
    selectedActivity: state.config.selectedActivity,
    currentClosing: state.session.currentClosing
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doUpdateRow: TableActions.updateRow,
  doOpenDialog: TableActions.openDialog,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptanceByActivityPage);
