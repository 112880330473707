import * as React from "react";
import * as TableActions from "actions/table";
import { dateToMoment, formatDate } from "utils/Utils";

import AcceptanceInfo from "components/common/panel/AcceptanceInfo";
import AddProjectDialog from "components/basic/myTimeEntries/AddProjectDialog";
import ClosedInfo from "components/common/panel/ClosedInfo";
import DeleteFavouriteIcon from "components/common/icon/DeleteFavouriteIcon";
import DialogMode from "enums/DialogMode";
import EntryCell from "components/basic/myTimeEntries/EntryCell";
import EntryStatus from "enums/EntryStatus";
import FooterStats from "components/basic/myTimeEntries/FooterStats";
import MonthSelect from "components/common/select/MonthSelect";
import PageFilter from "components/common/panel/PageFilter";
import StatisticsInfo from "components/common/panel/StatisticsInfo";
import PageInfo from "components/common/panel/PageInfo";
import MyTimeEntryState from "enums/MyTimeEntryState";
import Table from "components/common/Table";
import WeekEntryDialog from "components/acceptance/WeekEntryDialog";
import axios from "utils/Axios";
import { connect } from "react-redux";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import { getResource } from "utils/RedactedResources";

class MyTimeEntriesPage extends React.Component {
  static viewKey = 'MyTimeEntriesPage';

  columns = [
    {
      Header: <span className="w3-left">BU</span>,
      accessor: "activity.businessUnit",
      width: 60
    },
    {
      Header: <span className="w3-left">Centrum kosztów</span>,
      Footer: <button className="w3-button w3-round w3-green" onClick={this.onAddProjectClick.bind(this)} disabled={this.isMonthClosed()} ><i className="fas fa-plus" /> Dodaj</button>,
      id: "cc",
      accessor: "activity.costCenter.name"
    },
    {
      Header: <span className="w3-left">Projekt</span>,
      id: "project",
      accessor: (row) => {
        return row.activity.projectId != null ? row.activity.projectTypeKey + "." + row.activity.projectName : "";
      }
    },
    {
      Header: <span className="w3-left">Klient</span>,
      id: "client",
      accessor: (row) => {
        return (row.client ? row.client.name : row.activity.clientName);
      }
    },
    {
      Header: <span className="w3-left">Akceptujący</span>,
      accessor: "activity.managerName"
    },

    // [tygodnie]
    
    {
      Header: <span className="w3-center">Suma</span>,
      id: "sum",
      className: "w3-center",
      sortable: false,
      resizable: false,
      width: 70,
      accessor: (row) => {
        var sum = 0;
        for (var weKey in row.weekEntries) {
          var te = row.weekEntries[weKey];
          sum += te.hours;
        }
        return sum > 0 ? <b>{sum}</b> : null;
      },
      Footer: () => {
        var declared = 0, required = 0;
        this.state.weeks.forEach(w => required += w.requiredHours);
        this.state.weeks.forEach(w => declared += w.declaredHours);
        return (
        <div>
          <p className="w3-medium" style={{ margin: "8px -5px 15px 0px", fontWeight: "normal" }}>{declared} / {required}
          </p>
          <div className="w3-bar">
            {this.getConfirmedButton()}

          </div>
          </div>);
        
      }
    },

    
    {
      Header: "",
      id: "options",
      className: "w3-center",
      sortable: false,
      resizable: false,
      width: 50,
      accessor: row => !this.isMonthClosed() && <DeleteFavouriteIcon row={row} />
    }
  ]

  order = [{ id: "cc" }, { id: "project" }];

  constructor(props) {
    super(props);

    this.state = {
      columns: this.columns,
      weeks: [],
      addProjectDialogMode: DialogMode.HIDDEN,
      monthConfirmed: false
    };

    this.onHoursUpdated = this.onHoursUpdated.bind(this);
    this.onEntryInfoClick = this.onEntryInfoClick.bind(this);
    this.onAddProjectClick = this.onAddProjectClick.bind(this);
    this.onAddProjectClose = this.onAddProjectClose.bind(this);
    this.onSendAll = this.onSendAll.bind(this);
    this.isMonthClosed = this.isMonthClosed.bind(this);
    this.onMonthConfirmation = this.onMonthConfirmation.bind(this);
  }

  componentWillMount() {
    this.props.doSetCurrentTableView(MyTimeEntriesPage.viewKey);
    this.props.doDataSetLoaded([], MyTimeEntriesPage.viewKey);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.selectedMonth !== newProps.selectedMonth) {
      this.loadData(newProps.selectedMonth);
    }
  }

  loadData(month = this.props.selectedMonth) {
    axios
      .get(`TimeEntries/Current/Weeks?monthDate=${formatDate(month)}`)
      .then((res) => {
        var weeks = res.data;
        axios
          .get(`TimeEntries/Current?monthDate=${formatDate(month)}`)
          .then((res) => {
            this.props.doDataSetLoaded(res.data, MyTimeEntriesPage.viewKey);
            this.createWeekColumns(weeks);
          });
      
        });
    axios
      .get(`Closings/CheckMonthConfirmationStatus?month=${formatDate(month)}&employeeId=${this.props.currentUser.id}`)
      .then((res) => {
        this.setState({
          ...this.state,
          monthConfirmed: res.data
        });
      });
  }

  hasHoursReady() {

    var declared = 0, required = 0;
    this.state.weeks.forEach(w => required += w.requiredHours);
    this.state.weeks.forEach(w => declared += w.declaredHours); 
    var reason = ""; 


    if (this.isDayLabor()){
      
      if (this.isMonthClosed()){  // jeśli miesiąc zamknięty: szary przycisk
        reason = "miesiąc jest zamknięty" 
        return {type: MyTimeEntryState.DISABLED, reason};
      }   

      if (this.hasEntriesToSubmit()) // jeśli na akord i nie wysłał godzin: szary przycisk
      {
        reason = "nie wysłano wszystkich godzin"
        return {type: MyTimeEntryState.DISABLED, reason};
      }  
      
      else if (this.state.monthConfirmed) // jeśli na akord i zatwierdził miesiąc: zielony przycisk
        return {type: MyTimeEntryState.READY};
      
      return {type: MyTimeEntryState.PENDING};
    }
      return "";
    }

    isPending(){
      if (this.hasHoursReady().type === MyTimeEntryState.PENDING)
        return true
      return false
    }
  

  createWeekColumns(weeks) {
    var columns = this.state.columns.slice(0, 5);
    weeks.forEach((week, idx) => {
      var period = dateToMoment(week.startDate).date() + " - " + dateToMoment(week.endDate).date();
      columns.push({
        Header: <div className="week-header" >tydz<br/>{period}</div>,
        Footer: () => <FooterStats week={week} onSend={this.onSend.bind(this, week)} onUndo={this.onUndo.bind(this, week)} disabled={this.isMonthClosed() || this.state.monthConfirmed} />,
        width: 100,
        resizable: false,
        sortable: false,
        className: "w3-center entry",
        id: week.startDate,
        accessor: (row) => <EntryCell row={row} week={week} onHoursUpdated={this.onHoursUpdated} onInfoClick={this.onEntryInfoClick} disabled={this.isMonthClosed() || this.state.monthConfirmed} />
      });
    });

    columns.push(this.state.columns[this.state.columns.length-2]);
    columns.push(this.state.columns[this.state.columns.length-1]);

    this.setState({
      ...this.state,
      columns: columns,
      weeks: weeks
    });
  }

  onHoursUpdated(row, oldEntry, newEntry) {
    row.weekEntries[oldEntry.startDate] = newEntry;
    this.updateStatistics(oldEntry.startDate, newEntry.hours - oldEntry.hours);
    this.props.doUpdateRow(row, row);
  }

  onEntryInfoClick(row, entry) {
    var enabled = ((entry.status === EntryStatus.ENTERED.value || entry.status === EntryStatus.REJECTED.value) && row.activity.costCenter.leave === false && !this.isMonthClosed());
    this.props.doOpenDialog(row, enabled ? DialogMode.EDIT : DialogMode.PREVIEW, { entry: entry });
  }

  updateStatistics(date, diff) {
    var newWeeks = this.state.weeks.slice();
    newWeeks.forEach((week) => {
      if (week.startDate === date) {
        week.declaredHours += diff;
      }
    });
    this.setState({
      ...this.state,
      weeks: newWeeks
    });
  }

  onAddProjectClick() {
    this.setState({
      ...this.state,
      addProjectDialogMode: DialogMode.ADD
    });
  }

  onAddProjectClose() {
    this.setState({
      ...this.state,
      addProjectDialogMode: DialogMode.HIDDEN
    });
  
  }

  onMonthConfirmation() {
    if (!this.isDayLabor())
      return;
    if (this.hasEntriesToSubmit())
    {
      toastr.warning("Wyślij wpisy");
      return;
    }

    var isConfirming = !this.state.monthConfirmed;
    toastr.confirm("Czy wprowadziłeś wszystkie przepracowane na akord godziny?", {
      onOk: () => {
        axios
          .post(`Closings/ChangeMonthConfirmationStatus?month=${formatDate(this.props.selectedMonth)}&employeeId=${this.props.currentUser.id}&confirmed=${isConfirming}`)
          .then((res) => {
            toastr.success("Zatwierdzono");
            this.loadData();
          });
      }
    });
  }

  onSend(week) {
    toastr.confirm("Wysłać wpisy z wybranego tygodnia do akceptacji?", {
      onOk: () => {
        axios
          .post(`TimeEntries/Current/Submit?start=${week.startDate}&end=${week.endDate}`)
          .then((res) => {
            toastr.success("Wpisy zostały wysłane.");
            this.loadData();
          });
      }
    });
  }

  onUndo(week) {
    toastr.confirm("Wycofać wpisy z wybranego tygodnia?", {
      onOk: () => {
        axios
          .post(`TimeEntries/Current/Undo?start=${week.startDate}&end=${week.endDate}`)
          .then((res) => {
            toastr.success("Wpisy zostały wycofane.");
            this.loadData();
          });
      }
    });
  }

  onSendAll() {
    toastr.confirm("Wysłać wpisy z wybranego miesiąca do akceptacji?", {
      onOk: () => {
        var start = formatDate(this.props.selectedMonth);
        var end = formatDate(moment(this.props.selectedMonth).endOf("month"));
        axios
          .post(`TimeEntries/Current/Submit?start=${start}&end=${end}`)
          .then((res) => {
            toastr.success("Wpisy zostały wysłane.");
            this.loadData();
          });
      }
    });
  }

  isMonthClosed() {
    return this.props.selectedMonth && this.props.selectedMonth.isBefore(dateToMoment(this.props.currentClosing.date));
  }

  getConfirmedButton()  {
    var result = this.hasHoursReady();
    var icon = "";
    var reason = result.reason;
    var title = '';
    var action = () => {};
    var cursorDisabled = "";
    var color = null;
      switch (result.type) {
        case MyTimeEntryState.READY:
          color = 'green'
          icon = "fas fa-user-check "
          title = "Potwierdziłeś wprowadzenie wszystkich godzin"
          cursorDisabled = "button-display-only"
          break;
        case MyTimeEntryState.DISABLED: 
          color = 'grey'
          icon = "fas fa-user-times "
          title = "Nie możesz potwierdzić wprowadzonych godzin (" + reason + ")"
          cursorDisabled = "button-cursor-disabled w3-button"
          break;
        case MyTimeEntryState.PENDING:
          color = 'amber'
          icon = "fas fa-user-clock "
          title = "Potwierdź wprowadzenie wszystkich godzin"
          cursorDisabled = "w3-button"
          action = this.onMonthConfirmation;
          break;
      }
      return this.props.currentUser.employmentModel === 'DAY_LABOR' ? (<button color="" className={cursorDisabled + "  w3-round w3-" + color + " image"} onClick={action}
      title={title}>
      <i className={icon} /></button> ) : "";
  }
  hasEntriesToSubmit() {
    for (var row in this.props.dataSet) {
      var entries = this.props.dataSet[row].weekEntries;
      if (entries) {    
        for (var date in entries) {
          var entry = entries[date];
          if (entry && entry.hours > 0 && (entry.status === EntryStatus.ENTERED.value || entry.status === EntryStatus.REJECTED.value))
            return true;
        }
      }
    };

    return false;
  }

  isDayLabor() {
    return this.props.currentUser.employmentModel === 'DAY_LABOR';
  }

  canAccept() {
    for (var row in this.props.dataSet) {
      var entries = this.props.dataSet[row].weekEntries;
      if (entries) {    
        for (var date in entries) {
          var entry = entries[date];
          if (entry && entry.hours > 0 && (entry.status !== EntryStatus.ENTERED.value || entry.status !== EntryStatus.REJECTED.value))
            return true;
        }
      }
    };
    return false;
  }

  render() {
    var buttonStatus = this.state.monthConfirmed || !this.hasEntriesToSubmit();

    var isPending = this.isPending();
    
    return (
      <div>
        <h1 className="small-bottom">{getResource(this.props.currentUser.contractType, "worklog")} </h1>
        <StatisticsInfo />
        <AcceptanceInfo />

        {
          isPending ? 
        <PageInfo warning>
        <p>Pamiętaj, aby na koniec miesiąca potwierdzić wprowadzenie wszystkich godzin. &nbsp; <i className="fas fa-user-clock" /></p>   
            </PageInfo> : ""
        }

        <PageFilter statusLegend >
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Miesiąc:</label></div>
            <div className="w3-col m9 value"><MonthSelect /></div>
          </div>
        </PageFilter>  
        <ClosedInfo />
        <Table columns={this.state.columns} order={this.order} defaultPageSize={20} />
        <div className="w3-bar w3-center">
          <button className="w3-button w3-round w3-blue" onClick={this.onSendAll} disabled={this.isMonthClosed() || buttonStatus} >Wyślij miesiąc do akceptacji</button>
        </div>
        <AddProjectDialog mode={this.state.addProjectDialogMode} onClose={this.onAddProjectClose} />
        <WeekEntryDialog onHoursUpdated={this.onHoursUpdated} />
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    dataSet: state.table.originalDataSet,
    currentClosing: state.session.currentClosing,
    selectedMonth: state.config.selectedMonth,
    currentUser: state.session.currentUser
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doOpenDialog: TableActions.openDialog,
  doUpdateRow: TableActions.updateRow,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(MyTimeEntriesPage);
