import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import Select from "react-select-v1";

import * as ConfigActions from "actions/config";

class YearSelect extends React.Component {
  static defaultProps = {
    from: 2011

  };

  constructor(props) {
    super(props);

    var today = moment();
    var years = this.generateYears();

    this.state = {
      years: years,
      selectedYear: years[today.year() - this.props.from],
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.doYearSelected(this.state.selectedYear.value);
  }

  generateYears() {
    var nextYear = moment().year() + 1;
    var array = [];
    for (var i = this.props.from; i <= nextYear; i++) {
      array.push({
        label: i,
        value: i
      });
    }
    return array;
  }

  onChange(option) {
    if (option) {
      this.setState({
        ...this.state,
        selectedYear: option
      });
      this.props.doYearSelected(option.value);
    }
  }

  render() {
    return <Select
      className="select-inline w100"
      clearable={false}
      value={this.state.selectedYear}
      onChange={this.onChange}
      options={this.state.years}
    />;
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  doYearSelected: ConfigActions.yearSelected
};

export default connect(mapStateToProps, mapDispatchToProps)(YearSelect);