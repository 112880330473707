
import * as React from "react";
import { connect } from "react-redux";

import * as ConfigActions from "actions/config";
import * as TableActions from "actions/table";
import LeaveEntryDialog from "components/common/dialog/LeaveEntryDialog";
import PageFilter from "components/common/panel/PageFilter";
import EmployeeSelect from "components/common/select/EmployeeSelect";
import YearSelect from "components/common/select/YearSelect";
import StatusLabel from "components/common/StatusLabel";
import Table from "components/common/Table";
import DialogMode from "enums/DialogMode";
import axios from "utils/Axios";
import { toastr } from "react-redux-toastr";
import LeaveAcceptIcon from "components/acceptance/LeaveAcceptIcon";
import LeavesStatusSelect from "components/controlling/leaves/LeavesStatusSelect";
import moment from "moment";
import { LeaveEntryStatus } from "enums/EntryStatus";

class LeavesAcceptancePage extends React.Component { 
  static viewKey = 'LeavesAcceptancePage';

  columns = [
    {
      Header: <span className="w3-left">Początek</span>,
      accessor: "startDate",
      width: 120
    },
    {
      Header: <span className="w3-left">Koniec</span>,
      accessor: "endDate",
      width: 120
    },
    {
      Header: <span className="w3-left">Zatrudniony</span>,
      accessor: "employeeName",
      width: 200
    },
    {
      Header: <span className="w3-left">Dni</span>,
      accessor: "days",
      width: 50
    },
    {
      Header: <span className="w3-left">Typ</span>,
      accessor: "typeKey",
    },
    {
      Header: <span className="w3-left">Status</span>,
      accessor: "statusKey",
      Cell: (props) => <StatusLabel status={props.original.status} label={props.original.statusKey} />
    },
    {
      Header: <span className="w3-left">Opis</span>,
      id: "description",
      accessor: "description"
    },
    {
      Header: "",
      id: "options",
      className: "w3-center",
      sortable: false,
      resizable: false,
      accessor: (row) => row.status === LeaveEntryStatus.SEND.value ? 
        <LeaveAcceptIcon row={row} onSuccess={this.reloadData} />
        : undefined,
      width: 40
    },
  ];

  order = [{ id: "statusKey"}];

  constructor(props) {
    super(props);

    this.state = { 
      selectedEmployee: undefined,
      noEntriesToAccept: true
    };
    this.onShowDialog = this.onShowDialog.bind(this);
    this.onAcceptAll = this.onAcceptAll.bind(this);
    this.loadData = this.loadData.bind(this);
    this.reloadData = this.reloadData.bind(this);
  }

  componentWillMount() {
    this.props.doSetCurrentTableView(LeavesAcceptancePage.viewKey);
    this.props.doDataSetLoaded([], LeavesAcceptancePage.viewKey);
    this.props.doEmployeeSelected();
    this.props.doStatusSelected(LeaveEntryStatus.SEND.value);
    this.props.doYearSelected();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.selectedYear !== newProps.selectedYear || this.props.selectedEmployee !== newProps.selectedEmployee || this.props.selectedStatus !== newProps.selectedStatus) {
      this.loadData(newProps.selectedYear, newProps.selectedEmployee, newProps.selectedStatus);
    }
  }

  loadData(year, employee, status) {
    let selectedYear = year ? year : moment().year()
    let employeeParam = employee ? "&employeeId=" + employee.value.id : "";
    let statusParam = status ? "&status=" + status : "";

    axios.get(`LeaveEntries/LeavesByBoss?year=${selectedYear}${employeeParam}${statusParam}`) 
      .then((res) => {
        let anyUnaccepted = res.data.some(d => d.status === LeaveEntryStatus.SEND.value);
        this.setState({
          ...this.state,
          noEntriesToAccept: !anyUnaccepted,
        })
        this.props.doDataSetLoaded(res.data, LeavesAcceptancePage.viewKey);
      });
    
  }

  onShowDialog(row) {
    this.props.doOpenDialog(row, DialogMode.ACCEPT);
  }

  onAcceptAll() {
    toastr.confirm("Zaakceptować wszystkie wnioski?", {
      onOk: () => {
        axios
          .post(`LeaveEntries/Accept`, this.getAllUnacceptedEntriesIds())
          .then((res) => {
            toastr.success("Zaakceptowano wszystkie wnioski.");
            this.reloadData();
          });
      }
    });
  }

  reloadData() {
    this.loadData(this.props.selectedYear, this.props.selectedEmployee, this.props.selectedStatus);
  }
  
  getAllUnacceptedEntriesIds() {
    var data = [];
    this.props.dataSet.forEach(row => {
      if (row.status === LeaveEntryStatus.SEND.value)
        data.push(row.id);
    });
    return data;
  }

  render() {
    return (
      <div>
        <h1 className="small-bottom">Urlopy i przerwy</h1>
        <PageFilter>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Rok:</label></div>
            <div className="w3-col m9 value"><YearSelect from={2017}/></div>
          </div>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Zatrudniony:</label></div>
            <div className="w3-col m9 value"><EmployeeSelect leaveAcceptance/></div>
          </div>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Status:</label></div>
            <div className="w3-col m9 value"><LeavesStatusSelect /></div>
          </div>
         
        </PageFilter>   
        <Table columns={this.columns} order={this.order} onShowDialog={this.onShowDialog} />
        <div className="w3-bar w3-center">
          <button className="w3-button w3-round w3-blue" onClick={this.onAcceptAll} disabled={this.state.noEntriesToAccept}>Zaakceptuj wszystko</button>
        </div>
        <LeaveEntryDialog acceptance selectedEmployee={this.props.selectedEmployee} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedYear: state.config.selectedYear,
    selectedEmployee: state.config.selectedEmployee,
    selectedStatus: state.config.statusSelected,
    dataSet: state.table.filteredDataSet,
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doEmployeeSelected: ConfigActions.employeeSelected,
  doOpenDialog: TableActions.openDialog,
  doUpdateRow: TableActions.updateRow,
  doStatusSelected: ConfigActions.statusSelected,
  doYearSelected: ConfigActions.yearSelected,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(LeavesAcceptancePage);