import * as React from "react";

import DetailsTable from "components/common/DetailsTable";
import axios from "utils/Axios";
import { dateToMoment } from "utils/Utils";


export default class DetailsManager extends React.Component { 
  static defaultProps = {
    responsibilityId: undefined,
    fullMonth: undefined
  };

  columns = [
    {
      Header: <span className="w3-left">Zatrudniony</span>,
      id: "employee",
      accessor: "employeeName",
    },
    
    {
      Header: <span className="w3-left">Tydzień</span>,
      id: "week",
      accessor: (row) => dateToMoment(row.startDate).date() + " - " + dateToMoment(row.endDate).date(),
      width: 120
    },
    {
      Header: <span className="w3-left">Godziny</span>,
      accessor: "hours",
      width: 120
    },
    {
      Header: <span className="w3-left">Status</span>,
      accessor: "statusKey",
    }
  ];

  order = [{ id: "employee" }, {id: "week"}];

  constructor(props) {
    super(props);

    this.state = {
      dataSet: []
    }
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    axios
      .get(`Closings/${this.props.responsibilityId}?fullMonth=${this.props.fullMonth}`)
      .then((res) => {
        this.setState({
          ...this.state,
          dataSet: res.data
        })
      });
  }

  render() {
    return (
      <div className="w3-padding">
        <DetailsTable columns={this.columns} order={this.order} dataSet={this.state.dataSet} />
      </div>
    );
  }
}
