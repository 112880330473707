import * as Action from "../actions/config";

const initialState = {
  selectedYear: undefined,        // wybrany rok (int)
  selectedMonth: undefined,       // wybrany miesiąc (moment)
  selectedEmployee: undefined,    // wybrany zatrudniony 
  selectedActivity: undefined,    // wybrany projekt/ck 
};

export default (state = initialState, action) => {
  switch (action.type) {

    case Action.YEAR_SELECTED:
      return {
        ...state,
        selectedYear: action.selectedYear,
      }; 

    case Action.MONTH_SELECTED: 
      return {
        ...state,
        selectedMonth: action.selectedMonth,
      };

    case Action.EMPLOYEE_SELECTED: 
      return {
        ...state,
        selectedEmployee: action.selectedEmployee
      };
    
    case Action.ACTIVITY_SELECTED: 
      return {
        ...state,
        selectedActivity: action.selectedActivity
      };

      case Action.STATUS_SELECTED: 
      return {
        ...state,
        statusSelected: action.statusSelected
      };
      
    default:
      return state;
  }
};
