import Card from "components/common/Card";
import WeekSelect from "components/reports/WeekSelect";
import FileSaver from "file-saver";
import * as React from "react";
import { toastr } from "react-redux-toastr";
import axios from "utils/Axios";
import { formatDate } from "utils/Utils";

export default class SalesReportCard extends React.Component {
  static defaultProps = {

  };

  constructor(props) {
    super(props);

    this.state = {
      from: undefined,
      to: undefined,
      spinner: false
    };

    this.onFromChange = this.onFromChange.bind(this);
    this.onToChange = this.onToChange.bind(this);
    this.onGenerate = this.onGenerate.bind(this);
  }

  onFromChange(date) {
    this.setState({
      ...this.state,
      from: date.start
    });   
  }

  onToChange(date) {
    this.setState({
      ...this.state,
      to: date.end
    });   
  }

  onGenerate() {
    if (this.state.from.isAfter(this.state.to)) {
      toastr.warning("Wybrany przedział jest niepoprawny.");
      return;
    }

    this.setState({
      ...this.state,
      spinner: true
    });

    axios
      .post(`Reports/Sale?start=${formatDate(this.state.from)}&end=${formatDate(this.state.to)}`, null, {
        responseType: "blob",
        timeout: 30000
      })
      .then(res => { 
        var blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
        this.setState({
          ...this.state,
          spinner: false
        });
        FileSaver.saveAs(blob, `Raport sprzedażowy od ${formatDate(this.state.from)} do ${formatDate(this.state.to)}.xlsx`);
      })
      .catch(err => {
        this.setState({
          ...this.state,
          spinner: false
        })
      });
  }

  render() {
    return (
      <Card title="Sprzedaż" buttonTitle="Generuj" onClick={this.onGenerate} spinner={this.state.spinner} >
         <p>Raport dla danych z nowej wersji TTracka (od maja 2019 roku).</p>
        <div className="w3-row form-inline">
          <div className="w3-col m2 label">
            <label>Tydzień poczatkowy:</label>
          </div>
          <div className="w3-col m10 value">
            <WeekSelect onChange={this.onFromChange} />
          </div>
        </div>
        <div className="w3-row form-inline">
          <div className="w3-col m2 label">
            <label>Tydzień końcowy:</label>
          </div>
          <div className="w3-col m10 value">
            <WeekSelect onChange={this.onToChange} />
          </div>
        </div>
      </Card>
    );
  }
}
