import * as React from "react";
import { connect } from "react-redux";

import * as ConfigActions from "actions/config";
import * as TableActions from "actions/table";
import LeaveEntryDialog from "components/common/dialog/LeaveEntryDialog";
import DeleteIcon from "components/common/icon/LeaveDeleteIcon";
import LeaveInfo from "components/common/panel/LeaveInfo";
import PageFilter from "components/common/panel/PageFilter";
import EmployeeSelect from "components/common/select/EmployeeSelect";
import YearSelect from "components/common/select/YearSelect";
import StatusLabel from "components/common/StatusLabel";
import Table from "components/common/Table";
import DialogMode from "enums/DialogMode";
import axios from "utils/Axios";

class EmployeeLeavesPage extends React.Component { 
  static viewKey = 'EmployeeLeavesPage';

  columns = [
    {
      Header: <span className="w3-left">Początek</span>,
      accessor: "startDate",
      width: 120
    },
    {
      Header: <span className="w3-left">Koniec</span>,
      accessor: "endDate",
      width: 120
    },
    {
      Header: <span className="w3-left">Dni</span>,
      accessor: "days",
      width: 50
    },
    {
      Header: <span className="w3-left">Typ</span>,
      accessor: "typeKey"
    },
    {
      Header: <span className="w3-left">Status</span>,
      accessor: "statusKey",
      Cell: (props) => <StatusLabel status={props.original.status} label={props.original.statusKey} />
    },
    {
      Header: <span className="w3-left">Opis</span>,
      id: "description",
      accessor: "description"
    },
    {
      Header: "",
      id: "options",
      className: "w3-center",
      sortable: false,
      accessor: (row) => <DeleteIcon row={row} controlling />,
      width: 40
    }
  ];

  order = [{ id: "startDate" }];

  constructor(props) {
    super(props);

    this.state = {   
    };
    this.onShowDialog = this.onShowDialog.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
  }

  componentWillMount() {
    this.props.doSetCurrentTableView(EmployeeLeavesPage.viewKey);
    this.props.doDataSetLoaded([], EmployeeLeavesPage.viewKey);
    this.props.doEmployeeSelected();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.selectedYear !== newProps.selectedYear || this.props.selectedEmployee !== newProps.selectedEmployee) {
        this.loadData(newProps.selectedYear, newProps.selectedEmployee);
    }
  }

  loadData(year, employee) {
    if (!year || !employee) {
      this.props.doDataSetLoaded([], EmployeeLeavesPage.viewKey);
      return;
    }

    axios
      .get(`LeaveEntries/${employee.id}?year=${year}`)
      .then((res) => {
        this.props.doDataSetLoaded(res.data, EmployeeLeavesPage.viewKey)
      });
  }

  onShowDialog(row) {
    this.props.doOpenDialog(row, DialogMode.EDIT);
  }

  onAddClick() {
    this.props.doOpenDialog({employeeId: this.props.selectedEmployee.id}, DialogMode.ADD);
  }

  render() {
    return (
      <div>
        <h1 className="small-bottom">Wszystkie urlopy i przerwy</h1>
        <PageFilter>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Rok:</label></div>
            <div className="w3-col m9 value"><YearSelect /></div>
          </div>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Zatrudniony:</label></div>
            <div className="w3-col m9 value"><EmployeeSelect /></div>
          </div>
        </PageFilter>    
        <LeaveInfo controlling />
        <Table columns={this.columns} order={this.order} onShowDialog={this.onShowDialog} />
        <div className="w3-bar w3-center">
          <button className="w3-button w3-round w3-green" onClick={this.onAddClick} disabled={!this.props.selectedEmployee}>Dodaj wniosek</button>
        </div>
        <LeaveEntryDialog controlling selectedEmployee={this.props.selectedEmployee} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedYear: state.config.selectedYear,
    selectedEmployee: state.config.selectedEmployee ? state.config.selectedEmployee.value : undefined
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doEmployeeSelected: ConfigActions.employeeSelected,
  doOpenDialog: TableActions.openDialog,
  doUpdateRow: TableActions.updateRow,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeLeavesPage);