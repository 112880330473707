import * as React from "react";
import Select from "react-select-v1";
import moment from "moment";

export default class MonthSelect extends React.Component {
  static defaultProps = {
    onChange: (date) => {}
  }

  static FROM = 2011;

  constructor(props) {
    super(props);

    var today = moment();
    var years = this.generateYears();
    var months = this.generateMonths();

    this.state = {
      years: years,
      months: months,
      selectedYear: years[today.year() - MonthSelect.FROM],
      selectedMonth: months[today.month()],
    };

    this.onYearChange = this.onYearChange.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
  }

  componentDidMount() {
    this.props.onChange(this.getSelectedDate());
  }

  onYearChange(option) {
    this.setState({
      ...this.state,
      selectedYear: option,
      selectedMonth: undefined,
      selectedWeek: undefined
    });
  }

  onMonthChange(option) {
    this.setState(
      {
        ...this.state,
        selectedMonth: option,
        selectedWeek: undefined
      },
      () => this.props.onChange(this.getSelectedDate())
    );
  }

  generateYears() {
    var nextYear = moment().year() + 1;
    var array = [];
    for (var i = MonthSelect.FROM; i <= nextYear; i++) {
      array.push({
        label: i,
        value: i
      });
    }
    return array;
  }

  generateMonths() {
    return ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"].map((el, index) => {
      return {
        label: el,
        value: index
      };
    });
  }

  getSelectedDate() {
    return moment([this.state.selectedYear.value, this.state.selectedMonth.value, 1]);
  }

  render() {
    return (
      <div>
        <Select
          className="select-inline w100"
          value={this.state.selectedYear}
          onChange={this.onYearChange}
          options={this.state.years}
          clearable={false}
          placeholder="Wybierz"
        />
        <Select
          className="select-inline w200"
          value={this.state.selectedMonth}
          onChange={this.onMonthChange}
          options={this.state.months}
          clearable={false}
          placeholder="Wybierz"
        />
      </div>
    );
  }
}

