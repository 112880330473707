import { Enum } from "./Enum";

const ContractType = {
  NONE: Enum("NONE", "Brak"),
  FV: Enum("FV", "FV"),
  UD: Enum("UD", "UD"),
  UP: Enum("UP", "UP"),
  UZ: Enum("UZ", "UZ")
};

export const Contract = {
  ALL: Enum("", "Wszyscy"),
  ACTIVE: Enum("Active", "Aktywni")
};

export default ContractType;
