import PageInfo from "components/common/panel/PageInfo";
import ContractType from "enums/ContractType";
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import axios from "utils/Axios";


class CreativeWorkInfo extends React.Component {
  static defaultProps = {
    controlling: false
  };

  constructor(props){
    super(props);

    this.state = {
      statistics: undefined
    };
  }

  componentWillMount() {
    this.loadData(this.props);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.currentUser !== newProps.currentUser || this.props.selectedEmployee !== newProps.selectedEmployee)
      this.loadData(newProps);
  }

  loadData(newProps) {
    var user = newProps.controlling === true ? newProps.selectedEmployee : newProps.currentUser;
    if (!user)
      return;
    
    axios
      .get(`CreativeWorks/${user.id}/Statistics`)
      .then(res => {
        this.setState({
          ...this.state,
          statistics: res.data
        })
      })
      .catch(error => {
        if (error.response.status !== 404)
          console.log(error);
      });
  }

  render() {
    if (!this.state.statistics)
      return null;

    var user = (this.props.controlling === true ? this.props.selectedEmployee : this.props.currentUser);
    if (!user || user.contractType !== ContractType.UP.value)
      return null;

    var stat = this.state.statistics;
    
    return (
      <PageInfo>
        <p>Praca twórcza w miesiącu <b>{moment().format("MMMM")}</b> prawdopodobnie nie przekroczyła {stat.creativeWorkPercent.toFixed(2)} %.</p>   
      </PageInfo> 
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataSet: state.table.originalDataSet,
    currentUser: state.session.currentUser,
    selectedEmployee: state.config.selectedEmployee ? state.config.selectedEmployee.value : undefined,
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CreativeWorkInfo);