import * as React from "react";
import { connect } from "react-redux";
import Select from "react-select-v1";
import axios from "utils/Axios";

import * as ConfigActions from "actions/config";
import * as SessionActions from "actions/session";

class EmployeeSelect extends React.Component {
  static defaultProps = {
    creativeWork: false,
    leaveAcceptance: false
  };

  constructor(props) {
    super(props);

    this.state = {
      options: []
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  getPath() {
    var path = `Employees/Active`
    if (this.props.creativeWork) {
      path = `Employees/WithCreativeWork`
    }
    if (this.props.leaveAcceptance) {
      var bossId = this.props.currentUser.id;
      path = `Employees/WithBoss?bossId=${bossId}`
    }
    return path;
  }

  loadData() {
    var path = this.getPath()
    axios.get(path)
      .then((res) => {
        var options = res.data.map((el) => {
          return {
            label: el.lastName + " " + el.firstName,
            value: el
          };
        });

        this.setState({
          ...this.state,
          options: options
        });
    });
  }

  onChange(option) {
    this.props.doEmployeeSelected(option);
  }

  render() {
    if (!this.state.options)
      return;

    return <Select
          value={this.props.selectedEmployee}
          searchable={true}
          clearable={this.props.leaveAcceptance}
          options={this.state.options}
          onChange={this.onChange}
          className="select-inline w308"
          placeholder="Wybierz"
        />;
  }
}

const mapStateToProps = (state) => {
  return {
    selectedEmployee: state.config.selectedEmployee,
    currentUser: state.session.currentUser,
  };
};

const mapDispatchToProps = {
  doEmployeeSelected: ConfigActions.employeeSelected,
  doCurrentUserLoaded: SessionActions.currentUserLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeSelect);