import * as React from "react";

import SalesReportCard from "components/reports/sales/SalesReportCard";
import SalesOldReportCard from "components/reports/sales/SalesOldReportCard";
import EngagementReportCard from "components/reports/sales/EngagementReportCard";
import EngagementDayLaborReportCard from "components/reports/sales/EngagementDayLaborReportCard";

export default class SalesReportPage extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <h1>Raporty sprzedaży i zaangażowania</h1>
        <EngagementReportCard />
        <EngagementDayLaborReportCard />
        <SalesReportCard />
        <SalesOldReportCard />
      </div>
    );
  }
}