import * as React from "react";
import { connect } from "react-redux";

import * as ConfigActions from "actions/config";
import * as TableActions from "actions/table";
import WeekEntryDialog from "components/acceptance/WeekEntryDialog";
import AddProjectDialog from "components/basic/myTimeEntries/AddProjectDialog";
import EntryCell from "components/basic/myTimeEntries/EntryCell";
import FooterStats from "components/basic/myTimeEntries/FooterStats";
import DeleteFavouriteIcon from "components/common/icon/DeleteFavouriteIcon";
import ClosedInfo from "components/common/panel/ClosedInfo";
import PageFilter from "components/common/panel/PageFilter";
import EmployeeSelect from "components/common/select/EmployeeSelect";
import EntryStatus from "enums/EntryStatus";
import MonthSelect from "components/common/select/MonthSelect";
import Table from "components/common/Table";
import DialogMode from "enums/DialogMode";
import axios from "utils/Axios";
import { dateToMoment, formatDate, formatPeriod } from "utils/Utils";
import { toastr } from "react-redux-toastr";
import MyTimeEntryState from "enums/MyTimeEntryState";
import PageInfo from "components/common/panel/PageInfo";


class EmployeeTimeEntriesPage extends React.Component {
  static viewKey = 'EmployeeTimeEntriesPage';

  columns = [
    {
      Header: <span className="w3-left">BU</span>,
      accessor: "activity.businessUnit",
      width: 50
    },
    {
      Header: <span className="w3-left">Centrum kosztów</span>,
      id: "cc",
      accessor: "activity.costCenter.name"
    },
    {
      Header: <span className="w3-left">Projekt</span>,
      id: "project",
      accessor: (row) => {
        return row.activity.projectId != null ? row.activity.projectTypeKey + "." + row.activity.projectName : "";
      }
    },
    {
      Header: <span className="w3-left">Klient</span>,
      id: "client",
      accessor: (row) => {
        return (row.client ? row.client.name : row.activity.clientName);
      } 
    },
    {
      Header: <span className="w3-left">Akceptujący</span>,
      accessor: "activity.managerName"
    },

    // [tygodnie]
    {
      Header: <span className="w3-center">Suma</span>,
      id: "sum",
      className: "w3-center yellow",
      
      sortable: false,
      resizable: false,
      width: 80,
      accessor: (row) => {
        var sum = 0;
        for (var weKey in row.weekEntries) {
          var te = row.weekEntries[weKey];
          sum += te.hours;
        }
        return sum > 0 ? <b>{sum}</b> : null;
      },
      
      Footer: () => {
        var declared = 0, required = 0;
        this.state.weeks.forEach(w => required += w.requiredHours);
        this.state.weeks.forEach(w => declared += w.declaredHours);
        return (
        <div>
          <p className="w3-medium" style={{ margin: "8px -5px 15px 0px", fontWeight: "normal" }}>{declared} / {required}
          </p>
          <div className="w3-bar">
            {this.getConfirmedButton()}

          </div>
          </div>);
      }
      
    },
    
    {
      Header: "",
      id: "options",
      className: "w3-center",
      sortable: false,
      resizable: false,
      width: 40,
      accessor: row => <DeleteFavouriteIcon row={row} controlling />
    }
  ]

  order = [{ id: "cc" }, { id: "project" }];

  constructor(props) {
    super(props);

    this.state = {
      columns: this.columns,
      weeks: [],
      addProjectDialogMode: DialogMode.HIDDEN,
      monthConfirmed: false
    };

    this.onHoursUpdated = this.onHoursUpdated.bind(this);
    this.onEntryInfoClick = this.onEntryInfoClick.bind(this);
    this.addProjectClick = this.addProjectClick.bind(this);
    this.onAddProjectClose = this.onAddProjectClose.bind(this);
    this.isMonthClosed = this.isMonthClosed.bind(this);
    this.onMonthConfirmation = this.onMonthConfirmation.bind(this);

  }

  getConfirmedButton()  {
    if (this.props.selectedEmployee)  {
      var result = this.hasHoursReady();

      var title = '';
      var action = () => {};
      var icon = ''
      var color = null;
      switch (result) {
        case MyTimeEntryState.READY:
          color = 'green'
          title = "Wycofaj wprowadzenie wszystkich godzin" // Wycofaj zatrudnionemu wszystkie przepracowane godziny
          action = this.onMonthConfirmation;
          icon = "fa fa-user-times"
          break;
        case MyTimeEntryState.DISABLED: 
          color = 'grey'
          title = "Nie możesz potwierdzić wprowadzonych godzin"
          icon = "fas fa-user-times";
          break;
        case MyTimeEntryState.PENDING:
          color = 'amber'
          title = "Potwierdź wprowadzenie wszystkich godzin"
          action = this.onMonthConfirmation;
          icon = "fas fa-user-clock";
          break;
      }

      return (
        <button className={"w3-button w3-round w3-" + color + " image"} onClick={action} title={title}>
          <i className={icon} />
        </button>
      );

    }
    else {
      return "";
    }
  }

  componentWillMount() {
    this.props.doSetCurrentTableView(EmployeeTimeEntriesPage.viewKey);
    this.props.doDataSetLoaded([], EmployeeTimeEntriesPage.viewKey);
    this.props.doEmployeeSelected();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.selectedMonth !== newProps.selectedMonth || this.props.selectedEmployee !== newProps.selectedEmployee) {
      this.loadData(newProps.selectedMonth, newProps.selectedEmployee);
    }
  }

  isDayLabor() {
    return this.props.selectedEmployee && this.props.selectedEmployee.employmentModel === 'DAY_LABOR';
  }

  hasEntriesToSubmit() {
    for (var row in this.props.dataSet) {
      var entries = this.props.dataSet[row].weekEntries;
      if (entries) {    
        for (var date in entries) {
          var entry = entries[date];
          if (entry && entry.hours > 0 && (entry.status === EntryStatus.ENTERED.value || entry.status === EntryStatus.REJECTED.value))
            return true;
        }
      }
    };

    return false;
  }

  loadData(month, employee) {
    if (!month || !employee) {
      this.props.doDataSetLoaded([], EmployeeTimeEntriesPage.viewKey);
      return;
    }
    
    axios
      .get(`TimeEntries/${employee.id}/Weeks?monthDate=${formatDate(month)}`)
      .then((res) => {
        var weeks = res.data;
        axios
          .get(`TimeEntries/${employee.id}?monthDate=${formatDate(month)}`)
          .then((res) => {
            this.props.doDataSetLoaded(res.data, EmployeeTimeEntriesPage.viewKey);
            this.createWeekColumns(weeks);
          });
      });

      axios
      .get(`Closings/CheckMonthConfirmationStatus?month=${formatDate(month)}&employeeId=${employee.id}`)
      .then((res) => {
        this.setState({
          ...this.state,
          monthConfirmed: res.data
        });
      });

  }

  hasHoursReady() {

    var declared = 0, required = 0;
    this.state.weeks.forEach(w => required += w.requiredHours);
    this.state.weeks.forEach(w => declared += w.declaredHours);  

    if (this.isDayLabor()){
      if (this.isMonthClosed()) // jeśli miesiąc zamknięty: szary przycisk
      return MyTimeEntryState.DISABLED;
      
      if (this.hasEntriesToSubmit()) // jeśli na akord i nie wysłał godzin: szary przycisk
        return MyTimeEntryState.DISABLED;
      
      else if (this.state.monthConfirmed) // jeśli na akord i zatwierdził miesiąc: zielony przycisk
        return MyTimeEntryState.READY;
      
      return MyTimeEntryState.PENDING;
    }
  }

  createWeekColumns(weeks) {
    var columns = this.state.columns.slice(0, 5);
    weeks.forEach((week, idx) => {
      var period = dateToMoment(week.startDate).date() + " - " + dateToMoment(week.endDate).date();
      columns.push({
        Header: <span title={"Tydzień " + formatPeriod(week.startDate, week.endDate)}>{period}</span>,
        Footer: () => <FooterStats week={week} controlling />,
        width: 100,
        resizable: false,
        sortable: false,
        className: "w3-center entry",
        id: week.startDate,
        accessor: (row) => <EntryCell row={row} week={week} onInfoClick={this.onEntryInfoClick} controlling />
      });
    });

    columns.push(this.state.columns[this.state.columns.length-2]);
    columns.push(this.state.columns[this.state.columns.length-1]);

    this.setState({
      ...this.state,
      columns: columns,
      weeks: weeks
    });
  }

  onHoursUpdated(row, oldEntry, newEntry) {
    row.weekEntries[oldEntry.startDate] = newEntry;
    this.updateStatistics(oldEntry.startDate, newEntry.hours - oldEntry.hours);
    this.props.doUpdateRow(row, row);
  }

  onEntryInfoClick(row, entry) {
    var enabled = !this.isMonthClosed();
    this.props.doOpenDialog(row, enabled ? DialogMode.EDIT : DialogMode.PREVIEW, { entry: entry });
  }

  updateStatistics(date, diff) {
    var newWeeks = this.state.weeks.slice();
    newWeeks.forEach((week) => {
      if (week.startDate === date) {
        week.declaredHours += diff;
      }
    });
    this.setState({
      ...this.state,
      weeks: newWeeks
    });
  }

  addProjectClick() {
    this.setState({
      ...this.state,
      addProjectDialogMode: DialogMode.ADD
    });
  }

  onAddProjectClose() {
    this.setState({
      ...this.state,
      addProjectDialogMode: DialogMode.HIDDEN
    });
  }
  
  onMonthConfirmation() {
    var isConfirming = !this.state.monthConfirmed;
    toastr.confirm(isConfirming ? "Wysłać potwierdzenie wszystkich godzin?": "Wycofać potwierdzenie wszystkich godzin?", {
      onOk: () => {
        axios
          .post(`Closings/ChangeMonthConfirmationStatus?month=${formatDate(this.props.selectedMonth)}&employeeId=${this.props.selectedEmployee.id}&confirmed=${isConfirming}`)
          .then((res) => {
            toastr.success(isConfirming ? "Zatwierdzono" : "Wycofano");
            this.loadData(this.props.selectedMonth, this.props.selectedEmployee);
          });
      }
    });
  }

  isMonthClosed() {
    return this.props.selectedMonth && this.props.selectedMonth.isBefore(dateToMoment(this.props.currentClosing.date));
  }

  render() {
    let myTimeEntryState = this.hasHoursReady();

    return (
      <div>
        <h1 className="small-bottom">Wpisy zatrudnionych</h1>
        <PageFilter>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Miesiąc:</label></div>
            <div className="w3-col m9 value"><MonthSelect /></div>
          </div>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Zatrudniony:</label></div>
            <div className="w3-col m9 value"><EmployeeSelect /></div>
          </div>
         
        </PageFilter>  
        {
          myTimeEntryState === MyTimeEntryState.PENDING ? 
          <PageInfo warning>
            <p>Nie potwierdzono wprowadzenia wszystkich godzin. Aby potwierdzić kliknij: &nbsp; <i className="fas fa-user-clock" /></p>   
          </PageInfo> : ""
        }
        {
          myTimeEntryState === MyTimeEntryState.READY ? 
          <PageInfo info>
            <p>Potwierdzono wprowadzenie wszystkich godzin. Aby anulować kliknij: &nbsp; <i className="fas fa-user-times" /></p>   
          </PageInfo> : ""
        }

        <ClosedInfo />
        <Table columns={this.state.columns} order={this.order} defaultPageSize={20} />
       
        <div className="w3-bar w3-center">
          <button className="w3-button w3-round w3-green" onClick={this.addProjectClick} disabled={this.isMonthClosed() || !this.props.selectedEmployee} >Dodaj projekt</button>
        </div>
        <AddProjectDialog mode={this.state.addProjectDialogMode} onClose={this.onAddProjectClose} controlling />
        <WeekEntryDialog onHoursUpdated={this.onHoursUpdated} controlling />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentClosing: state.session.currentClosing,
    selectedMonth: state.config.selectedMonth,
    selectedEmployee: state.config.selectedEmployee ? state.config.selectedEmployee.value : undefined
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doEmployeeSelected: ConfigActions.employeeSelected,
  doOpenDialog: TableActions.openDialog,
  doUpdateRow: TableActions.updateRow,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTimeEntriesPage);
