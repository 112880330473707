import * as React from "react";

import { Link } from "react-router-dom";
import Path from "utils/Path";
import { connect } from "react-redux";
import { getResource } from "utils/RedactedResources";
import axios from "utils/Axios";

export class NavBar extends React.Component {
  scrollListener = null;

  constructor(props) {
    super(props);

    this.state = {
      mobileVisible: false,
      top: true
    };

    this.scrollListener = this.handleScroll.bind(this);
    this.toggleNavMobile = this.toggleNavMobile.bind(this);
  }

  componentDidMount() {
    this.loadData();
    window.addEventListener("scroll", this.scrollListener);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollListener);
  }

  handleScroll() {
    if (this.state.top && window.scrollY > 0) {
      this.setState({
        ...this.state,
        top: false
      });
    }
    else if (!this.state.top && window.scrollY <= 0) {
      this.setState({
        ...this.state,
        top: true
      });
    }
  }

  toggleNavMobile() {
    this.setState({
      ...this.state,
      mobileVisible: !this.state.mobileVisible
    });
  }

  loadData() {
    axios
      .get('/Status/LoadVersion')
      .then((res) => {
        this.setState({
          ...this.state,
          envType: res.data.envType
        });
      });
  }

  renderDesktop() {
    if (!this.props.currentUser)
      return null;
    
    let user = this.props.currentUser;
    let isTestEnv = this.state.envType === "TEST";
    let titleStyle = isTestEnv ? {color: "orange"} : {};
    let textInfo = isTestEnv ? "WERSJA TESTOWA" : "";

    return (
      <div className={"w3-top navbar"+ (this.state.top === true ? " sticky" : "")}>
        <div className="w3-bar navbar-menu w3-padding w3-card" >
          <Link to={Path.main}>
            <img src="/img/BMS_logo.jpg" className="w3-bar-item bms-logo" alt="BMS" />
          </Link>
          <div className="w3-bar-item ttrack-title" style={titleStyle} >TTrack {textInfo}</div>
          <button className="w3-bar-item w3-button w3-hide-large w3-right" onClick={this.toggleNavMobile} title="Menu">
            <i className="fa fa-bars w3-large" />
          </button>
          <div className="w3-right w3-hide-medium w3-hide-small">
            <div className="w3-dropdown-hover">
              <button className="w3-button w3-hover-theme-d3">Podstawowe <i className="fa fa-caret-down" /></button>
              <div className="w3-dropdown-content w3-bar-block w3-card-4">
                {user.roleTimeEntry === true && <Link to={Path.basic + Path.timeEntries} className="w3-bar-item w3-button">{getResource(this.props.currentUser.contractType, "worklog")}</Link>}
                {(user.roleCreativeWork === true) && <Link to={Path.basic + Path.creativeWorks} className="w3-bar-item w3-button">Moje utwory</Link>}
                <Link to={Path.basic + Path.leaves} className="w3-bar-item w3-button">{getResource(this.props.currentUser.contractType, "myLeaves")}</Link>
                {(user.roleAdmin === true) && <Link to={Path.basic + Path.statistics} className="w3-bar-item w3-button">Statystyki</Link>}
              </div>
            </div>
            {(user.roleManager === true || user.roleControlling === true) &&
              <div className="w3-dropdown-hover">
                <button className="w3-button w3-hover-theme-d3">Akceptacja <i className="fa fa-caret-down" /></button>
                <div className="w3-dropdown-content w3-bar-block w3-card-4">
                  {user.roleManager === true && <Link to={Path.acceptance + Path.byEmployee} className="w3-bar-item w3-button">Po zatrudnionych</Link>}
                  {user.roleManager === true && <Link to={Path.acceptance + Path.byActivity} className="w3-bar-item w3-button">Po projektach</Link>}
                  {user.roleManager === true && <Link to={Path.acceptance + Path.creativeWorks} className="w3-bar-item w3-button">Utwory</Link>}
                  {user.roleManager === true && <Link to={Path.acceptance + Path.leaves} className="w3-bar-item w3-button">Urlopy i przerwy</Link>}
                </div>
              </div>
            }
            {user.roleControlling === true &&
              <div className="w3-dropdown-hover">
                <button className="w3-button w3-hover-theme-d3">Kontroling <i className="fa fa-caret-down" /></button>
                <div className="w3-dropdown-content w3-bar-block w3-card-4">
                  <Link to={Path.controlling + Path.timeEntries} className="w3-bar-item w3-button">Wpisy zatrudnionych</Link>
                  <Link to={Path.controlling + Path.leaves} className="w3-bar-item w3-button">Urlopy i przerwy</Link>
                  {(user.roleControlling) && <Link to={Path.controlling + Path.sicks} className="w3-bar-item w3-button">Pozostałe nieobecności</Link>}
                  <Link to={Path.controlling + Path.creativeWorks} className="w3-bar-item w3-button">Utwory</Link>
                  <Link to={Path.controlling + Path.managerStats} className="w3-bar-item w3-button">Statystyki akceptujących</Link>
                  <Link to={Path.controlling + Path.closing} className="w3-bar-item w3-button">Zamykanie miesiąca</Link>
                  <Link to={Path.controlling + Path.emails} className="w3-bar-item w3-button">Ustawienia e-maili</Link>
                </div>
              </div>
            }
            {user.roleControlling === true &&
              <div className="w3-dropdown-hover">
                <button className="w3-button w3-hover-theme-d3"> Słowniki <i className="fa fa-caret-down" /></button>
                <div className="w3-dropdown-content w3-bar-block w3-card-4">
                  <Link to={Path.dictionaries + Path.employees} className="w3-bar-item w3-button">Zatrudnieni</Link>
                  <Link to={Path.dictionaries + Path.projects} className="w3-bar-item w3-button">Projekty i centra kosztów</Link>
                  <Link to={Path.dictionaries + Path.holidays} className="w3-bar-item w3-button">Święta</Link>
                </div>
              </div>
            }
            {(user.roleManager === true || user.roleControlling === true || user.roleReports === true) &&
              <div className="w3-dropdown-hover">
                <button className="w3-button w3-hover-theme-d3">Raporty <i className="fa fa-caret-down" /></button>
                <div className="w3-dropdown-content w3-bar-block w3-card-4">
                  <Link to={Path.reports + Path.sales} className="w3-bar-item w3-button">Sprzedaż i zaangażowanie</Link>
                  <Link to={Path.reports + Path.leaves} className="w3-bar-item w3-button">Urlopy i przerwy</Link>
                </div>
              </div>
            }
            <div className="w3-dropdown-hover">
              <button className="w3-button w3-hover-theme-d3">Moje konto <i className="fa fa-caret-down" /></button>
              <div className="w3-dropdown-content w3-bar-block w3-card-4">
                <Link to={Path.myAccount + Path.settings} className="w3-bar-item w3-button">Ustawienia</Link>
                <Link to={Path.myAccount + Path.logOut} className="w3-bar-item w3-button">Wyloguj</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="w3-bar w3-container w3-card navbar-details">
          <p><b>{user.firstName} {user.lastName} [{user.businessUnit}]</b>, akceptujący: {user.bossName}</p>
        </div>
      </div>
    );
  }

  renderMobile() {
    if (!this.props.currentUser)
      return null;
    
    var user = this.props.currentUser;

    return (
      <div className={"w3-bar-block w3-black w3-hide w3-hide-large w3-top" + (this.state.mobileVisible ? " w3-show" : "")} style={{ marginTop: "46px" }}>
      <div className="w3-dropdown-hover">
        <button className="w3-button">Podstawowe <i className="fa fa-caret-down" /></button>
        <div className="w3-dropdown-content w3-bar-block w3-card-4">
          {user.roleTimeEntry === true && <Link to={Path.basic + Path.timeEntries} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Czas pracy</Link>}
          {(user.roleCreativeWork === true) && <Link to={Path.basic + Path.creativeWorks} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Moje utwory</Link>}
          <Link to={Path.basic + Path.leaves} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Moje urlopy</Link>
          {(user.roleAdmin === true) && <Link to={Path.basic + Path.statistics} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Statystyki</Link>}
        </div>
      </div>
      {(user.roleManager === true || user.roleControlling === true) &&
        <div className="w3-dropdown-hover">
          <button className="w3-button">Akceptacja <i className="fa fa-caret-down" /></button>
          <div className="w3-dropdown-content w3-bar-block w3-card-4">
            {user.roleManager === true && <Link to={Path.acceptance + Path.byEmployee} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Po zatrudnionych</Link>}
            {user.roleManager === true && <Link to={Path.acceptance + Path.byActivity} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Po projektach</Link>}
            {user.roleManager === true && <Link to={Path.acceptance + Path.creativeWorks} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Utwory</Link>}
            {user.roleManager === true && <Link to={Path.acceptance + Path.leaves} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Urlopy</Link>}
            {user.roleControlling === true && <Link to={Path.acceptance + Path.sicks} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Choroby i inne</Link>}
          </div>
        </div>
      }
      {user.roleControlling === true &&
        <div className="w3-dropdown-hover">
          <button className="w3-button">Kontroling <i className="fa fa-caret-down" /></button>
          <div className="w3-dropdown-content w3-bar-block w3-card-4">
            <Link to={Path.controlling + Path.timeEntries} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Wpisy zatrudnionych</Link>
            <Link to={Path.controlling + Path.leaves} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Urlopy zatrudnionych</Link>
            <Link to={Path.controlling + Path.creativeWorks} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Utwory</Link>
            <Link to={Path.controlling + Path.managerStats} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Statystyki akceptyjących</Link>
            <Link to={Path.controlling + Path.closing} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Zamykanie miesiąca</Link>
            <Link to={Path.controlling + Path.emails} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Ustawienia e-maili</Link>
          </div>
        </div>
      }
      {user.roleControlling === true &&
        <div className="w3-dropdown-hover">
          <button className="w3-button"> Słowniki <i className="fa fa-caret-down" /></button>
          <div className="w3-dropdown-content w3-bar-block w3-card-4">
            <Link to={Path.dictionaries + Path.employees} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Zatrudnieni</Link>
            <Link to={Path.dictionaries + Path.projects} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Projekty i centra kosztów</Link>
            <Link to={Path.dictionaries + Path.holidays} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Święta</Link>
          </div>
        </div>
      }
      {(user.roleManager === true || user.roleControlling === true) &&
        <div className="w3-dropdown-hover">
          <button className="w3-button">Raporty <i className="fa fa-caret-down" /></button>
          <div className="w3-dropdown-content w3-bar-block w3-card-4">
            <Link to={Path.reports + Path.sales} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Sprzedaż i zaangażowanie</Link>
            <Link to={Path.reports + Path.leaves} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Urlopy</Link>
          </div>
        </div>
      }
      <div className="w3-dropdown-hover">
        <button className="w3-button">Moje konto <i className="fa fa-caret-down" /></button>
          <div className="w3-dropdown-content w3-bar-block w3-card-4">
            <Link to={Path.myAccount + Path.settings} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Ustawienia</Link>
            <Link to={Path.myAccount + Path.logOut} className="w3-bar-item w3-button" onClick={this.toggleNavMobile} >Wyloguj</Link>
        </div>
      </div>
    </div>
    );
  }

  render() {
    return (
      <div>
        { this.renderDesktop() }
        { this.renderMobile() }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.currentUser
  };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
