import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";

import * as TableActions from "actions/table";

import axios from "utils/Axios";
import { calcEmployeeUrl } from "utils/Utils";

class DeleteFavouriteIcon extends React.Component {
  static defaultProps = {
    row: undefined,
    controlling: false
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (!this.props.row.favouriteActivityId)
      return;

    var msg = (this.props.row.activity.projectId
      ? "Usunąć projekt z listy ulubionych? Wprowadzone wpisy nie zostaną usunięte."
      : "Usunąć centrum kosztów z listy ulubionych? Wprowadzone wpisy nie zostaną usunięte."
    ); 
    
    var employeeUrl = calcEmployeeUrl(this.props.row, this.props.controlling);

    toastr.confirm(msg, {
      onOk: () => {
        var path = this.props.row.activity.projectId
          ? `Employees/${employeeUrl}/FavouriteProjects/${this.props.row.favouriteActivityId}`
          : `Employees/${employeeUrl}/FavouriteResponsibilities/${this.props.row.favouriteActivityId}`
  
        axios
          .delete(path)
          .then((res) => {
            var msg = (this.props.row.activity.projectId
              ? "Projekt został usunięty."
              : "Centrum kosztów zostało usunięte."
            ); 

            toastr.success(msg);

            if (this.isRowEmpty()) {
              this.props.doUpdateRow(this.props.row, null);
            }
            else {
              var newRow = { ...this.props.row };
              newRow.favouriteActivityId = null;
              this.props.doUpdateRow(this.props.row, newRow);
            }
          });
      }
    });
  }

  isRowEmpty() {
    for (var key in this.props.row.weekEntries) {
      var entry = this.props.row.weekEntries[key];
      if (entry.id)
        return false;
    }
    return true;
  }

  render() {   
    if (!this.props.row.favouriteActivityId)
      return null;

    var title = (this.props.row.activity.projectId ? "Usuń projekt z listy ulubionych" : "Usuń centrum kosztów z listy ulubionych"); 
    
    return (
      <i className="far fa-trash-alt pointer" title={title} onClick={this.onClick} />
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {
  doUpdateRow: TableActions.updateRow
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteFavouriteIcon);
