import * as React from "react";
import { connect } from "react-redux";
import Select from "react-select-v1";

import * as ConfigActions from "actions/config";
import { enum2ComboBox } from "enums/Enum";
import { LeaveEntryStatus } from "enums/EntryStatus";

class LeavesStatusSelect extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);


    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.props.doStatusSelected(this.props.statusSelected);
  }

  onChange(option) {
    let value = option ? option.value : null;
    this.props.doStatusSelected(value);
  }

  render() {
    return <Select
          className="select-inline w308"
          placeholder="Wybierz"
          value={this.props.statusSelected}
          options={enum2ComboBox(LeaveEntryStatus)}
          clearable={true}
          onChange={this.onChange}
        />;
  }
}

const mapStateToProps = (state) => {
  return {
    statusSelected: state.config.statusSelected
  };
};

const mapDispatchToProps = {
  doStatusSelected: ConfigActions.statusSelected
};

export default connect(mapStateToProps, mapDispatchToProps)(LeavesStatusSelect);