import * as React from "react";

import Card from "./Card";
import axios from "utils/Axios";
import { toastr } from "react-redux-toastr";

export default class EmailTemplateCard extends React.Component {
  static defaultProps = {
    type: "",
    label: "",
    time: ""
  }

  constructor(props) {
    super(props);

    this.state = {
      emailTemplate: null,
      spinner: false
    };

    this.onSaveClick = this.onSaveClick.bind(this);
    this.onTestClick = this.onTestClick.bind(this);
  }

  componentWillMount() {
    this.loadData();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.type !== newProps.type) {
      this.loadData(newProps.type);
    }
  }

  loadData(type = this.props.type) {
    if (!type)
      return;
    
    axios
      .get(`Emails/${type}`)
      .then(res => { 
        this.setState({
          ...this.state,
          emailTemplate: res.data
        });
      });
  }

  onChange(name, event) {   
    var value = event.target.value;
    this.setState((prevState) => {
       var state = { ...prevState };
       state.emailTemplate[name] = value;
       return state;
    });
  }

  onSaveClick() {
    if (!this.state.emailTemplate.subject) {
      toastr.warning("Wpisz temat maila!");
      return;
    }

    if (!this.state.emailTemplate.body) {
      toastr.warning("Wpisz treść maila!");
      return;
    }

    this.setState({
      ...this.state,
      spinner: true
    });

    axios
      .put(`Emails/${this.state.emailTemplate.id}`, this.state.emailTemplate)
      .then(res => { 
        toastr.success("Szablon został zapisany.");
        this.setState({
          emailTemplate: res.data,
          spinner: false
        });
      });
  }

  onTestClick() {
    this.setState({
      ...this.state,
      spinner: true
    });
    axios
      .post(`Emails/Test`, this.state.emailTemplate)
      .then(res => { 
        toastr.success("Przykładowy mail został wysłany na Twoją skrzynkę pocztową.");
        this.setState({
          ...this.state,
          spinner: false
        });
      });
  }

  render() {
    if (!this.state.emailTemplate)
      return null;

    return (
      <Card title={this.props.label} time={this.props.time} onSaveClick={this.onSaveClick} onTestClick={this.onTestClick} spinner={this.state.spinner}>
        <div className="w3-row form-inline">
          <div className="w3-col m2 label">
            <label>Temat:</label>
          </div>
          <div className="w3-col m10 value">
            <input type="text" className="w3-input w3-border w3-round" value={this.state.emailTemplate.subject} onChange={this.onChange.bind(this, "subject")} />
          </div>
        </div>
        <div className="w3-row form-inline">
          <div className="w3-col m2 label">
            <label>Treść:</label>
          </div>
          <div className="w3-col m10 value">
            <textarea className="w3-input w3-border w3-round h200" onChange={this.onChange.bind(this, "body")} value={this.state.emailTemplate.body}></textarea>
          </div>
        </div>
      </Card>
    );
  }
}
