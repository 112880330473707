import * as React from "react";
import * as SessionActions from "actions/session";
import * as TableActions from "actions/table";

import { dateToMoment, formatDate } from "utils/Utils";

import { Checkbox } from "react-icheck";
import ClosingDetails from "components/controlling/closing/Details";
import ClosingInfo from "components/common/panel/ClosingInfo";
import DialogMode from "enums/DialogMode";
import FillIcon from "components/controlling/closing/FillIcon";
import PrintIcon from "components/controlling/closing/PrintIcon";
import PageFilter from "components/common/panel/PageFilter";
import SendMailDialog from "./SendMailDialog";
import StatsPeriodSelect from "components/controlling/StatsPeriodSelect";
import Table from "components/common/Table";
import YesNoIcon from "components/common/icon/YesNoIcon";
import DayLaborIcon from "components/common/icon/DayLaborIcon";

import axios from "utils/Axios";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import FileSaver from "file-saver";
import ClosingActionType from "enums/ClosingActionType";
import ReadyToCloseState from "enums/ReadyToCloseState";

class ClosingPage extends React.Component { 
  static viewKey = 'ClosingPage';
  
  static defaultProps = {
    fullMonth: true
  };

  columns = [
    {
      Header: <span className="w3-left">Zatrudniony</span>,
      id: "employee",
      accessor: "employeeName",
    },
    {
      Header: "TTrack",
      id: "ttrack",
      className: "w3-center",
      accessor: "roleTimeEntry",
      Cell: (props) => <YesNoIcon value={props.value} />,
      width: 70
    },
    {
      Header: <span className="w3-left">BU</span>,
      accessor: "businessUnitName",
      width: 80
    },
    {
      Header: <span className="w3-left">Nie bms</span>,
      accessor: "notBmsHours",
      width: 80
    },
    {
      Header: <span className="w3-left">Ferie friko</span>,
      accessor: "unpaidLeave",
      width: 80
    },
    {
      Header: <span className="w3-left">Wprowadzone</span>,
      accessor: "enteredHours",
      width: 110
    },
    {
      Header: <span className="w3-left">Wysłane</span>,
      accessor: "sendHours",
      width: 80
    },
    {
      Header: <span className="w3-left">Zaakceptowane</span>,
      accessor: "acceptedHours",
      width: 120
    },
    {
      Header: <span className="w3-left">Odrzucone</span>,
      accessor: "rejectedHours",
      width: 90
    },
    {
      Header: <span className="w3-left">Razem</span>,
      id: "sum",
      accessor: "sumHours",
      width: 80
    },
    {
      Header: <span className="w3-left">Urlopy i przerwy</span>,
      accessor: "sendLeaveHours",
      width: 120
    },
    {
      Header: <span className="w3-left">Utwory</span>,
      accessor: "creativeWorks",
      Cell: (props) => {
        return (props.original.creativeWorks !== undefined ? props.original.creativeWorks : "-");
      },
      width: 60
    },
       {
      Header: <span className="w3-left">Gotowy</span>,
      id: "monthConfirmed",
      accessor: (row) => {
        if (row.roleTimeEntry && row.employmentModel === "DAY_LABOR" && !row.monthConfirmed){
            return ReadyToCloseState.DAY_LABOR_UNCONFIRMED;
        }
        return row.readyToClose;
      },
      Cell: (props) => <DayLaborIcon value={props.value}/>,
      width: 60
    },
    {
      Header: "",
      id: "options",
      className: "w3-center",
      resizable: false,
      accessor: (row) => {
        if (this.state.fullMonth) {
          if (row.readyToClose && row.employmentModel === "DAY_LABOR")
            return { 
              type: ClosingActionType.PRINT,
              row: row
            }
          else if (row.canFill) 
            return {
              type: ClosingActionType.FILL,
              row: row
            }
        }
        return {
          type: ''
        }
      },
      sortMethod: (firstObj, secObj) => {
        return firstObj.type.localeCompare(secObj.type);
      },
      Cell: (row) => {
        if (row.value.type === ClosingActionType.FILL)
          return <FillIcon row={row.value.row} onSuccess={this.onFillSuccess}/>
        if (row.value.type === ClosingActionType.PRINT)
          return <PrintIcon row={row.value.row} onSuccess={this.onFillSuccess}/>
      },
      width: 30
    },

    {
      id: "check",
      width: 30,
      className: "flex",
      accessor: row => this.renderCheck(row)       
    },
  ];

  order = [{ id: "employee" }];

  constructor(props) {
    super(props);

    this.state = {
      fullMonth: props.fullMonth,
      dialogMode: DialogMode.HIDDEN,
      printDialogMode: DialogMode.HIDDEN,
      spinner: false,
      employeeId: null
    }

    this.onPeriodChange = this.onPeriodChange.bind(this);
    this.onFillSuccess = this.onFillSuccess.bind(this);
    this.onCheckedChange = this.onCheckedChange.bind(this);
    this.getSubComponent = this.getSubComponent.bind(this);
    this.onCloseMonth = this.onCloseMonth.bind(this);
    this.onSendClick = this.onSendClick.bind(this);

    this.onDialogClose = this.onDialogClose.bind(this);
    this.onPrintDialogClose = this.onPrintDialogClose.bind(this);
    this.onGenerate = this.onGenerate.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);

  }

  componentWillMount() {
    this.props.doSetCurrentTableView(ClosingPage.viewKey);
    this.props.doDataSetLoaded([], ClosingPage.viewKey);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData(fullMonth = this.state.fullMonth) {
    axios
      .get(`Closings/Employees?fullMonth=${fullMonth}`)
      .then((res) => {
        this.props.doDataSetLoaded(res.data, ClosingPage.viewKey)
      });
  }

  onPeriodChange(fullMonth) {
    this.setState({
      ...this.state,
      fullMonth: fullMonth
    })
    this.loadData(fullMonth);
  }

  renderCheck(row) {
    return <Checkbox checkboxClass="icheckbox_flat-blue" label="&nbsp;"
      checked={row.selected === true}
      onChange={this.onCheckedChange}
      data-id={row.employeeId} />;
  }

  onCheckedChange(event, checked) {
    let id = event.target.dataset.id;
    this.props.doSelectRow(id, checked, "employeeId");
  }

  onFillSuccess() {
    this.loadData();
  }

  getTrProps(state, rowInfo, column) {
    if (rowInfo && rowInfo.original.readyToClose === true) {
      return {
        className: "green"
      };
    }
    else
      return {};
  }

  getSubComponent(row) {
    return <ClosingDetails employeeId={row.original.employeeId} fullMonth={this.state.fullMonth} />
  }

  onCloseMonth() {
    toastr.confirm(`Zamknąć miesiąc ${dateToMoment(this.props.currentClosing.date).format("MMMM")}?`, {
      onOk: () => {
        axios
          .post(`Closings/Close?monthDate=${formatDate(this.props.currentClosing.date)}`)
          .then((res) => {
            this.props.doCurrentClosingLoaded(res.data);
            this.loadData();
          });
      }
    });
  }

  onSendClick() {
    let employeeIds = this.props.dataSet
      .filter(row => row.selected)
      .map(row => row.employeeId);

    if (employeeIds.length === 0) {
      toastr.warning("Zaznacz przynajmniej jedną osobę.");
      return;
    }
    
    this.setState({
      ...this.state,
      dialogMode: DialogMode.PREVIEW,
      employeeIds: employeeIds
    });

    
  }

  onPrintClick(employeeId) {
    this.setState({
      ...this.state,
      printDialogMode: DialogMode.PREVIEW,
      printEmployeeId: employeeId
    });

  }

  onDialogClose() {
    this.setState({
      ...this.state,
      dialogMode: DialogMode.HIDDEN
    });
  }

  onPrintDialogClose() {
    this.setState({
      ...this.state,
      printDialogMode: DialogMode.HIDDEN
    });
  }

  onMonthChange(date) {
    this.setState({
      ...this.state,
      month: date
    });   
  }
  
  onGenerate(employeeId) {
    this.setState({
      ...this.state,
      spinner: true
    });

    axios
      .post(`Reports/Engagement/Employee?start=${formatDate(this.props.currentClosing.date)}&id=${employeeId}`, null, {
        responseType: "blob",
        timeout: 30000
      })
      .then(res => { 
        var blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8" });
        this.setState({
          ...this.state,
          spinner: false
        });
        FileSaver.saveAs(blob, `Raport zaangażowania za ${formatDate(this.props.currentClosing.date)}.xlsx`);
      })
      .catch(err => {
        this.setState({
          ...this.state,
          spinner: false
        })
      });
  }


  render() {
    var isClosingAllowed = this.props.dataSet.length > 0;
    this.props.dataSet.forEach(el => {
      if (el.readyToClose !== true) {
        isClosingAllowed = false;
      }
    });

    return (
      <div>
        <h1 className="small-bottom">Zamykanie miesiąca</h1>
        <ClosingInfo />
        <PageFilter tableFilter>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Przedział czasu:</label></div>
            <div className="w3-col m9 value"><StatsPeriodSelect fullMonth={this.state.fullMonth} onChange={this.onPeriodChange} /></div>
          </div>
        </PageFilter>  
        <Table columns={this.columns} order={this.order} getTrProps={this.getTrProps} SubComponent={this.getSubComponent} />
        <SendMailDialog mode={this.state.dialogMode} employeeIds={this.state.employeeIds} onClose={this.onDialogClose}/>
        <div className="w3-bar w3-center">
          <button className="w3-button w3-round w3-blue" onClick={this.onSendClick} title="Wyślij maila o dowolnej treści do zaznaczonych osób" >
            Wyślij e-maile
          </button>
          &nbsp;
          <button className="w3-button w3-round w3-green" onClick={this.onCloseMonth} disabled={!isClosingAllowed}>
            Zamknij miesiąc
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentClosing: state.session.currentClosing,
    dataSet: state.table.filteredDataSet
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doSelectRow: TableActions.selectRow,
  doCurrentClosingLoaded: SessionActions.currentClosingLoaded,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(ClosingPage);