import * as React from "react";
import { connect } from "react-redux";

import * as TableActions from "actions/table";
import PageFilter from "components/common/panel/PageFilter";
import YearSelect from "components/common/select/YearSelect";
import Table from "components/common/Table";
import DeleteIcon from "components/dictionaries/holidays/DeleteIcon";
import HolidayDialog from "components/dictionaries/holidays/HolidayDialog";
import DialogMode from "enums/DialogMode";
import axios from "utils/Axios";


class HolidaysPage extends React.Component {
  static viewKey = 'HolidaysPage';

  columns = [
    {
      Header: <span className="w3-left">Data</span>,
      accessor: "date",
      width: 100
    },
    {
      Header: <span className="w3-left">Nazwa</span>,
      accessor: "name"
    },
    {
      Header: "",
      id: "options",
      className: "w3-center",
      sortable: false,
      accessor: (row) => <DeleteIcon row={row} />,
      width: 40
    },
  ];
  order = [{ id: "date", desc: true }];

  constructor(props) {
    super(props);

    this.state = {};

    this.loadData();

    this.onShowDialog = this.onShowDialog.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
  }

  componentWillMount() {
    this.props.doSetCurrentTableView(HolidaysPage.viewKey);
    this.props.doDataSetLoaded([], HolidaysPage.viewKey);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.selectedYear !== newProps.selectedYear) {
      this.loadData(newProps.selectedYear);
    }
  }

  loadData(year = this.props.selectedYear) {
    if (!year)
      return;
    
    axios
      .get(`Holidays?year=${year}`)
      .then((res) => this.props.doDataSetLoaded(res.data, HolidaysPage.viewKey));
  }

  onShowDialog(row) {
    this.props.doOpenDialog(row, DialogMode.EDIT);
  }

  onAddClick() {
    this.props.doOpenDialog({}, DialogMode.ADD);
  }

  render() {
    return (
      <div>
        <h1 className="small-bottom">Święta</h1>
        <PageFilter tableFilter>
          <div className="w3-row form-inline">
            <div className="w3-col m3 label"><label>Wybierz rok:</label></div>
            <div className="w3-col m9 value"><YearSelect from={2017}/></div>
          </div>
        </PageFilter>
        <Table columns={this.columns} order={this.order} onShowDialog={this.onShowDialog} defaultPageSize={20} />
        <div className="w3-bar w3-center">
          <button className="w3-button w3-round w3-green" onClick={this.onAddClick}>
            Dodaj
          </button>
        </div>
        <HolidayDialog />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedYear: state.config.selectedYear,
  };
};

const mapDispatchToProps = {
  doDataSetLoaded: TableActions.dataSetLoaded,
  doOpenDialog: TableActions.openDialog,
  doSetCurrentTableView: TableActions.setCurrentTableView
};

export default connect(mapStateToProps, mapDispatchToProps)(HolidaysPage);
