export default {
  main: "/",

  basic: "/podstawowe",
  acceptance: "/akceptacja",
  controlling: "/kontroling",
  dictionaries: "/slowniki",
  myAccount: "/moje-konto",

  timeEntries: "/rejestr-wpisow",
  creativeWorks: "/utwory",
  leaves: "/urlopy-i-przerwy",
  otherLeaves: "/pozostale-nieobecnosci",
  statistics: "/statystyki",
  byEmployee: "/po-zatrudnionych",
  byActivity: "/po-projektach",
  vacations: "/urlopy-i-przerwy",
  sicks: "/choroby-i-inne",
  managerStats: "/statystyki-akceptujących",
  closing: "/zamykanie-miesiaca",
  employees: "/zatrudnieni",
  projects: "/projekty",
  holidays: "/swieta",
  reports: "/raporty",
  sales: "/sprzedaz-i-zaangazowanie",
  login: "/zaloguj",
  logOut: "/wyloguj",
  settings: "/settings",
  emails: "/emaile",
};
