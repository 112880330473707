import * as React from "react";

import EmailTemplateCard from "./EmailTemplateCard";
import PageInfo from "components/common/panel/PageInfo";
import { connect } from "react-redux";

class EmailsPage extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <h1>Ustawienia e-maili</h1>
        <PageInfo>
          <p>W temacie oraz treści maili można używać poniższych znaczników:
            <ul style={{marginTop: "0px"}}>
              <li><span className="code">{"{imie}"}</span> - imię odbiorcy wiadomości</li>
              <li><span className="code">{"{nazwisko}"}</span> - nazwisko odbiorcy wiadomości</li>
              <li><span className="code">{"{ttrack}"}</span> - link do TTracka ze słowem "tutaj"</li>
            </ul>
          </p>
        </PageInfo>
        <EmailTemplateCard type="REMINDER_TO_EMPLOYEE" label="Wiadomość z upomnieniem" time="poniedziałek i wtorek, godz. 9:05" />
        <EmailTemplateCard type="GIFT_TO_EMPLOYEE" label="Wiadomość z nagrodą" time="poniedziałek, godz. 15:05" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.currentUser 
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(EmailsPage);
