import EntryStatus from "enums/EntryStatus";
import * as React from "react";
import { toastr } from "react-redux-toastr";
import axios from "utils/Axios";
import { ENTRY_CHARS, ENTRY_REGEX} from "utils/Utils";

export default class EntryCell extends React.Component {
  static defaultProps = {
    row: undefined,
    week: undefined,
    controlling: false,
    disabled: false,
    onHoursUpdated: (row, entry, newEntry) => { },
    onInfoClick: (row, entry) => { }
  };

  constructor(props) {
    super(props);

    var entry = this.calcEntry(props.row, props.week);

    this.state = {
      entry: entry,
      originalEntry: entry
    };

    this.onEntryChange = this.onEntryChange.bind(this);
    this.onEntryBlur = this.onEntryBlur.bind(this);
    this.onInfoClick = this.onInfoClick.bind(this);
  }

  componentWillReceiveProps(newProps) {
    var newEntry = this.calcEntry(newProps.row, newProps.week);
    if (this.props.row !== newProps.row || this.props.week !== newProps.week || this.state.entry !== newEntry) {
      this.setState({
        ...this.state,
        entry: newEntry,
        originalEntry: newEntry
      });
    }
  }

  calcEntry(row, week) {
    var entry = row.weekEntries[week.startDate];
    if (!entry) {
      entry = {
        activity: {
          projectId: row.activity.projectId,
          responsibilityId: row.activity.responsibilityId,
          costCenter: row.activity.costCenter
        },
        clientId: (row.client ? row.client.id : undefined),
        startDate: week.startDate,
        endDate: week.endDate,
        status: EntryStatus.ENTERED.value,
        hours: ""
      };
    }
    return entry;
  }

  onEntryChange(event) {
    var strVal = event.target.value.replace(",", ".");
    if (!ENTRY_CHARS.test(strVal)) {
      toastr.warning("Dozwolone są tylko cyfry.");
      return;
    }

    var entry = { ...this.state.entry };
    entry.hours = strVal;
    this.setState({
      ...this.state,
      entry: entry
    });
  }

  // controlling nie moze edytować z tego poziomu
  onEntryBlur(event) {
    var strVal = event.target.value.replace(",", ".");
    if (strVal.length > 0 && !ENTRY_REGEX.test(strVal)) {
      toastr.warning("Niepoprawny format godzin.");
      return;
    }

    var hours = parseFloat(strVal);
    if (isNaN(hours) && this.state.entry.id === undefined)
      return;

    this.setState({
      ...this.state,
      entry: {
        ...this.state.entry,
        disabled: true
      }
    });

    var entry = { ...this.state.entry };
    entry.hours = hours;

    if (!entry.id) {
      axios
        .post(`TimeEntries/Current`, entry)
        .then((res) => {
          this.props.onHoursUpdated(this.props.row, this.state.originalEntry, res.data);
        })
        .catch(err => {
          this.setState({
            ...this.state,
            entry: {
              ...this.state.entry,
              hours: 0,
              disabled: false
            }
          });
        });
    }
    else {
      axios
        .put(`TimeEntries/Current/${entry.id}`, entry)
        .then((res) => {
          this.props.onHoursUpdated(this.props.row, this.state.originalEntry, res.data);
        })
        .catch(err => {
          this.setState({
            ...this.state,
            entry: {
              ...this.state.entry,
              hours: 0,
              disabled: false
            }
          });
        });
    }
  }

  onInfoClick(){
    this.props.onInfoClick(this.props.row, this.state.entry);
  }

  render() {  
    if (!this.state.entry)
      return null;
    
    var disabled = (this.state.entry.status === EntryStatus.SEND.value || this.state.entry.status === EntryStatus.ACCEPTED.value
      || this.props.controlling === true || this.props.disabled === true || this.state.entry.disabled === true || this.props.row.activity.costCenter.leave === true);  
    var className = "w3-input w3-border w3-round entry-" + this.state.entry.status.toLowerCase();
    var infoClassName = this.props.controlling === false
      ? this.state.entry.description ? "fas fa-info-circle w3-text-blue" : "fas fa-info-circle w3-text-gray"
      : "fas fa-pencil-alt"
    return (
      <div>
        <input type="text" className={className} disabled={disabled} title="Liczba godzin"
          // eslint-disable-next-line
          value={this.state.entry.hours} onChange={this.onEntryChange} onBlur={this.onEntryBlur} />
        {this.state.entry.disabled !== true && <i className={infoClassName} title={this.props.controlling === false ? "Opis" : "Edycja"} onClick={this.onInfoClick} />}
      </div>
    );
  }
}

