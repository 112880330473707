import * as React from "react";
import Select from "react-select-v1";
import axios from "utils/Axios";


export default class EmployeeSelect extends React.Component {
  static defaultProps = {
    onChange: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      options: [],
      value: undefined
    };

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    axios.get(`Employees/Active`)
      .then((res) => {
        var options = res.data.map((el) => {
          return {
            label: el.lastName + " " + el.firstName,
            value: el
          };
        });

        this.setState({
          ...this.state,
          options: options
        });
    });
  }

  onChange(option) {
    this.setState({
      ...this.state,
      value: option
    });
    this.props.onChange(option.value);
  }

  render() {
    if (!this.state.options)
      return;

    return (
      <Select
        value={this.state.value}
        searchable={true}
        clearable={false}
        options={this.state.options}
        onChange={this.onChange}
        className="select-inline w308"
        placeholder="Wybierz"
      />
    );
  }
}