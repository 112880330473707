import * as React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import axios from "utils/Axios";

class CreativeWorkAcceptIcon extends React.Component {
  static defaultProps = {
    row: undefined,
    controlling: false,
    onSuccess: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.onClick = this.onClick.bind(this);
  }

  onClick() {   
    toastr.confirm("Zaakceptować utwór?", {
      onOk: () => {
        axios
          .post(`CreativeWorks/Accept`, [this.props.row.id])
          .then(res => {
            toastr.success("Zaakceptowano utwór.");
            this.props.onSuccess();
          });
        }
      });
  }

  render() {
    return <i className="far fa-check-circle pointer" title="Zaakceptuj utwór" onClick={this.onClick} />;
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(CreativeWorkAcceptIcon);
