import * as React from "react";
import { connect } from "react-redux";

import LeavesReportCard from "components/reports/leaves/YearReportCard";
import WorkReportCard from "components/reports/leaves/WorkReportCard";
class LeavesReportPage extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div>
        <h1>Raporty urlopów, przerw i pracy twórczej</h1>
        <LeavesReportCard></LeavesReportCard>
        {(this.props.currentUser.roleControlling === true || this.props.currentUser.roleReports === true) && <WorkReportCard />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.session.currentUser
  };
};

export default connect(mapStateToProps)(LeavesReportPage);
