import * as React from "react";

export default class PageInfo extends React.Component {
  static defaultProps = {
    warning: false,
    info: false
  }

  render() {
    var className = "w3-panel w3-leftbar small-top ";

    if (this.props.info === true)
      className += "w3-pale-green w3-border-green";
    else if (this.props.warning === true)
      className += "w3-pale-yellow w3-border-amber";
    else
      className += "w3-pale-blue w3-border-blue";
    
    return (
      <div className={className}>
        {this.props.children}
      </div>
    );
  }
}
